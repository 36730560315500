import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

const BriefcaseLight = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1.5 15.5l.739 4.8h0A2 2 0 004.216 22h15.568a2 2 0 001.977-1.7l.739-4.8h0a1 1 0 001-1V8v0a2 2 0 00-2-2h-19 0a2 2 0 00-2 2s0 0 0 0v6.5h0a1 1 0 001 1zM16.5 6h-9V5h0a3 3 0 013-3h3a3 3 0 013 3zM1.5 15.5H10M14 15.5h8.5"></path>
        <path d="M13.414 13.732c.781.977.781 2.56 0 3.536-.78.976-2.047.976-2.828 0-.781-.977-.781-2.56 0-3.536.78-.976 2.047-.976 2.828 0"></path>
      </g>
    </StyledIcon>
  )
}

export default BriefcaseLight
