import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const GeneralInfo = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M3.5 24c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h18c.6 0 1 .4 1 1v19c0 .6-.4 1-1 1h-18zM6.5 5V1M18.5 5V1M10.5 5V1M14.5 5V1M6.5 10h12M6.5 13h12M6.5 16h12M6.5 19h7"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default GeneralInfo
