import React from 'react'
import Tooltip from 'rc-tooltip'
import styled from 'styled-components'
import { useSdk } from 'sdk'

const InfoOverlay = styled.div`
  max-width: 250px;
`

const InfoText = styled.div`
  white-space: pre-wrap;
`

const InfoLink = styled.a`
  color: #3bcbe8;
  text-decoration: underline;
  margin-left: 2px;
  display: inline;
`

export const Tooltip2 = props => {
  const overlayStyle = {
    borderRadius: '4px',
    background: 'transparent',
  }

  const overlayInnerStyle = {
    border: 'none',
    backgroundColor: '#333',
    color: '#ffffff',
  }

  const { t } = useSdk()

  return (
    <Tooltip
      placement={'top'}
      overlayStyle={overlayStyle}
      overlayInnerStyle={overlayInnerStyle}
      overlay={
        <InfoOverlay>
          <InfoText>
            {props.helpText}{' '}
            {props.helpLink && (
              <InfoLink
                href={props.helpLink}
                target={props.isSameTab ? '' : '_blank'}
              >
                {t('translation.Tooltip2.label-here')}
              </InfoLink>
            )}
          </InfoText>
        </InfoOverlay>
      }
      arrowContent={<div className="rc-tooltip-arrow-inner infoButton" />}
    >
      {props.children}
    </Tooltip>
  )
}
