import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import regionalFormatsHR from './localizations/hr/regionalFormats.json'
import translationHR from './localizations/hr/translation.json'

import regionalFormatsEN from './localizations/en/regionalFormats.json'
import translationEN from './localizations/en/translation.json'

import translationRO from './localizations/ro/translation.json'
import regionalFormatsRO from './localizations/ro/regionalFormats.json'

import translationES from './localizations/es/translation.json'
import regionalFormatsES from './localizations/es/regionalFormats.json'

import translationDE from './localizations/de/translation.json'
import regionalFormatsDE from './localizations/de/regionalFormats.json'

import translationSR from './localizations/sr/translation.json'
import regionalFormatsSR from './localizations/sr/regionalFormats.json'

// the translations
// (tip move them in a EN file and import them)
const resources = {
  en: {
    translation: {
      regionalFormats: regionalFormatsEN,
      translation: translationEN,
    },
  },
  hr: {
    translation: {
      regionalFormats: regionalFormatsHR,
      translation: translationHR,
    },
  },
  ro: {
    translation: {
      regionalFormats: regionalFormatsRO,
      translation: translationRO,
    },
  },
  es: {
    translation: {
      regionalFormats: regionalFormatsES,
      translation: translationES,
    },
  },
  de: {
    translation: {
      regionalFormats: regionalFormatsDE,
      translation: translationDE,
    },
  },
  sr: {
    translation: {
      regionalFormats: regionalFormatsSR,
      translation: translationSR,
    },
  },
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export const getCurrentLanguage = () => {
  const language = localStorage.getItem('i18nextLng')
  return language || i18n.language
}
export default i18n
