import React from 'react'
import styled from 'styled-components'
import AutoSizer from 'react-virtualized-auto-sizer'
import { device } from '../../constants'
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;
  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const ContentContainer = styled(FlexColumn)`
  height: 100%;
  max-height: 100%;
`
const ContentScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px 30px;
`

export const ContentScroll = props => {
  return (
    <div style={{ height: '100%' }}>
      <AutoSizer disableWidth>
        {({ height, width }) => (
          <ContentScrollContainer
            style={{ height, maxHeight: height, ...props.style }}
          >
            {props.children}
          </ContentScrollContainer>
        )}
      </AutoSizer>
    </div>
  )
}
export const Flex = {
  Row: FlexRow,
  Column: FlexColumn,
}
