//export const blue = '#7860dc';
export const blue = '#6a4ee1'
export const blueDarker = '#5739e1'
export const blueDark = '#2e188a'
export const blueLighter = '#6B81FF'
export const purpleLight = '#7860dc21'
export const purpleGray = '#7f74ae'
export const red = '#9D2637'
export const redLight = '#e972842e'
export const redLighter = '#F3AFB9'
export const white = '#FFFFFF'
export const grayLighter = '#fafafa'
export const grayLight = '#dbdfe2'
export const gray = '#E8ECEF'
export const grayDark = '#98A9BC'
export const grayDarker = '#3D3D3D'
export const grayDefault = '#77797a'
export const blueLight = '#deecff'
export const blueGray = '#5a88c7'
export const grayShadow = '0px 1px 0.286rem rgba(0, 0, 0, 0.16)'
export const secondaryDarker = ' #778CA2'
export const yellow = '#ffde38'
export const yellowDarker = '#cfa036'
export const orangeDark = '#903e00'
export const orangeLighter = '#fe9060'

export const boxShadow = '#ABB7FF'

const textColors = {
  red,
  white,
  gray,
  grayDark,
  grayDarker,
  grayLight,
  blue,
  blueDark,
  purpleGray,
  blueGray,
  grayDefault,
}

export const backgroundColors = {
  purpleLight,
  blueLight,
  grayLighter,
}

export default textColors
