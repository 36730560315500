import styled from 'styled-components'
import { device } from '../../../constants'
import { ButtonLabel } from 'components/Button/style'
import { AvatarWrapper } from 'components/Avatar/Avatar'

export const DesktopLocationWrapper = styled.div`
  width: 100%;
  background: #fff;
  z-index: 1;
  min-height: calc((var(--vh, 1vh) * 100));
  display: flex;
  flex-direction: column;
`

export const DesktopLocationTextBody = styled.div`
  display: flex;
`

export const DesktopLocationContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  position: relative;
  border-radius: 10px;
  height: 100%;
`

export const DesktopLocationContentWrapper = styled.div`
  transform: translate3d(0px, 0px, 0px);
  background: ${props => props.theme.colors.light};
  padding: 20px 0px 50px 50px;
  position: relative;
  max-width: 1220px;
  margin: auto;
  width: 100%;

  @media ${device.tablet} {
    padding: 0px;
  }
`

export const DesktopLocationStyledRoot = styled.div`
  flex: 3;
`

export const DesktopLocationSectionPrefix = styled.div`
  padding: 20px 0;

  h1 {
    letter-spacing: 0px;
    min-width: 0px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: ${props => props.theme.colors.text};
    overflow-wrap: break-word;
    margin: 0px;
  }
  h1.smaller {
    font-size: 18px;
    padding: 0 20px;
  }
`

export const LocationWorkTable = styled.div`
  display: flex;
`

export const LocationWorkShift = styled.div`
  display: flex;
  flex-direction: column;

  p {
    min-width: 130px;
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${props => props.theme.colors.text};
    @media ${device.tablet} {
      font-size: 14px;
      min-width: 120px;
    }
  }
`

export const DesktopLocationHoursContainer = styled.div`
  padding: 0px 20px 16px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

export const DesktopLocationContent = styled.div`
  margin-right: 40px;
  flex: 7;
`

export const DesktopLocationTextWrapper = styled.div`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.text};
  margin: 0px;

  .react-tel-input .form-control {
    border: none;
  }
`

export const DesktopLocationParagraph = styled.p`
  position: relative;
  overflow: hidden;
  transition: height 200ms ease 0s;
  /* max-height: 240px; */
  line-height: 30px;
`
type TGalleryRow = {
  isSmall?: boolean
}

export const GalleryRow = styled.div<TGalleryRow>`
  display: flex;
  position: relative;
  width: 100%;
  height: ${props => (props.isSmall ? '250px' : '350px')};
  background: white;
  padding-bottom: 20px;
  max-width: 1220px;
  margin: auto;

  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`

export const GalleryLeft = styled.div`
  display: flex;
  flex-basis: 0px;
  flex-shrink: 0;
  flex-grow: 6;
  background: ${props => props.theme.colors.lightBackground};
  flex-direction: column;
  padding: 0px 50px;
  width: 500px;

  ${ButtonLabel} {
    margin: auto;
  }
`

export const GallerySpace = styled.div`
  width: 16px;
`

export const GalleryRight = styled.div`
  display: flex;
  flex-basis: 0px;
  flex-shrink: 1;
  position: relative;
  flex-grow: 8;
`

export const StyledBasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-wrap: break-word;
  max-width: 600px;
  position: relative;
  button {
    display: inline;
    margin-top: 20px;
  }
`

type TGalleryName = {
  isFullHeight?: boolean
}
export const GalleryName = styled.h1<TGalleryName>`
  font-size: ${props => (props.isFullHeight ? '40px' : '32px')};
  line-height: ${props => (props.isFullHeight ? '50px' : '42px')};
  font-weight: 600;
  color: ${props => props.theme.colors.text};
  padding-bottom: ${props => (props.isFullHeight ? '8px' : '0')};
  margin: 0px;
`

export const GalleryDesc = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  color: ${props => props.theme.colors.text};
  margin: 0px;
`

export const GalleryRoot = styled.div`
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  backface-visibility: hidden;
  overflow: hidden;
`

export const GalleryRootContainer = styled.div`
  position: relative;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
`

export const GalleryRootWrapper = styled.div`
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  width: calc(100% + 0px);
  margin-left: 0px;
  margin-right: 0px;
`

export const GalleryInnerContainer = styled.div`
  width: 100%;
  max-width: none;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  display: flex;
  position: relative;
  scroll-behavior: smooth;
  align-self: stretch;
  overflow: hidden;
  scroll-snap-type: initial;
  scroll-behavior: auto;
`

export const GalleryElement = styled.div`
  display: inline-flex;
  width: calc(100% - 0px);
  scroll-snap-align: center;
  padding: 0px;
  white-space: initial;
  flex-shrink: 0;
`
type TGalleryImage = {
  isEmpty?: boolean
}
export const GalleryImage = styled.div<TGalleryImage>`
  position: relative;
  display: block;
  margin: 0px;
  padding: 0px;
  background: transparent;
  overflow: hidden;
  display: flex;
  flex-grow: 1;

  width: 700px;
  animation: ${props =>
    props.isEmpty ? 'shimmer 2s infinite linear' : 'none'};
  background: ${props =>
    props.isEmpty
      ? 'linear-gradient(to right, #faf9fd 4%, #f4f4ff 25%, #faf9fd 36%)'
      : 'none'};
  background-size: 1000px 100%;

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`

export const GalleryImg = styled.img`
  width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 200ms ease 0s;
  margin: 0px !important;

  @media ${device.tablet} {
    height: 300px;
  }
`

export const ServicesContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  padding-right: 50px;
  @media ${device.tablet} {
    padding-right: 0;
  }
`

export const ServicesLeftContent = styled.div`
  display: flex;
  min-width: 270px;
  max-height: 100%;
  overflow: auto;

  @media ${device.tablet} {
    min-width: auto;
    margin-bottom: 10px;
  }
`

export const CategoriesList = styled.ul`
  padding: 0px;
  margin-right: 28px;
  overflow: auto;
  max-height: calc(100vh - 205px);

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }

  @media ${device.tablet} {
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
type TCategoryItem = {
  isActive?: boolean
  isMobile?: boolean
}
export const CategoryItem = styled.li<TCategoryItem>`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  padding-left: 16px;
  padding-right: 24px;
  max-height: 40px;
  opacity: 1;
  transition: max-height 300ms ease 0s, opacity 300ms ease 0s;
  max-width: ${props => (props.isMobile ? '100%' : '300px')};
  background: ${props => props.theme.colors.primary};

  input {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  :not(:first-of-type) {
    margin-top: 20px;
  }

  @media ${device.tablet} {
    background: ${props => props.theme.colors.light};
    border-bottom: 2px solid ${props => props.theme.colors.primary};
    :first-of-type {
      border-radius: 8px 8px 0 0;
    }
    :not(:first-of-type) {
      border-top: 1px solid rgb(219, 223, 226);
      margin-top: 0;
    }
  }
`
type TCategoryName = {
  isActive?: boolean
}
export const CategoryName = styled.p<TCategoryName>`
  letter-spacing: 0px;
  min-width: 100px;
  font-size: 16px;
  font-weight: ${props => (props.isActive ? '600' : '400')};
  line-height: 24px;
  color: ${props => props.theme.colors.light};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0px;
  overflow: hidden;
  margin-right: auto;
  @media ${device.tablet} {
    color: ${props => props.theme.colors.text};
  }
`
type TCategoryNumber = {
  isActive?: boolean
}

export const CategoryNumber = styled.p<TCategoryNumber>`
  letter-spacing: 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: ${props => props.theme.colors.secondaryDark};
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 0px 0px 8px;
  background: ${props =>
    props.isActive ? 'rgb(255, 255, 255);' : 'rgb(242, 242, 247)'};
  border-radius: 50%;
  margin-left: 20px;
`
type TCategoryServicesList = {
  isMobile?: boolean
}
export const CategoryServicesList = styled.div<TCategoryServicesList>`
  display: flex;
  flex-direction: column;
  flex: 1;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
  &:last-of-type {
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
`

export const CategoryServicesListRight = styled(CategoryServicesList)`
  max-width: calc(100% - 300px);
`

export const EmployeeContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  grid-auto-flow: dense;

  @media ${device.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
`

type TEmployeeCard = {
  isDisabled?: boolean
}
export const EmployeeCard = styled.div<TEmployeeCard>`
  display: inline-flex;
  padding: 0px;
  white-space: initial;
  flex-shrink: 0;
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
`

export const EmployeeCardContainer = styled.div`
  display: flex;
  margin: 0px auto;
  max-width: 100%;
`

export const EmployeeLink = styled.div`
  display: flex;
  width: 100%;
  box-shadow: none;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out 0s;
  width: auto;
  margin-left: auto;
  margin-right: auto;
`

export const EmployeeAvatarWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  will-change: transform;
  border-radius: 50%;
  overflow: hidden;
  background: rgb(229, 241, 255);
  height: 88px;
  width: 88px;
  min-width: 88px;
  > div {
    height: auto;
  }
  ${AvatarWrapper} {
    height: none;
    > div {
      height: 80px !important;
    }
  }

  @media ${device.tablet} {
    height: 70px;
    width: 70px;
    min-width: 70px;

    ${AvatarWrapper} {
      width: 70px;
      height: 70px;
      > div {
        width: 70px;
        height: 70px;
      }
    }
  }
`

export const EmployeeStyledImageWrapper = styled.div`
  position: relative;
  display: block;
  margin: 0px;
  padding: 0px;
  background: transparent;
  animation: 0s ease 0s 1 normal none running none;
  overflow: hidden;
`

export const EmployeeDetails = styled.div`
  margin-top: 15px;
  width: 100%;

  &:after {
    content: '';
    display: block;
    order: -1;
    height: 20px;
  }

  @media ${device.tablet} {
    margin-top: 10px;
  }
`

export const EmployeeAvatarName = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 17px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: ${props => props.theme.colors.text};
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  max-width: 100%;
  margin: 0px;
  overflow: hidden;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 20px;
  }
`

export const EmployeeAvatarDesc = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: ${props => props.theme.colors.secondaryDark};
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  max-width: 100%;
  margin: 0px;
  overflow: hidden;
`

export const BlockMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 60px); 
  margin: 20px auto 10px auto;
  @media ${device.tablet} {
    width: calc(100% - 30px); 
  }
`

export const BlockMessage = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: ${props => props.theme.colors.accent5}
`