import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const ChevDown = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.541 5.627L11.666 18.2h0a.5.5 0 00.749 0L23.541 5.627"
      ></path>
    </StyledIcon>
  )
}
export default ChevDown
