import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Notes = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3 23.5a1 1 0 01-1-1v-19a1 1 0 011-1h18a1 1 0 011 1v19a1 1 0 01-1 1zM6 4.5v-4M18 4.5v-4M10 4.5v-4M14 4.5v-4M6 9.5h12M6 12.5h12M6 15.5h12M6 18.5h7"></path>
      </g>
    </StyledIcon>
  )
}

export default Notes
