import React from 'react'
import styled from 'styled-components'
import { device } from '../../constants'
const AnimationDivStyled = styled.div`
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(72, 47, 180, 0.3) 100%,
    #30364d
  );
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

  @media ${device.tablet} {
    display: ${props => (props.showOnMobile ? 'flex' : 'none')};
  }

  .af-view.af-class-animation-div {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-dots-animation {
    position: relative;
    top: 10%;
    width: 20px;
    height: 20px;
    border-radius: 20%;
    background-color: rgba(78, 225, 115, 0.43);
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-2 {
    width: 15px;
    height: 15px;
    margin: auto 10px;
    border-radius: 20%;
    background-color: #e9d7fd;
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-3 {
    position: relative;
    left: 0%;
    top: 60%;
    right: 0%;
    bottom: auto;
    width: 25px;
    height: 25px;
    border-radius: 20%;
    background-color: #b87df9;
    opacity: 0.24;
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-4 {
    position: relative;
    left: 16%;
    top: 32%;
    bottom: auto;
    width: 10px;
    height: 10px;
    margin-left: 0px;
    border-radius: 20%;
    background-color: rgba(102, 71, 236, 0.3);
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-5 {
    position: relative;
    left: auto;
    top: 52%;
    right: 20%;
    bottom: auto;
    width: 10px;
    height: 10px;
    border-radius: 20%;
    background-color: #eeacac;
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-6 {
    position: relative;
    left: auto;
    top: 78%;
    right: 24%;
    bottom: auto;
    width: 12px;
    height: 12px;
    border-radius: 20%;
    background-color: #b87df9;
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-page-title.af-class-white {
    color: #fff;
  }

  .af-view .af-class-dots-animation-7 {
    position: relative;
    left: -30%;
    top: 37%;
    right: auto;
    bottom: auto;
    width: 45px;
    height: 45px;
    border-radius: 20%;
    background-color: rgba(255, 0, 230, 0.2);
    animation: animate3 20s linear infinite;
  }

  .af-view .af-class-dots-animation-8 {
    position: relative;
    left: 0%;
    top: 93%;
    right: -22%;
    bottom: auto;
    width: 40px;
    height: 40px;
    border-radius: 20%;
    background-color: rgba(106, 78, 225, 0.4);
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-9 {
    position: relative;
    left: 30%;
    top: 56%;
    bottom: auto;
    width: 12px;
    height: 12px;
    margin-left: 0px;
    border-radius: 20%;
    background-color: #ff995d;
    animation: animate2 20s linear infinite;
  }

  .af-view .af-class-dots-animation-10 {
    position: relative;
    left: 360px;
    top: 600px;
    right: auto;
    bottom: auto;
    width: 20px;
    height: 20px;
    border-radius: 20%;
    background-color: #f0dbb5;
    animation: animate3 20s linear infinite;
  }

  .af-view .af-class-dots-animation-11 {
    position: relative;
    left: -44%;
    top: 73%;
    right: auto;
    bottom: auto;
    width: 18px;
    height: 18px;
    border-radius: 20%;
    background-color: #64c939;
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-12 {
    position: relative;
    left: 38%;
    top: 71%;
    right: auto;
    bottom: auto;
    width: 20px;
    height: 20px;
    border-radius: 20%;
    background-color: #eeb5f0;
    animation: animate2 20s ease infinite;
  }

  .af-view .af-class-dots-animation-13 {
    position: relative;
    top: 15%;
    width: 55px;
    height: 55px;
    border-radius: 20%;
    background-color: rgba(248, 132, 54, 0.2);
    animation: animate 20s linear infinite;
  }

  .af-view .af-class-dots-animation-14 {
    position: relative;
    top: 32%;
    right: -35%;
    width: 32px;
    height: 32px;
    border-radius: 20%;
    background-color: rgba(184, 125, 249, 0.2);
    animation: animate2 20s linear infinite;
  }

  .af-view .af-class-dots-animation-15 {
    position: absolute;
    top: auto;
    right: 8%;
    bottom: 14%;
    width: 44px;
    height: 44px;
    border-radius: 20%;
    background-color: rgba(64, 209, 48, 0.3);
    animation: animate3 20s linear infinite;
  }

  .animation-div {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .dots-animation-7 {
    left: 10%;
    top: 15%;
    width: 450px;
    height: 450px;
    margin-left: 0;
    animation: animate3 100s linear infinite;
    align-self: auto;
    opacity: 0.05;
    position: absolute;
    background-image: url(${process.env.PUBLIC_URL}/zoyya7.svg);
    background-size: cover;
    right: auto;
    bottom: auto;
    border-radius: 20%;
    background-color: transparent;
    background-position: 0 0;
  }

  .dots-animation-6 {
    position: absolute;
    left: 0%;
    top: 65%;
    right: 0;
    bottom: auto;
    z-index: 0;
    width: 350px;
    height: 350px;
    border-radius: 20%;
    background-color: transparent;
    background-image: url(${process.env.PUBLIC_URL}/zoyya0.svg);
    background-position: 0 0;
    background-size: cover;
    opacity: 0.7;
    animation: animateRotate2 100s linear infinite;
  }

  .dots-animation-13 {
    border-radius: 20%;
    background-color: transparent;
    background-position: 0 0;
    background-image: url(${process.env.PUBLIC_URL}/zoyya7.svg);
    position: relative;
    left: auto;
    top: 20%;
    right: -70%;
    width: 350px;
    height: 350px;
    background-size: cover;
    opacity: 0.05;
    color: transparent;
    animation: animateRotate2 100s linear infinite;
  }

  .dots-animation-15 {
    border-radius: 20%;
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: -10%;
    bottom: 45%;
    width: 420px;
    height: 420px;
    background-image: url(${process.env.PUBLIC_URL}/zoyya0.svg);
    background-size: cover;
    opacity: 0.69;
    animation: animateRotate 100s linear infinite;
  }

  @keyframes animateRotate {
    0% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
    50% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(-360deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }

    100% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
  }
  @keyframes animateRotate2 {
    0% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
    50% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(360deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }

    100% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
  }

  @keyframes animate {
    0% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
    50% {
      transform: translate3d(150px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(360deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }

    100% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
  }

  @keyframes animate2 {
    0% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
    50% {
      transform: translate3d(300px, -50px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }

    100% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
  }

  @keyframes animate3 {
    0% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
    50% {
      transform: translate3d(-150px, -100px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(-360deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }

    100% {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
      will-change: transform;
    }
  }
`

export const AnimationDiv = props => {
  const { style } = props
  return (
    <AnimationDivStyled style={style} showOnMobile={props.showOnMobile}>
      <div className="af-view af-class-animation-div">
        <div className="af-class-dots-animation" />
        <div className="af-class-dots-animation-2" />
        <div className="af-class-dots-animation-7" />
        <div className="af-class-dots-animation-6" />
        <div className="af-class-dots-animation-5" />
        <div className="af-class-dots-animation-3" />
        <div className="af-class-dots-animation-10" />
        <div className="af-class-dots-animation-13" />
        <div className="af-class-dots-animation-15" />
        <div className="af-class-dots-animation-14" />
        <div className="af-class-dots-animation-12" />
        <div className="af-class-dots-animation-11" />
        <div className="af-class-dots-animation-4" />
        <div className="af-class-dots-animation-9" />
        <div className="af-class-dots-animation-8" />
      </div>
    </AnimationDivStyled>
  )
}
