import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ServiceWorkerProvider, useServiceWorker } from './useServiceWorker'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AppComponent = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()

  if (isUpdateAvailable) {
    updateAssets()
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        draggable
        pauseOnHover
        closeOnClick={false}
        closeButton={false}
        pauseOnFocusLoss={false}
        autoClose={3000}
      />
      <App />
    </div>
  )
}

ReactDOM.render(
  <ServiceWorkerProvider>
    <ErrorBoundary>
      <AppComponent />
    </ErrorBoundary>
  </ServiceWorkerProvider>,

  document.getElementById('root')
)
