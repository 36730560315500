import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Shop = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M23.5 2.5h-2.558 0a.5.5 0 00-.5.439l-.332 2.684h0a1 1 0 01-.992.877H2h0A1.5 1.5 0 00.569 8.447l1.562 5h0A1.5 1.5 0 003.563 14.5H18a1 1 0 110 2H4M19.5 8.5l-.44 3.98M7.062 18.94a1.5 1.5 0 11-2.122 2.12 1.5 1.5 0 012.122-2.12M18.062 18.94a1.5 1.5 0 11-2.122 2.12 1.5 1.5 0 012.122-2.12"></path>
      </g>
    </StyledIcon>
  )
}
export default Shop
