import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
  useContext,
  useRef,
} from 'react'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'
import {
  blue,
  blueLighter,
  grayLight,
  grayDarker,
  grayDark,
  red,
  redLight,
  redLighter,
  white,
} from '../../style/colors'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../../style/fonts'
import { FieldContainer } from '../FieldContainer'
import { MobileContext } from 'App'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import { device } from '../../../constants'
import moment from 'moment'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import Calendar from 'components/Icon/Icons/Calendar'
import { getCurrentLanguage } from 'localization'

mobiscroll.settings = {
  theme: 'default',
  themeVariant: 'light',
  display: 'bubble',
}

const monthNamesLocale = {
  hr: [
    'Siječanj',
    'Veljača',
    'Ožujak',
    'Travanj',
    'Svibanj',
    'Lipanj',
    'Srpanj',
    'Kolovoz',
    'Rujan',
    'Listopad',
    'Studeni',
    'Prosinac',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
}
export const StyledInput = styled.input<any>`
  position: relative;
  box-sizing: border-box;
  margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
  background: ${({ hasError }) => (hasError ? redLight : white)};
  border-radius: 4px;
  border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  color: ${({ hasError }) => (hasError ? red : grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  padding: 9px 8px 8px 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
  cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};
  padding-left: ${props => (props.hasIcon ? '50px' : 'auto')};

  &::placeholder {
    color: ${props => props.theme.colors.secondaryDark};
  }

  &:focus {
    border: 1px solid ${({ hasError }) => (hasError ? red : blue)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) => (hasError ? redLighter : blueLighter)};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  &:disabled {
    background: ${grayLight};
    border: 1px solid ${grayLight};
    color: ${grayDark};
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }
  }
`
type TFormFieldTextProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  multiline?: boolean
  style?: any
  parseValue?: (value?: string) => any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  icon?: any
  showPassword?: boolean
  helpText?: string
  helpLink?: string
}

const StyledFieldRow = styled(FieldRow)`
  margin-bottom: 0px;
  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 0px;
  }
`
export const FormFieldDateInput = React.forwardRef(
  (props: TFormFieldTextProps, ref) => {
    const { parseValue, onChange, icon, name, ...rest } = props
    const [field, meta] = useField(props)

    const { setFieldValue } = useFormikContext()

    const isMobile = useContext(MobileContext)

    const dateRef = useRef<any>(null)

    const lang = getCurrentLanguage()

    return (
      <FieldContainer
        label={props.label}
        error={meta.error}
        fieldName={field.name}
        type={props.type}
        icon={props.icon}
        helpText={props.helpText}
        helpLink={props.helpLink}
      >
        <StyledFieldRow style={{ marginBottom: 0 }}>
          <div className="mbsc" style={{ width: '100%', padding: 0 }}>
            <StyledFieldRow style={{ marginBottom: 0 }}>
              <FieldColumn style={{ paddingRight: '5px' }}>
                <mobiscroll.Date
                  ref={dateRef as any}
                  value={
                    field.value
                      ? moment(field.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
                      : null
                  }
                  showOnTap={false}
                  lang={lang === 'en' ? 'en-US' : 'hr'}
                  monthNames={monthNamesLocale[lang]}
                  showOnFocus={false}
                  buttons={[
                    {
                      text: lang === 'en' ? 'Cancel' : 'Odustani',
                      handler: 'cancel',
                    },
                    {
                      text: lang === 'en' ? 'Set' : 'Postavi',
                      handler: 'set',
                    },
                  ]}
                  dateFormat={'dd/mm/yy'}
                  disabled={props.disabled}
                  display={isMobile ? 'bottom' : 'bubble'}
                  onSet={(e, instance) => {
                    setFieldValue(
                      name,
                      moment(instance.getVal()).format('YYYY/MM/DD')
                    )
                  }}
                >
                  <StyledInput
                    {...rest}
                    ref={ref}
                    placeholder={'DD/MM/YYYY'}
                    name={name}
                    data-cy={`input_birthDate`}
                    autoFocus={!isMobile && props.autoFocus}
                    disabled={props.disabled}
                    onBlur={e => {
                      setFieldValue(
                        name,
                        e.target.value
                          ? moment(e.target.value, 'DD/MM/YYYY').format(
                              'YYYY/MM/DD'
                            )
                          : null
                      )
                    }}
                  />
                </mobiscroll.Date>
              </FieldColumn>
              <StyledFieldCol style={{ flex: '0 1' }}>
                <StyledCalendar
                  stroke={1.5}
                  size={'medium'}
                  onClick={() =>
                    dateRef.current && dateRef.current.instance.show()
                  }
                />
              </StyledFieldCol>
            </StyledFieldRow>
          </div>
        </StyledFieldRow>
      </FieldContainer>
    )
  }
)
FormFieldDateInput.defaultProps = {
  autoComplete: 'off',
  spellCheck: false,
  autoCorrect: 'off',
  autoCapitalize: 'off',
}

const StyledFieldCol = styled(FieldColumn)<any>`
  flex: 0 1;
`
const StyledCalendar = styled(Calendar)<any>`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: ${props => props.theme.colors.secondaryDark};
`
