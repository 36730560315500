import React from 'react'
import styled from 'themes'
import { device } from '../../constants'
import { keyframes } from 'styled-components'
import { easeOutQuart } from '../style/animations'
import { borderRadius } from '../style/borders'
import Button from '../Button/Button'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const stagingAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`

type TStyledBodyProps = {}
export const StyledBody = styled.div<TStyledBodyProps>`
  background: ${props => props.theme.colors.light};
  background-size: 100% auto;
  border-radius: ${borderRadius};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 0 1rem;
  width: 400px;
  overflow: inherit;
  display: flex;
  align-items: center;
  justify-content: inherit;
  flex-direction: column;
  flex-grow: 0;
  outline: none;
  animation: 300ms ${stagingAnimation} ${easeOutQuart};
  margin: auto;
  padding: 20px;

  @media ${device.tablet} {
    width: 90vw;
  }
`

type TStyledModalProps = {}
export const StyledModal = styled.div<TStyledModalProps>`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  align-items: center;
  position: fixed;
  padding-top: 30px;
  box-sizing: border-box;
  width: 100%;
  z-index: 100000;
  flex-direction: column;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;

  animation: 200ms ${fadeIn} ${easeOutQuart};

  @media ${device.tablet} {
    padding-top: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

type TStyledTitleProps = {}
export const StyledTitle = styled.span<TStyledTitleProps>`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
`

type TStyledTextProps = {}
export const StyledText = styled.span<TStyledTextProps>`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;
`

type TStyledFooterProps = {}
export const StyledFooter = styled.div<TStyledFooterProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

type TPopupProps = {
  title: string
  text: string
  onCancel?: any
  onConfirm: any
  confirmButtonLabel: string
  closeButtonLabel?: string
}
export const Popup = (props: TPopupProps) => {
  const {
    title,
    text,
    onCancel,
    onConfirm,
    confirmButtonLabel,
    closeButtonLabel,
  } = props

  return (
    <StyledModal>
      <StyledBody>
        <StyledTitle>{title}</StyledTitle>
        <StyledText>{text}</StyledText>
        <StyledFooter>
          {onCancel ? (
            <Button
              label={closeButtonLabel}
              onClick={onCancel}
              buttonType={'text'}
            />
          ) : null}
          {onConfirm ? (
            <Button
              label={confirmButtonLabel}
              onClick={onConfirm}
              buttonType={'primary'}
            />
          ) : null}
        </StyledFooter>
      </StyledBody>
    </StyledModal>
  )
}
