import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const CSV = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.5 23.5h0a1 1 0 001-1V6.478h0A5.975 5.975 0 0015.525.5H3.5h0a1 1 0 00-1 1s0 0 0 0v21h0a1 1 0 001 1z"></path>
        <path d="M16.5.584V4.5h0a1 1 0 001 1h3.92M8.5 18.5h0a3 3 0 010-6M13.5 12.5h-1.7 0a1.3 1.3 0 00-.723 2.386l1.84 1.227h0A1.3 1.3 0 0112.2 18.5h-1.7M18.5 12.5V14a7.5 7.5 0 01-1.5 4.5h0a7.5 7.5 0 01-1.5-4.5v-1.5"></path>
      </g>
    </StyledIcon>
  )
}
export default CSV
