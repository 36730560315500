import React from 'react'
import styled from 'styled-components'
const StyledBadgeContainer = styled.div<{isSelected?:boolean}>`
  display: flex;
  border-radius: 50%;
  padding: 4px;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  background: ${props => !props.isSelected ? props.theme.colors.primary : props.theme.colors.light};
  color: ${props => !props.isSelected ? props.theme.colors.light : props.theme.colors.primary}; ;
      margin-left: auto;
    margin-right: 10px;
`

export const Badge = props => {
  const { count, isSelected } = props
  return <StyledBadgeContainer isSelected={isSelected}>{count}</StyledBadgeContainer>
}
