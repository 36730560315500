import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

export const Phone = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.745 23.268c-3.067-3.074-1.978-5.471-.314-7.135l1.075-1.075h0a2.739 2.739 0 013.874 0l4.651 4.651h0a1.6 1.6 0 01-.529 2.614h0c-8.172 3.33-17.495-.596-20.824-8.768A15.977 15.977 0 011.677 1.5h0A1.6 1.6 0 014.291.969L8.942 5.62h0a2.739 2.739 0 010 3.874l-1.075 1.075C6.2 12.233 3.806 13.322.732 10.255"
      ></path>
    </StyledIcon>
  )
}
export default Phone
