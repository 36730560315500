import React, { useContext, useRef } from 'react'
import styled from 'themes'
import { MobileContext } from 'App'
import { AnimatedDiv } from 'components/AnimatedDiv/AnimatedDiv'
import Helmet from 'react-helmet'
import { useAuth, useSdk } from 'sdk'
import { device } from '../../constants'
import { useHistory, useLocation } from 'react-router'
import {
  MarketGetMyLocationsDocument,
  useGetFbUserInfoQuery,
  useRegisterExternalMutation,
} from 'state/graphql'
import { Loader } from 'components'
import Footer from 'views/Booking/Footer/Footer'

export const Container = styled.div<{ light?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props =>
    props.light ? props.theme.colors.light : '#f8f8ff'};
  min-height: 550px;
  padding-top: 100px;
  padding-bottom: 100px;

  @media ${device.tablet} {
    min-height: calc((var(--vh, 1vh) * 100 - 100px));
    padding-top: 20px;
    padding-bottom: 60px;
  }
`
export const FacebookRedirect = () => {
  const isMobile = useContext(MobileContext)
  const listRef = useRef<any>()
  const history = useHistory()
  const { t, appServices, toast, navigateTo, apolloClient } = useSdk()

  const auth = useAuth()
  const [registerExternalUserMutation] = useRegisterExternalMutation()
  const { setUser } = useAuth()

  const { hash } = useLocation()
  const params = new URLSearchParams(hash)

  const accessToken = params.get('#access_token')
  const state = params.get('state').slice(1, -1)
  const parts = state.split(',')
  const origin = parts
    .find(e => e.toLocaleLowerCase().startsWith('origin'))
    ?.split('=')?.[1] // bookingWizard or home
  const action = parts
    .find(e => e.toLocaleLowerCase().startsWith('action'))
    ?.split('=')?.[1] // login or signup
  const isHome = origin === 'home'
  const isGroupBookingWizard = origin === 'groupBookingWizard'
  const isHotelBookingWizard = origin === 'hotelBookingWizard'

  // state = `{origin=bookingWizard,action=login,locationId=${locationId},orgId=${orgId},employeeId=${employeeId},timeId=${timeId},dayId=${dayId}}`
  // only needed in booking wizard
  const locationId = !isHome
    ? parts
        .find(e => e.toLocaleLowerCase().startsWith('locationid'))
        ?.split('=')?.[1]
    : null
  const orgId = !isHome
    ? parts
        .find(e => e.toLocaleLowerCase().startsWith('orgid'))
        ?.split('=')?.[1]
    : null
  const employeeId =
    !isHome && !isGroupBookingWizard
      ? parts
          .find(e => e.toLocaleLowerCase().startsWith('employeeid'))
          ?.split('=')?.[1]
      : null
  const timeId =
    !isHome && !isGroupBookingWizard
      ? parts
          .find(e => e.toLocaleLowerCase().startsWith('timeid'))
          ?.split('=')?.[1]
      : null
  const dayId =
    !isHome && !isGroupBookingWizard
      ? parts
          .find(e => e.toLocaleLowerCase().startsWith('dayid'))
          ?.split('=')?.[1]
      : null

  // group state = `{origin=groupBookingWizard,action=login,locationId=${locationId},orgId=${orgId},appointmentId=${appointmentId}}`
  const appointmentId = isGroupBookingWizard
    ? parts
        .find(e => e.toLocaleLowerCase().startsWith('appointmentid'))
        ?.split('=')?.[1]
    : null

  useGetFbUserInfoQuery({
    variables: {
      input: {
        accessToken,
      },
    },
    skip: !accessToken,
    onCompleted: async data => {
      const userInfo = data?.market?.getFbUserInfo
      if (userInfo) {
        await handleSocialLogin(userInfo)
      }
    },
    onError: error => {
      console.log(error)
      toast.danger('Error in fetching fb user info')
      navigateTo.home()
    },
  })

  const handleSocialLogin = async user => {
    try {
      const variables = {
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        profilePicUrl: user.avatarUrl,
        phone: null,
        email: null,
        accessToken: user.longLivedAccessToken,
        provider: 'facebook',
      }

      const result = await registerExternalUserMutation({
        variables,
      })

      appServices
        .handleMutationResult(
          result,
          t('translation.Step5LoginChoice.toast-loginSucessfull')
        )
        .onSuccess(async () => {
          const loggedUser = await auth.signInExternal({
            accessToken: result.data.market.registerExternal.token,
            provider: 'facebook',
            userId: user.id,
          })

          const userExternal = result.data.market.registerExternal.user
          setUser(userExternal)

          if (isHome) {
            const { data: myLocationsData } = await apolloClient.query({
              query: MarketGetMyLocationsDocument,
              fetchPolicy: 'network-only',
            })
            const myLocations = myLocationsData?.market.getMyLocations

            // if its signup by invitation, should have my locations and redirected there
            if (myLocations[1]) {
              navigateTo.myLocations()
            } else if (myLocations[0]) {
              navigateTo.selectedLocation({
                orgId: myLocations[0].orgId,
                locationId: myLocations[0].id,
              })
            } else {
              navigateTo.home()
            }
          } else {
            if (action === 'login') {
              let stepId = 'createBooking'
              if (!loggedUser?.phone) {
                stepId = 'phoneRegistration'
              } else if (!loggedUser?.isMobilePhoneConfirmed) {
                stepId = 'codeVerification'
              }

              if (isGroupBookingWizard) {
                // to prevent errors when going back
                history.replace(
                  `/${orgId}/${locationId}/groupWizard/${stepId}/${appointmentId}`
                )
              } else if (isHotelBookingWizard) {
                history.replace(`/${orgId}/${locationId}/hotelWizard/${stepId}`)
              } else {
                // to prevent errors when going back
                history.replace(
                  `/${orgId}/${locationId}/day/${dayId}/wizard/${stepId}/${employeeId}/${timeId}`
                )
              }
            } else {
              if (isGroupBookingWizard) {
                // to prevent errors when going back
                history.replace(
                  `/${orgId}/${locationId}/groupWizard/phoneRegistration/${appointmentId}`
                )
              } else if (isHotelBookingWizard) {
                history.replace(
                  `/${orgId}/${locationId}/hotelWizard/phoneRegistration`
                )
              } else {
                history.replace(
                  `/${orgId}/${locationId}/day/${dayId}/wizard/phoneRegistration/${employeeId}/${timeId}`
                )
              }
            }
          }
        })
    } catch (err) {
      toast.danger('Error in registering external user')

      navigateTo.home()
    }
  }

  return (
    <AnimatedDiv>
      <Helmet>
        <title>{'Zoyya'}</title>
      </Helmet>
      <Loader />
      <Container light ref={listRef} style={{ paddingTop: 0 }}></Container>
      {isMobile && <Footer />}
    </AnimatedDiv>
  )
}
