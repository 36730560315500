import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { FlexRow, FlexColumn } from '../Flex/Flex'
import { NavLink, useLocation } from 'react-router-dom'
import { device } from '../../constants'

type TProps = {
  /**
   * Additional className to pass to the outer tabView container
   */
  className?: string

  /**
   * index of the selected (active) Tab
   */
  selectedTabIndex?: number
  useRoutes?: boolean
  children: any
  isSingleTab: any
  hasFooter: any
}

/***
 * The tab view component
 * @param props component props
 *
 */
export const TabView = (props: TProps) => {
  const l = useLocation()

  const {
    className,
    selectedTabIndex,
    children,
    useRoutes,
    isSingleTab,
    hasFooter,
  } = props
  const [selectedTabIndexInternal, setSelectedTabIndex] = useState(
    selectedTabIndex || 0
  )
  const childrenArray = useMemo(() => React.Children.toArray(children), [
    children,
  ]) as any[]

  return (
    <TabViewContainer className={className} hasFooter={hasFooter}>
      <TabItemStripContainer>
        <TabItems>
          {React.Children.map(children, (child, index) => {
            return (
              child && (
                <TabItemHeader
                  key={index}
                  title={child.props.title}
                  isActive={index === selectedTabIndexInternal || isSingleTab}
                  onClick={() => setSelectedTabIndex(index)}
                  route={child.props.route}
                  id={child.props.id}
                  useRoutes={useRoutes}
                />
              )
            )
          })}
        </TabItems>
      </TabItemStripContainer>
      <TabContentContainer hasFooter={hasFooter}>
        {useRoutes
          ? childrenArray.find(e => e.props.route === l.pathname)
          : childrenArray[selectedTabIndexInternal]}
      </TabContentContainer>
    </TabViewContainer>
  )
}

export const TabContentContainer = styled.div<any>`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  z-index: 9;
  background: ${props => props.theme.colors.light};
  @media ${device.tablet} {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
const TabItemStripContainer = styled(FlexRow)`
  height: 55px;
  background: ${props => props.theme.colors.mainBackground};
  border-radius: 0.714rem 0.714rem 0 0;
  @media ${device.tablet} {
    background: none;
    flex-direction: row;
  }
`

export const TabItems = styled.div`
  display: flex;
  /* @media ${device.tablet} {
    display: none;
  } */

  @media ${device.tablet} {
    width: 100%;
  }
`
export const TabViewContainer = styled<any>(FlexColumn).attrs(props => ({
  hasFooter: props.hasFooter,
}))`
  flex: 1;

  @media ${device.tablet} {
    margin: 0px;
    box-shadow: none;
    border-radius: 0px;
  }
`

const TabItemContainer = styled.div<any>`
  padding: 15px 40px;
  color: ${props =>
    props.active ? props.theme.colors.text : props.theme.colors.primary};
  background: ${props =>
    props.active
      ? props.theme.colors.light
      : props.theme.colors.mainBackground};
  cursor: pointer;
  border-radius: 0.714rem 0.714rem 0px 0px;
  @media ${device.tablet} {
    background: initial;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${props =>
      props.active ? props.theme.colors.primary : props.theme.colors.text};
    border-bottom: 2px solid
      ${props =>
        props.active ? props.theme.colors.primary : props.theme.colors.outline};
  }
`

export const TabItemNavLinkContainer = styled<any>(NavLink).attrs(props => ({
  activeClassName: 'active',
}))`
  padding: 15px 40px;
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.mainBackground};
  cursor: pointer;
  border-radius: 0.714rem 0.714rem 0px 0px;

  @media ${device.tablet} {
    display: none;
    background: transparent;
    padding: 10px;
    color: ${props => props.theme.colors.secondaryDark};
  }

  :active,
  :focus {
    outline: none;
  }

  &.active {
    color: ${props => props.theme.colors.text};
    background: ${props => props.theme.colors.light};

    @media ${device.tablet} {
      font-weight: 600;
    }
  }
`

const TabItemHeader = (props: any) => {
  const { title, isActive, onClick, route, useRoutes, id } = props
  return useRoutes ? (
    <TabItemNavLinkContainer
      id={id}
      onClick={onClick}
      activeClassName="active"
      to={route || ''}
      data-cy={`TabItem_${id || title}`}
    >
      {title}
    </TabItemNavLinkContainer>
  ) : (
    <TabItemContainer onClick={onClick} active={isActive} data-cy={'tabItem'}>
      {title}
    </TabItemContainer>
  )
}

const StyledTabContainer = styled.div.attrs((props: any) => ({
  hasFooter: props.hasFooter,
}))`
  display: flex;
  flex-direction: column;
  padding-top: 3px;

  @media ${device.tablet} {
    padding: 0px;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

type TTabProps = {
  id?: string
  title: string
  route?: string
  children?: any
  loading?: boolean
  hasFooter: any
}

export const Tab = (props: TTabProps) => {
  const { children, hasFooter } = props
  return (
    <StyledTabContainer hasFooter={hasFooter}>{children}</StyledTabContainer>
  )
}
