import i18n, { getCurrentLanguage } from 'localization'
import moment from 'moment'
import { useCallback } from 'react'
import { useAuth } from 'sdk'
import {
  useBusinessCategoryAllQuery,
  useUpdateUserLanguageMutation,
} from 'state/graphql'

const allowedLanguages = ['hr', 'en', 'es', 'de', 'sr']
const defaultAppLanguage = 'hr'
export const useLanguage = () => {
  const [updateLanguageMutation] = useUpdateUserLanguageMutation()
  const { isSignedIn } = useAuth()
  const { refetch } = useBusinessCategoryAllQuery()
  const changeLanguage = useCallback(
    (language: 'hr' | 'en' | 'es' | 'de' | 'sr') => {
      if (!allowedLanguages.includes(language?.toLowerCase())) {
        language = defaultAppLanguage
      }
      const currentLanguage = localStorage.getItem('i18nextLng')
      if (currentLanguage === language) return
      localStorage.setItem('i18nextLng', language)
      document.cookie = `i18next=${language};path=/;domain=.zoyya.com`
      document.cookie = `i18next=${language};path=/;domain=.azurewebsites.net`
      document.cookie = `i18next=${language};path=/;domain=localhost`
      i18n.changeLanguage(language)

      moment.locale(language)
      // for displaying work hours properly in any localization
      if (language === 'en') {
        moment.updateLocale('en', {
          week: {
            dow: 1, // Monday is the first day of the week.
          },
        })
      }
      // used for refreshing the categories data with new language, automatically replaces data
      refetch()

      if (isSignedIn) {
        updateLanguageMutation({ variables: { input: { language } } })
      }
    },
    [refetch, isSignedIn, updateLanguageMutation]
  )
  const restoreLanguage = useCallback(() => {
    const appLanguage = localStorage.getItem('i18nextLng')
    if (allowedLanguages.includes(appLanguage)) {
      changeLanguage(appLanguage as any)
    } else {
      changeLanguage(defaultAppLanguage)
    }
  }, [changeLanguage])
  const currentLanguage = getCurrentLanguage()
  return { currentLanguage, changeLanguage, restoreLanguage }
}
