import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
`

export const Facebook2 = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="inherit"
      viewBox="0 0 24 24"
    >
      <path
        fill="inherit"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.768 7.5H13.5V5.6h0a.972.972 0 011.012-1.1c.418 0 2.988.01 2.988.01V.5h-4.329C9.244.5 8.5 3.474 8.5 5.355V7.5h-3v4h3v12h5v-12h3.851z"
      ></path>
    </StyledIcon>
  )
}
export default Facebook2
