import React from 'react'
import * as styles from './style'
import styled from 'styled-components'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const Location = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.5 11.323v10.5h0a.5.5 0 00.5.5h8.5M.5 10.823L11.326.938h0a1 1 0 011.348 0L23.5 10.823"></path>
        <path d="M11 8.323a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM20.06 14.762a1.5 1.5 0 11-2.12 2.122 1.5 1.5 0 012.12-2.122"></path>
        <path d="M23.5 15.823c0 3-4.5 7.5-4.5 7.5s-4.5-4.5-4.5-7.5h0a4.5 4.5 0 019 0s0 0 0 0z"></path>
      </g>
    </StyledIcon>
  )
}

export default Location
