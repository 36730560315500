import React from 'react'
import { useParams } from 'react-router-dom'
import { useConfirmUserRegistrationMutation } from 'state/graphql'
import {
  LoginForm,
  LoginFormWrapper,
  LoginPage,
  LogoColor,
  LoginHeader,
  LoginImage,
  LoginFormHeader,
  LoginTitle,
  LoginHint,
  LoginPartnerRedirect,
} from 'views/Login/styles'
import { AnimationDiv } from 'components/AnimationDiv/AnimationDiv'
import { Button, Form, FormField, Loader } from 'components'
import { useSdk } from 'sdk'
import { FieldRow } from 'views/Settings/styles'
import { AnimatedDiv } from 'components/AnimatedDiv/AnimatedDiv'
import { publicAssets } from 'assets/publicAssets'
import { useHeader } from 'mobile/Header'
import { getLogoImg } from 'helpers'

export const CodeVerification = () => {
  const { inviteId } = useParams<any>()
  const { navigateTo, t, appServices } = useSdk()

  const [confirmOtp, { loading: isBusy }] = useConfirmUserRegistrationMutation()

  useHeader({
    content: getLogoImg(),
  })

  async function createNewUser(values) {
    try {
      let results = await confirmOtp({
        variables: {
          input: {
            inviteId,
            otp: values.verificationCode,
            pinId: localStorage.getItem('pinId'),
          },
        },
      })

      appServices
        .handleMutationResult(
          results,
          t('translation.CodeVerification.toast-account-created')
        )
        .onSuccess(() => {
          const token = results?.data?.market?.confirmUserRegistration?.token

          if (token) {
            localStorage.setItem('token', token)
            navigateTo.home()
            // setTimeout(
            //   () => window.open(process.env.PUBLIC_URL + '/home', '_self'),
            //   100
            // )
          }
        })
    } finally {
    }
  }
  return (
    <AnimatedDiv
      style={{
        display: 'flex',
        height: 'calc((var(--vh, 1vh) * 100))',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPage>
        <LoginForm>
          <LoginFormHeader>
            <LoginHeader>
              <LogoColor src={publicAssets['zoyya-final-logo-white.svg']} />
            </LoginHeader>
            <LoginImage src={publicAssets['loginImg.svg']} />
          </LoginFormHeader>
          <LoginFormWrapper>
            <LoginTitle>
              {t('translation.CodeVerification.title-enter-sms-code')}
            </LoginTitle>
            <Form
              id={'tfa-form'}
              onSubmit={createNewUser}
              initialValues={{}}
              isInitialValid={true}
              isLoading={isBusy}
            >
              {({ values }) => (
                <>
                  <FieldRow>
                    <FormField.VerificationInput
                      name={'verificationCode'}
                      fields={6}
                      autoFocus
                    />
                  </FieldRow>
                  <Button
                    style={{ marginTop: 0 }}
                    type={'submit'}
                    buttonType={'primary'}
                    label={t('translation.CodeVerification.label-verify-code')}
                    fullWidth
                    disabled={!values.verificationCode}
                    name={'verify'}
                    size={'large'}
                  />
                  <LoginHint>
                    <LoginPartnerRedirect onClick={navigateTo.signup}>
                      {t('translation.Signup.label-backToSelection')}
                    </LoginPartnerRedirect>
                  </LoginHint>
                </>
              )}
            </Form>
          </LoginFormWrapper>
          {isBusy ? <Loader /> : null}
        </LoginForm>
        <AnimationDiv />
      </LoginPage>
    </AnimatedDiv>
  )
}
