import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: 'pub4ac642b4ce08cd2e171f9acc3624d480',
  site: 'datadoghq.eu',
  service: process.env.REACT_APP_DATADOG_SERVICE || 'zoyya-client',
  env: process.env.REACT_APP_DATADOG_ENV,
  version: process.env.REACT_APP_VERSION_NUMBER,
  forwardConsoleLogs: ['error'],
  telemetrySampleRate: 0,
  sampleRate: 100,
  beforeSend(event) {
    if (event.message?.includes("Can't perform a React state update on")) {
      return false
    }
    if (
      event.error?.stack?.includes('AbortError: The user aborted a request.')
    ) {
      return false
    }
    if (
      event.message?.startsWith('XHR') &&
      event.error?.stack?.includes('Failed to load')
    ) {
      return false
    }
    if (
      event.message?.startsWith('Fetch error') &&
      event.error?.stack?.includes('TypeError: Load failed')
    ) {
      return false
    }
    return true
  },
})
export const setLogContextProperty = datadogLogs.setGlobalContextProperty
export const removeLogContextProperty = datadogLogs.removeGlobalContextProperty
export const browserLogs = datadogLogs.logger

let sessionId: string | undefined
export const getSessionId = () => {
  if (sessionId) return sessionId
  const context = datadogLogs.getInternalContext()
  sessionId = context?.session_id
  return sessionId
}
