import React, { useRef, useCallback, useContext, useMemo } from 'react'
import { Props, components } from 'react-select'
import { grayLight } from '../style/colors'
import { StyledAsyncSelect, StyledSelect } from './style'
import { MobileContext } from '../../App'
import { MobileSelect } from 'components/MobileSelect'
import i18n from 'localization'
const SelectContainer = props => {
  return (
    <div data-cy={props['data-cy']}>
      <components.SelectContainer {...props} />
    </div>
  )
}

const SelectItem = props => {
  return (
    <components.Option {...props} data-cy={`select-item-${props.data?.id}`} />
  )
}
const portalTarget = document.getElementById('react-select-portal-target')

interface IProps extends Props<any, boolean> {
  isAsync?: boolean
  formatValue?: (option) => any
  // onValueChanged?: (
  //   newValue: any,
  //   values: any,
  //   formik: FormikContextType<any>
  // ) => any;
  parseValue?: (value) => any
  loadOptions?: (inputValue?: any, callback?: () => any, options?: any) => any
  options?: any[]
  error?: string | null
  allowSelectAll?: boolean
  isColor?: boolean
  groups?: any[]
}

const defaultGetOptionLabel = o => o.name || o.title
const defaultGetOptionValue = o => o.id
/**
 * The select component, wrapper for the react-select with Zoyya styling applied
 */
export const Select = (props: IProps) => {
  const { components, styles, formatOptionLabel, onChange } = props
  const selectRef = useRef<any>()

  const dataCy = props['data-cy']
  const isMobile = useContext(MobileContext)

  const Container = useCallback(
    containerProps => <SelectContainer {...containerProps} data-cy={dataCy} />,
    [dataCy]
  )

  const Option = useCallback(
    selectItemProps => <SelectItem {...selectItemProps} data-cy={dataCy} />,
    [dataCy]
  )

  const customComponents = useMemo(() => {
    return {
      ...components,
      SelectContainer: Container,
      Option: components?.Option || Option,
    }
  }, [components, Container, Option])

  const optionLabel = useCallback(
    (opt, meta) => {
      return formatOptionLabel
        ? formatOptionLabel(opt, meta)
        : opt?.name || opt?.title || opt?.toString()
    },
    [formatOptionLabel]
  )

  const handleChange = useCallback(
    (value, meta) => {
      onChange?.(value, meta)
    },
    [onChange]
  )

  const customStyles = useMemo(
    () => ({
      ...styles,
      menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 1000,
      }),
      control: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.control
          ? providedStyled.control(provided, state)
          : {
              ...provided,
              padding: '0',
            }
      },
      singleValue: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.singleValue
          ? providedStyled.singleValue(provided, state)
          : {
              ...provided,
            }
      },
      container: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.container
          ? providedStyled.container(provided, state)
          : {
              ...provided,
            }
      },
      input: base => ({
        ...base,
        border: '0px',
        padding: '0px',
      }),
      valueContainer: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.valueContainer
          ? providedStyled.valueContainer(provided, state)
          : {
              ...provided,
              margin: '4px',
              border: '0px',
            }
      },
      menu: styles => ({ ...styles, zIndex: 999 }),
      option: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.option
          ? providedStyled.option(provided, state)
          : {
              ...provided,
              color: 'black',
              cursor: 'pointer',
              background: state.background ? grayLight : 'white',
            }
      },
    }),
    [styles]
  )
  return !isMobile ? (
    props.isAsync ? (
      <StyledAsyncSelect
        ref={selectRef}
        {...props}
        formatOptionLabel={optionLabel}
        onInputChange={props.onInputChange}
        classNamePrefix="react-select"
        components={customComponents}
        onChange={handleChange}
        openMenuOnClick={!isMobile}
        additional={{
          page: 0,
        }}
        loadOptions={props.loadOptions}
        menuPortalTarget={portalTarget}
        styles={customStyles}
      />
    ) : (
      <StyledSelect
        ref={selectRef}
        {...props}
        openMenuOnClick={!isMobile}
        formatOptionLabel={optionLabel}
        classNamePrefix="react-select"
        onChange={handleChange}
        data-cy="TESTING"
        components={customComponents}
        menuPortalTarget={portalTarget}
        styles={customStyles}
      />
    )
  ) : (
    <MobileSelect
      ref={selectRef}
      {...props}
      formatOptionLabel={optionLabel}
      onInputChange={props.onInputChange}
      classNamePrefix="react-select"
      components={{ ...props.components, SelectContainer: Container }}
      onChange={handleChange}
      openMenuOnClick={!isMobile}
      additional={{
        page: 0,
      }}
    />
  )
}

Select.defaultProps = {
  isClearable: true,
  isSearchable: true,
  getOptionLabel: defaultGetOptionLabel,
  getOptionValue: defaultGetOptionValue,
  controlShouldRenderValue: true,
  placeholder: i18n.t('translation.DurationSelect.select'),
}
