import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const Heart = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
       <path
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 21.932L2.2 11.84h0a5.667 5.667 0 01-1.087-6.607v0h0A5.839 5.839 0 0110.4 3.746L12 5.205l1.6-1.459h0a5.84 5.84 0 019.287 1.487v0h0A5.669 5.669 0 0121.8 11.84z"
      ></path>
    </StyledIcon>
  )
}

export default Heart
