import React from 'react'
import styled from 'styled-components'
import * as styles from 'components/Icon/Icons/style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

const Share = props => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fill="none" stroke="inherit" strokeLinecap="round" strokeLinejoin="round">
        <path d="M8.074 10.212h0a4 4 0 100 3.574M19.5 8.5h0a4.01 4.01 0 10-3.575-2.213M15.925 17.712h0A4 4 0 1019.5 15.5"></path>
        <path d="M19.5 8.5h0a4 4 0 01-3.575-2.213l-7.851 3.925h0a3.963 3.963 0 010 3.575l7.851 3.926h0A4 4 0 0119.5 15.5"></path>
      </g>
    </StyledIcon>
  )
}

export default Share
