import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Edit = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M7.5 22.5L1 24l1.5-6.5 16-15.9c.8-.8 2-.8 2.8 0l2.1 2.1c.8.8.8 2 0 2.8l-15.9 16zM22.7 7.2l-5-4.9M21.3 8.7l-4.9-5M7.7 22.2l-4.9-5"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Edit
