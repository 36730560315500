import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Services = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M1.5 22.8c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5h-3zM5 21.3c10.5 3.5 7.3 3.6 18.7-2.2.3-.1.3-.4.2-.7 0-.1-.1-.1-.1-.2-.7-.6-1.6-.8-2.4-.5l-5.2 1.7"
        fill="none"
      ></path>
      <path
        d="M10 19.8h5.5c.6 0 1-.4 1-1s-.4-1-1-1h-3c-1.2-1.2-2.8-1.9-4.5-2H5M11 7.8v5.5c0 .3.2.5.5.5h9c.3 0 .5-.2.5-.5V7.8M10.5 7.8c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h11c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5h-11zM16 13.8v-9M18.8 3.4C18 4 17 4.5 16 4.8c.3-1 .8-2 1.4-2.8.9-.9 1.4-.7 1.8-.4s.5.9-.4 1.8zM13.2 3.4C14 4 15 4.5 16 4.8c-.3-1-.8-2-1.4-2.8-.9-.9-1.4-.7-1.8-.4-.4.4-.5.9.4 1.8z"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Services
