type TRouteBolder = {
  [key: string]: (params?: any, options?: { replace: boolean }) => any
}
export const routeBuilder: TRouteBolder = {
  root: () => `/`,
  bookingWizard: params =>
    `/${params.orgId}/${params.locationId}/day/${params.day}/wizard/${
      params.stepId
    }${params.employeeId ? '/' + params.employeeId : ''}${
      params.timeId ? '/' + params.timeId : ''
    }${params.inviteId ? '/' + params.inviteId : ''}${
      params.bookingId ? '/' + params.bookingId : ''
    }`,
  groupBookingWizard: params =>
    `/${params.orgId}/${params.locationId}/groupWizard/${params.stepId}${
      params.appointmentId ? '/' + params.appointmentId : ''
    }${params.inviteId ? '/' + params.inviteId : ''}`,
  hotelBookingWizard: params =>
    `/${params.orgId}/${params.locationId}/hotelWizard/${params.stepId}${
      params.bookingId ? '/booking/' + params.bookingId : ''
    }${params.inviteId ? '/' + params.inviteId : ''}`,
  myAccount: params =>
    `/${params.orgId}/${params.locationId}/settings/account/general`,
  myAccountSecurity: params =>
    `/${params.orgId}/${params.locationId}/settings/account/security`,
  myAccountContact: params =>
    `/${params.orgId}/${params.locationId}/settings/account/contact`,
  home: () => `/home`,
  selectedCategory: params =>
    `/home/${params.categoryId}${
      params.city ? '?city=' + encodeURI(params.city) : ''
    }`,
  myLocations: () => `/myLocations`,
  confirmInvite: (params: any) => `/register/${params.inviteId}`,
  myReservations: () => `/myAppointments`,
  myReservationsResourceBooking: params =>
    `/myAppointments?resourceBooking=${params.resourceBooking}`,
  selectedOrg: params => `/${params.orgId}`,
  selectedLocation: params => `/${params.orgId}/${params.locationId}`,
  partnerLogin: () => `/login`,
  signup: () => `/signup`,
  companyRegister: () => `/signup/partner`,
  passwordReset: () => `/passwordReset`,
  myAccountClient: () => `/account`,
  mySecurityClient: () => `/account/security`,
  myContactClient: () => `/account/contact`,
  myAccountClientSecurity: () => `/account/security`,
  myAccountClientContact: () => `/account/contact`,
  myBooking: params => `/myAppointments/${params.bookingId}`,
  myBookingReschedule: params =>
    `/myAppointments/${params.bookingId}/reschedule`,
  workHours: params =>
    `/${params.orgId}/${params.locationId}/settings/organization/locations/${params.locationId}/workHours/${params.dayId}`,
  thankYou: () => `/thankYou`,
  settingsMobile: () => '/settings',
}
