import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};

  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const UserBold = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M16.4 3.4c2.1 2.1 2.1 5.6 0 7.8s-5.6 2.1-7.8 0-2.1-5.6 0-7.8 5.6-2.2 7.8 0M3.5 23.2c0-5 4-9 9-9s9 4 9 9h-18z"
        fill="none"
      ></path>
    </StyledIcon>
  )
}

export default UserBold
