/* eslint-disable no-confusing-arrow */
import styled, { keyframes } from 'styled-components'
import { white, red } from '../style/colors'
import { borderRadius } from '../style/borders'
import { easeOutQuart } from '../style/animations'
import { device } from '../../constants'
import { TabViewContainer } from '../TabView/TabView'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  align-items: center;
  position: fixed;
  padding-top: 30px;
  box-sizing: border-box;
  width: 100%;
  z-index: 100000;
  flex-direction: column;
  top: 0px;
  left: 0px;

  animation: 200ms ${fadeIn} ${easeOutQuart};

  ${TabViewContainer} {
    width: 100%;
  }

  @media ${device.tablet} {
    padding-top: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const getWidth = props => {
  if (props.isMobile) {
    return '95%'
  } else if (props.wide || props.width === 'wide') {
    return '840px'
  } else if (props.width) {
    return props.width
  }
  return '512px'
}

const stagingAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`

export const Modal = styled.section`
  background: ${props => props.background};
  background-color: ${props => (props.noBackground ? 'transparent' : white)};
  background-size: 100% auto;
  border-radius: ${borderRadius};
  box-shadow: ${props =>
    props.noBackground ? 'none' : '0px 1px 4px rgba(0, 0, 0, 0.16)'};
  box-sizing: border-box;
  margin: 0 0 1rem;
  width: ${props => getWidth(props)};
  overflow: inherit;
  position: absolute;
  max-height: 98vh;
  display: flex;
  align-items: center;
  justify-content: inherit;
  flex-direction: column;
  flex-grow: 0;
  outline: none;

  animation: 300ms ${stagingAnimation} ${easeOutQuart};

  @media ${device.tablet} {
    max-height: calc((var(--vh, 1vh) * 100));
    height: calc((var(--vh, 1vh) * 100));
    width: 100%;
    border-radius: 0;
    overflow: auto;
  }
`

export const IconContainer = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: -4px;
  right: 47px;
  cursor: pointer;

  transition: transform 0.15s ease-out;
  svg {
    fill: ${white};
    transition: fill 0.15s ease-out;
  }

  &:hover {
    transform: scale(1.1);
    svg {
      fill: ${red};
    }
  }
`

export const Footer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  border-top: 1px solid ${props => props.theme.colors.outline};

  margin-top: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span,
  & > p {
    margin: 0 auto 0 0;
  }
`
