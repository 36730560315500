import styled from 'styled-components'
import { ButtonLabel } from '../../../components/Button/style'
import { device } from '../../../constants'
import { SelectStyled } from '../../../components/Select/style'

export const NavigationContainer = styled.div<any>`
  will-change: background;
  background-color: ${props =>
    props.navBar
      ? props.theme.colors.light
      : props.isDark
      ? '#f4f4ff'
      : 'transparent'};
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  z-index: 99999;
  display: block;
  width: 100%;
  height: auto;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  border-bottom: ${props =>
    props.navBar || props.isDark ? '1px solid #2e323c17' : 'none'};
  box-shadow: ${props =>
    props.navBar ? 'rgb(16 25 40 / 8%) 0px 16px 32px 0px' : 'none'};

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    height: 70px;
    min-height: 70px;
    position: sticky;
  }
`

export const NavigationWrapper = styled.div<any>`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 999;
  display: block;
  max-width: 1220px;
  margin-top: 0;
  margin-left: auto;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  position: relative;
  width: 100%;
  margin-right: auto;
`

export const SelectWrapper = styled.div<any>`
  display: flex;
  ${SelectStyled} {
    left: ${props => (props.isRight ? '-190px' : 'inherit')};
  }
`
export const NavigationLeftColumn = styled.div`
  display: flex;
  margin-right: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: left;
  align-items: center;

  ${ButtonLabel} {
    font-weight: 700;
    font-family: 'DM Sans', serif;
    font-size: 17px;
    letter-spacing: -0.5px;
    padding: 0px 8px;
  }

  @media ${device.tablet} {
    button {
      display: none;
    }
  }
`

export const NavigationLogo = styled.a`
  float: left;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  text-align: left;
  min-height: 54px;

  @media ${device.tablet} {
    width: 150px;
    margin: auto;
    margin-left: 20px;
  }
`

export const NavigationLogoImage = styled.img`
  will-change: opacity;
  opacity: 1;
  width: 120px;
  margin-left: 10px;
  height: auto;
  max-width: 100%;
  border: 0;
  vertical-align: middle;
  display: inline-block;
`

export const NavigationNav = styled.nav`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0 auto;
  position: relative;

  @media ${device.tablet} {
    display: none;
  }
`

export const NavigationNavLink = styled.span<any>`
  max-width: 1220px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
  display: block;
  line-height: 26px;
  margin-right: 20px;
  margin-left: 20px;
  line-height: 26px;
  font-weight: ${props => (props.isActive ? 600 : 400)};
  text-align: left;
  position: relative;
  vertical-align: top;
  text-decoration: none;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #30364d;
  font-size: 16px;
  font-weight: 500;
  border-bottom: ${props => (props.isActive ? '1px solid #b87df9' : 'none')};
  display: flex;
  align-items: center;
  background-color: ${props => (props.darkButton ? '#30364d' : 'transparent')};
  will-change: background;
  color: ${props => (props.darkButton ? 'white' : 'black')};
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  padding: ${props => (props.darkButton ? '10px 20px' : '0')};
  border-radius: ${props => (props.darkButton ? '8px' : '0')};
`
