import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const NewWindow = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.03 12.004l4.5 3.5-4.5 3.5"></path>
        <path d="M23.53 15.5h-3.5 0a6 6 0 00-6 6s0 0 0 0v2M1.03 4.5h20M4.03 2.25v0M4.03 2.254h0a.25.25 0 10.25.25v0a.25.25 0 00-.25-.25M6.03 2.25v0M6.03 2.254h0a.25.25 0 10.25.25v0a.25.25 0 00-.25-.25M8.03 2.25v0M8.03 2.254h0a.25.25 0 10.25.25v0a.25.25 0 00-.25-.25"></path>
        <path d="M12.03 16.5h-9 0a2 2 0 01-2-2s0 0 0 0v-12h0a2 2 0 012-2h16a2 2 0 012 2v8"></path>
      </g>
    </StyledIcon>
  )
}
export default NewWindow
