import React, {
  useState,
  useEffect,
  useMemo,
  Suspense,
  useCallback,
  useContext,
  useRef,
} from 'react'
import { AppServices } from './sdk/appServices'
import { Router } from 'react-router-dom'
import { AppContainer } from './styles'
import { ApolloProvider } from '@apollo/client'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import { ThemeProvider } from 'styled-components'
import { themes } from './themes'
import 'index.css'
import { AppContext } from './sdk/appServices'
import { createBrowserHistory } from 'history'
import { useLocation } from 'react-router'
import { getCurrentLanguage } from 'localization'
import { useViewportDimensions } from './helpers'
import 'localization'
import GlobalStyles from './GlobalStyles'
import 'react-toastify/dist/ReactToastify.css'
import { ClientRoutes } from './clientRoutes'
import { apolloClient } from './Apollo'
import { Loader } from 'components'
import moment from 'moment'
import 'moment/locale/ro'
import 'moment/locale/en-gb'
import 'moment/locale/hr'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/sr'
import { useAuth, UserContext } from 'sdk/useAuth'
import { useLanguage } from 'sdk/useLanguage'
import Geocode from 'react-geocode'
import { MarketGetCountryCitiesDocument } from 'state/graphql'
import { isFbOrIgApp } from 'components/Button/SocialLogin'
import { InfoPopup } from 'components/InfoPopup/InfoPopup'
import {
  browserLogs,
  removeLogContextProperty,
  setLogContextProperty,
} from 'sdk/browserLogs'
import { GoogleOAuthProvider } from '@react-oauth/google'

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})
history.listen(e => {
  browserLogs.log(`Route change ${e?.pathname}`, {
    pathname: e.pathname,
    search: e.search,
    state: e.state,
  })
})
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)

//zoyya
const googleClientId =
  '687616046414-tosav169mahectvb4jon1ud6p4iqvuis.apps.googleusercontent.com'

OfflinePluginRuntime.install()
export const MobileContext = React.createContext(false)
export const InfoContext = React.createContext({
  isInfoPopupOpen: true,
  setIsInfoPopupOpen: () => {},
} as any)
export const PromptToInstall = React.createContext<any>(null)
export const ThemeContext = React.createContext({
  theme: themes['default'],
})
const appServices = new AppServices(apolloClient, history)

const lang = getCurrentLanguage()
moment.locale(lang)

// for displaying work hours properly in any localization
if (lang === 'en') {
  moment.updateLocale('en', {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  })
}

const countryCodesToLangCodes = {
  RS: 'sr',
  DE: 'de',
  ES: 'es',
  HR: 'hr',
  RO: 'ro',
}

const extractCityFromGeocodeResults = (results: any) => {
  if (!results?.length) return null

  let city = null
  for (let address of results) {
    if (address.types.includes('locality')) {
      // city component
      const isBelgrade = address.address_components.find(comp =>
        comp.long_name.includes('Belgrade')
      )
      if (isBelgrade) {
        city = 'Beograd'
      } else {
        city = address?.address_components?.[0]?.long_name
      }
      break
    }
  }

  return city
}

const App = () => {
  const [user, setUser] = useState<any>()
  const userContextValue = useMemo(() => ({ user, setUser }), [user, setUser])

  const [selectedTheme] = useState('default')
  const { isMobile } = useViewportDimensions()

  const [initInProgress, setInitInProgress] = useState(true)
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(true)

  const [deferredPrompt, setDeferredPrompt] = useState(null)

  const hidePrompt = useCallback(() => {
    setDeferredPrompt(null)
  }, [])

  useEffect(() => {
    const ready = e => {
      setDeferredPrompt(e)
    }
    window.addEventListener('beforeinstallprompt', ready)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
    }
  }, [])

  useEffect(() => {
    const initApp = async () => {
      await appServices.init()

      setInitInProgress(false)
    }
    initApp()
  }, [setInitInProgress])
  useEffect(() => {
    if (user) {
      setLogContextProperty('userId', user?.id!)
      setLogContextProperty('user', user)
    } else {
      removeLogContextProperty('userId')
      removeLogContextProperty('user')
    }
  }, [user])
  if (initInProgress) return <Loader isComponent />

  return (
    <PromptToInstall.Provider value={{ deferredPrompt, hidePrompt }}>
      <AppContext.Provider value={appServices}>
        <UserContext.Provider value={userContextValue}>
          <ApolloProvider client={apolloClient}>
            <MobileContext.Provider value={isMobile}>
              <InfoContext.Provider
                value={{ isInfoPopupOpen, setIsInfoPopupOpen }}
              >
                <GoogleOAuthProvider clientId={googleClientId}>
                  <div
                    className={'kiamaki ' + (isMobile ? 'mobile' : 'desktop')}
                  >
                    <ThemeProvider
                      theme={{ ...themes[selectedTheme], name: selectedTheme }}
                    >
                      <GlobalStyles
                        selectedTheme={themes[selectedTheme]}
                        theme={selectedTheme}
                      />
                      <Router history={history}>
                        <Suspense fallback={<Loader />}>
                          <AppComponent isMobile={isMobile} />
                        </Suspense>
                      </Router>
                    </ThemeProvider>
                  </div>
                </GoogleOAuthProvider>
              </InfoContext.Provider>
            </MobileContext.Provider>
          </ApolloProvider>
        </UserContext.Provider>
      </AppContext.Provider>
    </PromptToInstall.Provider>
  )
}

export function usePromptToInstall() {
  const ctx = useContext(PromptToInstall)
  if (!ctx) {
    throw new Error(
      'Cannot use usePromptToInstall() outside <PromptToInstallProvider />'
    )
  }
  return ctx
}

function AppComponent({ isMobile }) {
  const { user, refetchUser } = useAuth()
  const { changeLanguage, restoreLanguage } = useLanguage()
  useEffect(() => {
    restoreLanguage()
  }, [restoreLanguage])

  // when using ?lang=en in address bar at the end of url
  const { search, pathname } = useLocation()
  const [preferredLang, setPreferredLang] = useState(
    search.slice(1).split('=')[1]
  )
  const [isInfoPopupHiden, setIsInfoPopupHiden] = useState(
    localStorage.getItem('hideInfoPopup') === 'true'
  )
  useEffect(() => {
    if (
      !user &&
      preferredLang &&
      (preferredLang === 'en' ||
        preferredLang === 'hr' ||
        preferredLang === 'de' ||
        preferredLang === 'sr' ||
        preferredLang === 'es')
    ) {
      changeLanguage(preferredLang)
    }
  }, [changeLanguage, preferredLang, user])

  useEffect(() => {
    if (!user?.id) return
    localStorage.setItem(
      'user',
      JSON.stringify({
        id: user.id,
        userName: user.userName,
        avatarUrl: user.avatarUrl,
        isEmailConfirmed: user.isEmailConfirmed,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
      })
    )

    if (user?.language) {
      changeLanguage(user?.language || 'hr')
    }
  }, [changeLanguage, user])

  const locationUnavailable = isFbOrIgApp() ? true : false
  const locationFetched = useRef(false)
  if (!locationUnavailable && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      pos => {
        const lat = pos.coords.latitude
        const long = pos.coords.longitude
        if (!locationFetched?.current) {
          //console.log('%cGEOCODING', 'background-color: blue;')
          locationFetched.current = true
          Geocode.fromLatLng(lat.toString(), long.toString()).then(
            response => {
              //console.log(response)
              // last element in array is the least accurate, contains only country
              const alreadyChangedLangByLocation = localStorage.getItem(
                'langChangedByLocation'
              )
              const countryCode =
                response.results?.[response.results.length - 1]
                  ?.address_components?.[0]?.short_name
              if (
                countryCode in countryCodesToLangCodes &&
                !user &&
                !alreadyChangedLangByLocation
              ) {
                localStorage.setItem('langChangedByLocation', '1')
                setPreferredLang(countryCodesToLangCodes[countryCode])
              }
              localStorage.setItem('storedCountryCode', countryCode)

              const city = extractCityFromGeocodeResults(response.results)
              if (city) {
                apolloClient
                  .query({
                    query: MarketGetCountryCitiesDocument,
                    fetchPolicy: 'cache-first',
                  })
                  .then(data => {
                    const locationCities =
                      data?.data?.market?.getCountryCities?.locationCities || []
                    if (locationCities.find(c => c.name === city)) {
                      localStorage.setItem('storedCity', city)
                    }
                  })
              }
            },
            error => {
              console.error(error)
            }
          )
        }
      },
      err => {
        console.log(
          '%cGeolocation is not allowed in this browser',
          'background-color: red;'
        )
      }
    )
  }

  return (
    <>
      <AppContainer isMobile={isMobile}>
        <ClientRoutes isMobile={isMobile} />
        {user &&
        !user?.email &&
        !isInfoPopupHiden &&
        !pathname?.includes('wizard') ? (
          <InfoPopup
            user={user}
            setIsInfoPopupHiden={setIsInfoPopupHiden}
            refetchUser={refetchUser}
          />
        ) : null}
      </AppContainer>
    </>
  )
}

export default App
