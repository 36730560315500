import { createGlobalStyle } from 'styled-components'
import { fontFamily } from './components/style/fonts'
import { device } from './constants'
import { ITheme } from 'themes'
const GlobalStyles = createGlobalStyle<{ selectedTheme: ITheme }>`

//hide Hubspot cookie banner
div#hs-eu-cookie-confirmation{display:none;}


.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    font-size: 18px;
    font-family: ${fontFamily} !important;
    @media ${device.tablet}{
        font-size: 16px;
    }
}


input[type='radio'], input[type='checkbox']{
  margin-left: 2px;
  -moz-appearance:initial;
width: 35px;
}
.rc-tooltip-inner{
  @media ${device.tablet}{
  border: none;

  }
}
.react-tel-input input {
  min-height: 35px;
  width: 90%;
}
p {
  margin-bottom: 0px;
}
.react-tel-input {
  width: auto;
}
  
:focus {
   @media ${device.tablet}{
      outline: none
    }
}
.rc-tooltip{
  background: ${props => props.selectedTheme.colors.light};
  z-index: 999999;
}


.rc-tooltip-arrow-inner.sidebar{
    border-right-color: ${props => props.selectedTheme.colors.primary}; 
}

.rc-tooltip-arrow-inner.infoButton{
    border-top-color: ${props => props.selectedTheme.colors.text}; 
}

#react-select-portal-target {
 div {
  z-index: 99999999999999;
  }
}

.Toastify__toast-container {
  border-radius: 10px;
  z-index: 9999999999;
  width: auto;
  max-width: 420px;
    
  @media ${device.tablet}{
    max-width: 100%;
    width: 100%;
    border-radius: 0px;
  }
}

.Toastify__toast-body{
  @media ${device.tablet}{
     text-align: center;
    }
}

.Toastify__toast {
 @media ${device.tablet}{
     min-height: 50px;
    }
}

.Toastify__toast--success {
  background: ${props => props.selectedTheme.colors.primary} !important; 
  border-radius: 10px !important;
  padding: 10px 20px !important;
    
  @media ${device.tablet}{
     border-radius: 0px !important;
  }
}
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.Toastify__toast--error {
  background: ${props => props.selectedTheme.colors.accent5} !important; 
  border-radius: 10px !important;
  padding: 10px 20px !important;
}

.Toastify__toast--default {
    border-radius: 10px !important;
    padding: 10px 20px !important;
}




[data-reach-tooltip] {
  z-index: 999999999999999 !important;
}

html,
body {
    font-family: ${fontFamily};
    width: 100%;
    height: 100%;
    font-size: 14px;
   
     @media ${device.tablet}{
     overflow: hidden;
      user-select: none;
        &::-webkit-scrollbar {
          display: none;
        }
    }
}


.kiamaki {
  height: 100%;
  display: flex;

  @media ${device.tablet}{
    //max-height: calc((var(--vh, 1vh) * 100));
  }
}

input {
  font-family: ${fontFamily} !important;
}

a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    
    :hover {
        text-decoration: none;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    width: 100%;
    height: 100%;

}


`

export default GlobalStyles
