import styled from 'themes'
import { transparentize } from 'polished'
import { device } from '../../../constants'
import { FieldColumn, FieldRow } from 'views/Settings/styles'

export const WizardWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-left: 15px;
  justify-content: flex-start;
  margin-bottom: 50px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${device.tablet} {
    padding-left: 0;
  }
`

export const BorderContainer = styled.div`
  position: relative;
`

export const WavePattern = styled.div`
  text-align: center;
  height: 20px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -15px;
  margin-top: -8px;
  overflow: hidden;
  background: transparent;

  &:before {
    content: '';
    position: absolute;
    left: 5px;
    bottom: 0;
    right: 0;
    height: 10px;
    background-size: 40px 23px;
    background-repeat: repeat-x;
    background-image: radial-gradient(
      circle at 10px -5px,
      ${props => props.theme.colors.lightBackground} 12px,
      rgba(255, 255, 255, 0) 13px
    );
  }

  &:after {
    content: '';
    position: absolute;
    left: -15px;
    bottom: 0;
    right: 0;
    height: 15px;
    background-size: 40px 8px;
    background-image: radial-gradient(
      circle at 10px 17px,
      rgba(255, 255, 255, 0) 13px,
      ${props => props.theme.colors.lightBackground} 14px
    );
    background-repeat: repeat-x;
  }
`

export const WaveSpacer = styled.div`
  height: 30px;
  background: initial;
`

export const WizardContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  min-height: 80vh;
`

export const HeaderTitles = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${device.tablet} {
    padding: 15px 0px 0px 15px;
    color: ${props => props.theme.colors.light};
  }
`

export const HeaderSubTitle = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.secondaryDark};
  line-height: normal;
  transform-origin: left top;
  width: 100%;
  word-break: break-word;
  z-index: 12;
  position: relative;
  display: block;
  height: 20px;
  backface-visibility: hidden;
  font-weight: 400;
  margin-left: 20px;

  @media ${device.tablet} {
    color: ${props => props.theme.colors.light};
    margin-left: 15px;
  }
`

export const HeaderTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.text};
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  transform-origin: left top;
  word-break: break-word;
  transform-style: preserve-3d;
  overflow: hidden;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-bottom: 0;
  @media ${device.tablet} {
    color: ${props => props.theme.colors.light};
    margin: 0.1em 0 0.2em 0;
    padding: 5px 10px 0px 15px;
  }
`

type TWizardBodyProps = {
  isFullWidth?: boolean
}
export const WizardBody = styled.div<TWizardBodyProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: opacity 100ms;
  width: 100%;
  min-height: 0;
  max-width: ${props => (props.isFullWidth ? '1220px' : '820px')};
`

export const WizardHeaderSticky = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  background: white;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};

  @media ${device.tablet} {
    display: none;
  }
`

export const WizardBackHeader = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
`

export const WizardSectionRoot = styled.div`
  width: 100%;
`

export const WizardCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  gap: 0px;
  background: ${props => props.theme.colors.light};
  border-radius: 8px;

  position: relative;
`

export const WizardCardsBody = styled.div``

type TWizardCardITemProps = {
  hasCheckbox?: boolean
  isNonBooking?: boolean
}
export const WizardCardItem = styled.div<TWizardCardITemProps>`
  position: relative;
  padding-bottom: ${props => (props.hasCheckbox ? '10px' : '15px')};
  min-height: 70px;
  margin-bottom: ${props => (props.isNonBooking ? '0' : null)};
  padding: ${props => (props.isNonBooking ? '15px 15px 0 15px' : null)};
  @media ${device.tablet} {
    min-height: auto;
    background: ${props => props.theme.colors.light};
    margin-bottom: ${props => (props.isNonBooking ? null : '15px')};
    border-radius: ${props => (props.isNonBooking ? null : '10px')};
    padding: 15px;
    :not(:last-of-type) {
      border-bottom: ${props =>
        props.isNonBooking ? '1px solid rgb(219, 223, 226)' : null};
    }
  }
`

export const WizardComplexCardItem = styled.div`
  display: flex;
  max-width: calc(100vw - 430px);
  padding: 20px 30px;
  align-items: flex-end;
`

type TWizardServiceItemWrapper = {
  isNonBooking?: boolean
}
export const WizardServiceItemWrapper = styled.div<TWizardServiceItemWrapper>`
  display: flex;
  flex-direction: row;
  cursor: ${props => (props.isNonBooking ? 'default' : 'pointer')};
  position: relative;
  align-items: flex-start;
`

export const WizardServiceComplexItemWrapper = styled(WizardServiceItemWrapper)`
  padding-bottom: 15px;
`

export const WizardActionContainer = styled.div`
  @media ${device.tablet} {
    margin-right: 15px;
  }
`

export const WizardAddButton = styled.div`
  display: flex;
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  color: ${props => props.theme.colors.light};
  background-color: ${props => props.theme.colors.light};
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.outline};
  border-image: initial;
  border-radius: 8px;
  margin-left: 0.2rem;
  transition: background 300ms ease 0s, border-color 300ms ease 0s,
    color 300ms ease 0s;

  &.selected {
    color: ${props => props.theme.colors.light};
    border-color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.primary};

    svg {
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.light};
    }
  }
`

type TWizardServiceDetail = {
  isNonBooking?: boolean
}
export const WizardServiceDetail = styled.div<TWizardServiceDetail>`
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  padding-left: ${props => (props.isNonBooking ? null : '15px')};
  flex-flow: row wrap;

  @media ${device.tablet} {
    padding-left: 0px;
  }
`

export const WizardComplexRow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  min-width: 200px;

  ${WizardServiceDetail} {
    padding-left: 0px;
  }
`

export const WizardComplexRowTimes = styled(WizardComplexRow)`
  width: 100%;
`

export const WizardComplexTimes = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: auto;
  padding-bottom: 4px;
  &::-webkit-scrollbar {
    display: none;
  }
`
type TWizardComplexTimeProps = {
  isDisabled?: boolean
  isSelected?: boolean
}
export const WizardComplexTime = styled.div<TWizardComplexTimeProps>`
  border: 1px solid ${props => props.theme.colors.outline};
  padding: 10px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: ${props => (props.isDisabled ? 'initial' : 'pointer')};
  color: ${props =>
    props.isSelected ? props.theme.colors.light : props.theme.colors.text};
  background: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.light};
  opacity: ${props => (props.isDisabled ? '0.6' : '1')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`

export const WizardServiceHeader = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
`

export const WizardProfessionHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 40px;
`

export const WizardServiceCardName = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme.colors.text};
  margin: 0px;
`

export const WizardProfessionTitle = styled.p<{ noSpaces?: boolean }>`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: ${props => props.theme.colors.text};
  margin: 0px;
  word-break: ${props => (props.noSpaces ? 'break-all' : null)};
  @media ${device.tablet} {
    font-size: 1rem;
    word-break: break-word;
  }
`

export const WizardProfession = styled.p`
  span {
    letter-spacing: 0px;
    min-width: 0px;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: ${props => props.theme.colors.text};
    margin: 0px;
    @media ${device.tablet} {
      font-size: 1rem;
    }
  }
  display: flex;
  align-items: center;
`

export const ComplexServices = styled.div`
  padding-top: 10px;

  ${WizardServiceCardName} {
    line-height: 28px;
  }
`
export const WizardServiceCardDescription = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${props => props.theme.colors.secondaryDarker};
  margin: 0px;
`

export const WizardServiceDesc = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.text};
  margin-top: 10px;
`

export const WizardPriceContainer = styled.div`
  text-align: right;
  padding-left: 16px;
  white-space: nowrap;
  flex: 1;
  p {
    letter-spacing: 0px;
    min-width: 0px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: ${props => props.theme.colors.text};
    margin: 0px;
    span {
      white-space: nowrap;
      display: inline-block;
    }

    @media ${device.tablet} {
      font-size: 14px;
    }
  }
`

export const WizardComplexServiceDuration = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.text};
`

export const WizardFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.outline};
  display: flex;
  align-items: center;
  width: calc(100% - 15px);
  padding: 15px 40px;
  justify-content: flex-end;
  position: sticky;
  bottom: 0px;
  background: white;
  button:first-child {
    margin-right: 10px;
  }
`

export const WizardEmployeeContainer = styled.div`
  transform: initial;
  width: 100%;
  align-self: flex-start;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: initial;
`

export const WizardEmployeeSticky = styled.div``

export const WizardEmplyeeStickyHeader = styled.div`
  backface-visibility: hidden;
  position: relative;
  transition: border-radius 300ms ease 100ms;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: '';
    opacity: 0;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 8px 16px 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 8px;
    transition: opacity 300ms ease 0s;
  }

  &::after {
    bottom: 0px;
    content: '';
    position: absolute;
    right: 0px;
    height: 1px;
    left: 0px;
  }
`

export const WizardEmployeeCenter = styled.div`
  flex: 1;
  padding-left: 130px;
  padding-right: 130px;
`

type TWizardEmployeeItemProps = {
  isDisabled?: boolean
}
export const WizardEmployeeItem = styled.a<TWizardEmployeeItemProps>`
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  min-height: 72px;
  transition: background 300ms ease 0s;
  padding: 15px 15px;
  position: relative;
  background: ${props => (props.isDisabled ? '#f4f4ff' : 'inherit')};
  border-radius: 4px;

  &:hover {
    cursor: ${props => (props.isDisabled ? 'initial' : 'pointer')};
    background: ${props =>
      !props.isDisabled
        ? transparentize(0.3, props.theme.colors.lightBackground)
        : '#f4f4ff'};
  }

  @media ${device.tablet} {
    &:hover {
      background: transparent;
    }
    min-height: 60px;
    margin: 10px 10px 0px 10px !important;
    padding: 0px 10px;
  }
`

export const WizardEmployeeListItemPrefix = styled.div`
  flex-direction: column;
  flex-basis: auto;
  -webkit-box-flex: 0;
  flex-grow: 0;
  display: flex;
  align-items: flex-start;
  order: 1;
  margin-right: 20px;

  @media ${device.tablet} {
    margin-right: 10px;
  }
`

export const WizardEmployeeAvatarWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  will-change: transform;
  width: 56px;
  min-width: 56px;
  min-height: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  background: rgb(242, 242, 247);

  svg {
    display: block;

    flex-shrink: 0;
  }
`

export const WizardEmployeeAvatarImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  background: transparent;
  animation: 0s ease 0s 1 normal none running none;
  overflow: hidden;
  width: 100%;
`

type TWizardEmployeeAvatarImage = {
  imageUrl?: string
}
export const WizardEmployeeAvatarImage = styled.div<TWizardEmployeeAvatarImage>`
  width: 100%;
  background-size: cover;
  opacity: 1;
  background-image: url(${props => props.imageUrl});
  padding-top: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 200ms ease 0s;
  margin: 0px !important;
`

export const WizardEMployeeListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  order: 2;
  min-width: 0px;
  -webkit-box-pack: center;
  justify-content: center;
`

export const WizardEmployeeName = styled.p<{ isVertical?: boolean }>`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(16, 25, 40);
  margin: 0px;

  text-align: ${props => (props.isVertical ? 'center' : 'initial')};
  margin-top: ${props => (props.isVertical ? '10px' : 'inherit')};
  @media ${device.tablet} {
    font-size: 18px;
  }
`

export const WizardEmployeeDescription = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  color: rgb(135, 140, 147);
  margin: 0px;
`

export const WizardListItemSufix = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: auto;
  -webkit-box-flex: 0;
  flex-grow: 0;
  align-items: flex-end;
  margin-left: 20px;
  order: 3;

  svg {
    width: 16px;
    height: 16px;
  }
`

export const WizardEmployees = styled.div`
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  transform: translateZ(0px);
  padding-top: 0px;
`

export const WizardTimeContainer = styled.div`
  transform: initial;
  width: 100%;
  align-self: flex-start;
  padding-bottom: env(safe-area-inset-bottom);
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: white;
  overflow: initial;
  position: relative;
  margin-bottom: 20px;
  justify-content: center;
`

export const WizardConfirmContainer = styled(WizardTimeContainer)`
  justify-content: flex-start;
`

export const WizardTimeSticky = styled.div``

export const WizardTimeStickyHeader = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  backface-visibility: hidden;
  position: relative;
  transition: border-radius 300ms ease 100ms;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &::before {
    position: absolute;
    display: block;
    content: '';
    opacity: 0;
    box-shadow: rgba(16, 25, 40, 0.08) 0px 8px 16px 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 8px;
    transition: opacity 300ms ease 0s;
  }

  &::after {
    bottom: 0px;
    content: '';
    position: absolute;
    right: 0px;
    height: 1px;
    left: 0px;
  }
`

export const WizardTimeHeaderWrapper = styled.div`
  display: flex;
  position: relative;
  -webkit-box-align: center;
  height: 98px;
  align-items: flex-start;
  justify-content: center;
`

export const WizardTimeArrowButton = styled.button`
  height: 56px;
  width: 56px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  display: none;
  outline: none;
  flex: 0 0 auto;
  margin: 10px 0px 0px;
  padding: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background: none;
  display: flex;
  margin-right: 20px;

  &.disabled {
    opacity: 0;
  }
  svg {
    width: 16px;
    height: 16px;
    transform: translateX(10px);
    transition: transform 500ms ease 0s;
  }
`

export const WizardTimeCourasel = styled.div`
  max-width: none;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  display: flex;
  position: relative;
  scroll-behavior: smooth;
  padding-top: 10px;
  overflow: hidden;
  scroll-snap-type: initial;
  scroll-behavior: auto;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }
`

type TWizardTimeElementProps = {
  isToday?: boolean
  disabled?: boolean
}
export const WizardTimeElement = styled.div<TWizardTimeElementProps>`
  display: inline-flex;
  scroll-snap-align: start;
  -webkit-box-pack: center;
  justify-content: center;
  width: 64px;
  padding-left: 16px;
  box-sizing: content-box;
  padding-left: 8px;
  padding-right: 8px;
  scroll-margin: 0px;

  button {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 64px;
    width: 64px;
    cursor: pointer;
    outline: none;
    background: none;
    border-width: 1px;
    border-style: solid;
    border-color: ${props =>
      props.isToday ? props.theme.colors.accent4 : props.theme.colors.outline};
    border-image: initial;
    border-radius: 4px;
    padding: 12px 16px;
    background: ${props =>
      props.isToday
        ? props.theme.colors.accent4
        : props.disabled
        ? props.theme.colors.background
        : '#fff'};
  }
`
type TWizardTimeTitleProps = {
  isToday?: boolean
}
export const WizardTimeTitle = styled.p<TWizardTimeTitleProps>`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  color: ${props =>
    props.isToday ? 'rgb(255, 255, 255)' : props.theme.colors.text};
  margin: 0px;
`
type TWizardTimeDayProps = {
  isToday?: boolean
}
export const WizardTimeDay = styled.p<TWizardTimeDayProps>`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: ${props =>
    props.isToday ? 'rgb(255, 255, 255)' : props.theme.colors.text};
  margin: 0px;
`

export const WizardTimeMonth = styled.div`
  text-align: center;
`

export const WizardNoDateContainer = styled.div`
  padding: 12px 8px 48px;
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;

  button {
    margin-top: 20px;
  }
`

export const WizardNoDatePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.mobileL} {
    flex-direction: column;
    align-items: start;
  }
`

export const WizardNoTimeTitle = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  line-height: 28px;
  color: ${props => props.theme.colors.accent1};
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;

  @media ${device.tablet} {
    margin: 10px;
  }
`

export const WizardNoTimeMessage = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.secondaryDark};
  margin: 8px 0px 0px;
`

export const WizardTimesWrapper = styled.div`
  transition: opacity 100ms;
  min-height: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  max-height: 500px;
`
type TWizardTimesItemProps = {
  isAvailable?: boolean
  isSelected?: boolean
}
export const WizardTimesItem = styled.div<TWizardTimesItemProps>`
  text-decoration: none;
  display: ${props => (!props.isAvailable ? 'none' : 'flex')};
  flex-direction: row;
  transition: background 300ms ease 0s;
  padding: 9px;
  margin: 3px;
  position: relative;
  min-width: 90px;
  /* width: 115px; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${props =>
    props.isSelected ? props.theme.colors.lightBackground : 'transparent'};
  font-size: ${props => (props.isSelected ? '1.3rem' : 'initial')};
  font-weight: ${props => (props.isSelected ? '700' : '500')};
  color: ${props => props.theme.colors.primary};
  &:hover {
    background: ${props => transparentize(0.7, props.theme.colors.accent2)};
  }
`

export const GroupWizardTimesItem = styled(WizardTimesItem)<{
  hasPassed: boolean
  isSelected?: boolean
}>`
  justify-content: space-between;
  box-shadow: 1px 1px 3px rgba(51, 51, 51, 0.35);
  border-radius: 10px;
  //padding: '9px 5px',
  border: ${props => (props.isSelected ? '0.5px solid' : 'none')};
  &:hover {
    background: ${props =>
      !props.hasPassed
        ? transparentize(0.7, props.theme.colors.accent2)
        : 'rgb(241 215 203 / 50%)'};
  }
  background: ${props =>
    props.hasPassed
      ? 'rgb(241 215 203 / 50%)'
      : props.isSelected
      ? props.theme.colors.lightBackground
      : props.theme.colors.light};
  max-width: 150px;
  @media ${device.tablet} {
    background: transparent;
    font-size: 16px;
    font-weight: 500;

    max-width: unset;
    justify-content: unset;
    box-shadow: unset;
    border: 1px solid ${props => props.theme.colors.outline};
    margin: 5px 0;
    background: ${props =>
      props.isSelected
        ? props.theme.colors.lightBackground
        : props.theme.colors.light};
    border: ${props =>
      props.isSelected
        ? `1px solid ${props.theme.colors.primary}`
        : `1px solid ${props.theme.colors.outline}`};
    &:hover {
      background: ${props =>
        props.isSelected
          ? props.theme.colors.lightBackground
          : props.theme.colors.light};
    }
  }
`
export const WizardTimesCapacityItem = styled.div`
  display: inline-flex;
  font-size: 0.9rem;
  font-weight: 400;
  color: ${props => props.theme.colors.secondaryDark};
  @media ${device.tablet} {
    font-size: 14px;
  }
`

export const WizardTimesItemContent = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  order: 2;
  min-width: 0px;
  -webkit-box-pack: center;
  justify-content: center;

  p {
    letter-spacing: 0px;
    min-width: 0px;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    color: ${props => props.theme.colors.text};
    margin: 0px;
  }
`

export const WizardTimesListItemSuffix = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  flex-basis: auto;
  -webkit-box-flex: 0;
  flex-grow: 0;
  align-items: flex-end;
  margin-left: 16px;
  order: 3;

  svg {
    width: 16px;
    height: 16px;
  }
`

export const WizardConfirmTitle = styled.p`
  font-size: 20px;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  margin-top: 14px;
  line-height: 30px;
`

export const WizardConfirmLabel = styled.span`
  color: ${props => props.theme.colors.text};
  font-weight: 500;
  margin-right: 5px;
`

export const WizardConfirmText = styled.p`
  font-size: 16px;
  padding-bottom: 4px;
  color: ${props => props.theme.colors.text};
  line-height: 24px;
`

export const WizardConfirmTime = styled.p`
  font-size: 32px;
  color: ${props => props.theme.colors.text};
  font-weight: 600;

  @media ${device.tablet} {
    font-size: 24px;
  }
`

export const WizardConfirmName = styled.p`
  font-size: 18px;
  padding-bottom: 4px;
  font-weight: 500;
  color: ${props => props.theme.colors.text};
`

export const WizardConfirmHint = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.secondaryDark};
  max-width: 500px;
  margin-bottom: 25px;
  text-align: center;
  @media ${device.tablet} {
    display: none;
  }
`

export const WizardConfirmImage = styled.img`
  max-height: 540px;

  @media ${device.tablet} {
    display: none;
  }
`

export const WizardConfirmWrapper = styled.div`
  flex-direction: column;
  flex: 2;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

type TWizardConfirmSectionProps = {
  isFullWidth?: boolean
}
export const WizardConfirmSection = styled.div<TWizardConfirmSectionProps>`
  margin-left: 0;
  width: ${props => (props.isFullWidth ? '100%' : 'auto')};
`

export const WizardConfirmBody = styled.div`
  display: flex;
  max-height: 500px;
`

export const WizardCelebrate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
`

export const WizardLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  @media ${device.tablet} {
    width: 100vw;
    padding: 20px;

    ${FieldRow} {
      flex-direction: row;

      ${FieldColumn} {
        :not(:last-child) {
          padding-right: 14px;
        }
      }
    }
  }
`

export const WizardLoginTitle = styled.span`
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WizardLoginHint = styled.span`
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${props => props.theme.colors.secondaryDark};
  font-weight: 400;
`

type TWizardHourSlotProps = {
  isVisible?: boolean
}
export const WizardHourSlot = styled.div<TWizardHourSlotProps>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(240, 240, 240);
  align-items: center;
  justify-content: space-between;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
`
export const WizardHourItem = styled.div`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  transition: background 300ms ease 0s;
  position: relative;
  justify-content: center;
  flex: 1;
  font-size: 2rem;
  font-weight: bold;
  margin-right: 12px;
  min-width: 55px;
  max-width: 55px;
  @media ${device.tablet} {
    min-width: 45px;
    font-size: 22px;
    &:hover {
      background: transparent;
    }
  }
`
export const GroupWizardDayItem = styled.div`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  transition: background 300ms ease 0s;
  position: relative;
  justify-content: center;
  flex: 1;
  font-size: 1.15rem;
  font-weight: bold;
  min-width: 165px;
  //max-width: 100px;
  @media ${device.tablet} {
    min-width: 145px;
    width: 100%;
    text-align: center;
    background: rgb(233 233 255 / 50%);
    border-radius: 4px;
    padding: 4px 0px;
    //max-width: 55px;
    //font-size: 14px;
    &:hover {
      background: transparent;
    }
  }
`
type TCategoryItem = {
  isActive?: boolean
  isMobile?: boolean
}
export const CategoryItem = styled.li<TCategoryItem>`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  padding-left: 16px;
  padding-right: 24px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  max-height: 40px;
  opacity: 1;
  margin-bottom: 8px;
  transition: max-height 300ms ease 0s, opacity 300ms ease 0s;
  max-width: ${props => (props.isMobile ? '100%' : '300px')};
  background: ${props =>
    props.isActive ? props.theme.colors.accent4 : 'transparent'};

  &:before {
    content: ${props => (props.isActive ? '""' : '')};
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    border-top: 20px solid rgb(255, 255, 255);
    border-bottom: 20px solid rgb(255, 255, 255);
    border-left: 20px solid ${props => props.theme.colors.accent4};
  }

  input {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media ${device.tablet} {
    &:before {
      border: none;
    }
    border-radius: 8px;
  }
`
type TCategoryName = {
  isActive?: boolean
}
export const CategoryName = styled.p<TCategoryName>`
  letter-spacing: 0px;
  min-width: 100px;
  font-size: 14px;
  font-weight: ${props => (props.isActive ? '600' : '400')};
  line-height: 24px;
  color: ${props =>
    props.isActive ? props.theme.colors.light : props.theme.colors.text};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0px;
  overflow: hidden;
  margin-right: auto;
`
type TCategoryServicesList = {
  isMobile?: boolean
}
export const CategoryServicesList = styled.div<TCategoryServicesList>`
  display: flex;
  flex-direction: column;
  flex: 1;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
`
export const ServicesContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100%;

  @media ${device.tablet} {
    flex-direction: column;
  }
`
export const ReadMore = styled.div<any>`
  width: 100%;
  margin-top: ${props => (props.isOpen ? null : '-20px')};
  padding-top: ${props => (props.isOpen ? null : '20px')};
  background: ${props =>
    props.isOpen ? null : 'linear-gradient(#ffffff70, white)'};
  color: ${props => props.theme.colors.primary};
`
export const EnteredNumberContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`

export const EnteredNumberLabel = styled.p`
  margin-right: 3px;
`

export const EnteredNumber = styled.p`
  color: ${props => props.theme.colors.primary};
`

export const DatePicked = styled.div`
  position: fixed;
  top: 55px;
  padding: 15px;
  background: white;
  text-align: center;
  z-index: 1;
  transition: opacity 0.3s;
  opacity: 0;
  width: 100%;
  font-size: 16px;
  box-shadow: rgb(16 25 40 / 8%) 0px 8px 16px 0px;
  &.show {
    opacity: 1;
  }
`
export const PrepaymentHint = styled.div`
  border: 1px solid ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.lightBackground};
  padding: 15px;
  border-radius: 4px;
  max-width: 750px;
  margin-top: -55px;
  position: relative;
  text-align: center;
  @media ${device.tablet} {
    border: unset;
    background: unset;
    padding: unset;
    margin-top: unset;
  }
`
export const PrepaymentHintText = styled.span`
  font-weight: 500;
  color: #f14949;
  margin-right: 5px;
  margin-left: 5px;
`
export const PriceInEur = styled.p`
  font-weight: 500 !important;
  font-size: 14px !important;
  color: ${props => props.theme.colors.secondaryDarker}!important;
  @media ${device.tablet} {
    font-size: 12px !important;
  }
`
