import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: ${props => props.fill || 'none'};
`

export const RatingStar = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        fill="inherit"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.551 5.745h0a.5.5 0 01.9 0L14.3 9.483l4.144.6h0a.5.5 0 01.276.854l-3 2.9.708 4.1h0a.5.5 0 01-.724.528L12 16.531 8.289 18.47h0a.5.5 0 01-.724-.528l.708-4.1-3-2.9h0a.5.5 0 01.276-.854l4.145-.6z"
      ></path>
    </StyledIcon>
  )
}
export default RatingStar
