import React from 'react'
import styled from 'styled-components'
import { SectionTitle } from './SectionTitle'
import { ButtonStyled } from '../Button/Button'

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${ButtonStyled} {
    width: auto;
    margin: 0 !important;
  }
`
const StyledSectionBody = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 2rem;
`
type TProps = {
  title?: string
  children?: any
}
export const SectionContainer = (props: TProps) => {
  const { title, children } = props
  return (
    <StyledSectionContainer>
      <SectionTitle title={title}></SectionTitle>
      <StyledSectionBody>{children}</StyledSectionBody>
    </StyledSectionContainer>
  )
}
