export const publicAssets = {
  '1.png': `${process.env.PUBLIC_URL}/assets/1.png`,
  '2.png': `${process.env.PUBLIC_URL}/assets/2.png`,
  '22.png': `${process.env.PUBLIC_URL}/assets/22.png`,
  '3.png': `${process.env.PUBLIC_URL}/assets/3.png`,
  '404.svg': `${process.env.PUBLIC_URL}/assets/404.svg`,
  'advancedPlan.svg': `${process.env.PUBLIC_URL}/assets/advancedPlan.svg`,
  'allDone.svg': `${process.env.PUBLIC_URL}/assets/allDone.svg`,
  'Asset 46.svg': `${process.env.PUBLIC_URL}/assets/Asset 46.svg`,
  'bg.png': `${process.env.PUBLIC_URL}/assets/bg.png`,
  'billingWarning.svg': `${process.env.PUBLIC_URL}/assets/billingWarning.svg`,
  'braintree-logo-black.png': `${process.env.PUBLIC_URL}/assets/braintree-logo-black.png`,
  'businessPlan.svg': `${process.env.PUBLIC_URL}/assets/businessPlan.svg`,
  'buttonLoading.svg': `${process.env.PUBLIC_URL}/assets/buttonLoading.svg`,
  'celebratePlan.svg': `${process.env.PUBLIC_URL}/assets/celebratePlan.svg`,
  'check-mark.svg': `${process.env.PUBLIC_URL}/assets/check-mark.svg`,
  'confetti.svg': `${process.env.PUBLIC_URL}/assets/confetti.svg`,
  'confirm-new.svg': `${process.env.PUBLIC_URL}/assets/confirm-new.svg`,
  'creditCard.svg': `${process.env.PUBLIC_URL}/assets/creditCard.svg`,
  'default-avatar.svg': `${process.env.PUBLIC_URL}/assets/default-avatar.svg`,
  'default-logo-sidebar.svg': `${process.env.PUBLIC_URL}/assets/default-logo-sidebar.svg`,
  'default-logo.svg': `${process.env.PUBLIC_URL}/assets/default-logo.svg`,
  'empty-box.svg': `${process.env.PUBLIC_URL}/assets/empty-box.svg`,
  'empty-image.svg': `${process.env.PUBLIC_URL}/assets/empty-image.svg`,
  'empty-reservations.svg': `${process.env.PUBLIC_URL}/assets/empty-reservations.svg`,
  'empty-tasks.svg': `${process.env.PUBLIC_URL}/assets/empty-tasks.svg`,
  'favi32.png': `${process.env.PUBLIC_URL}/assets/favi32.png`,
  'favicon.ico': `${process.env.PUBLIC_URL}/assets/favicon.ico`,
  'favicon.png': `${process.env.PUBLIC_URL}/assets/favicon.png`,
  'female1.svg': `${process.env.PUBLIC_URL}/assets/female1.svg`,
  'female10.svg': `${process.env.PUBLIC_URL}/assets/female10.svg`,
  'female11.svg': `${process.env.PUBLIC_URL}/assets/female11.svg`,
  'female12.svg': `${process.env.PUBLIC_URL}/assets/female12.svg`,
  'female13.svg': `${process.env.PUBLIC_URL}/assets/female13.svg`,
  'female14.svg': `${process.env.PUBLIC_URL}/assets/female14.svg`,
  'female15.svg': `${process.env.PUBLIC_URL}/assets/female15.svg`,
  'female16.svg': `${process.env.PUBLIC_URL}/assets/female16.svg`,
  'female17.svg': `${process.env.PUBLIC_URL}/assets/female17.svg`,
  'female2.svg': `${process.env.PUBLIC_URL}/assets/female2.svg`,
  'female3.svg': `${process.env.PUBLIC_URL}/assets/female3.svg`,
  'female4.svg': `${process.env.PUBLIC_URL}/assets/female4.svg`,
  'female5.svg': `${process.env.PUBLIC_URL}/assets/female5.svg`,
  'female6.svg': `${process.env.PUBLIC_URL}/assets/female6.svg`,
  'female7.svg': `${process.env.PUBLIC_URL}/assets/female7.svg`,
  'female8.svg': `${process.env.PUBLIC_URL}/assets/female8.svg`,
  'female9.svg': `${process.env.PUBLIC_URL}/assets/female9.svg`,
  'fitness-weightlift.1.svg': `${process.env.PUBLIC_URL}/assets/fitness-weightlift.1.svg`,
  'flower2.svg': `${process.env.PUBLIC_URL}/assets/flower2.svg`,
  'flower3.svg': `${process.env.PUBLIC_URL}/assets/flower3.svg`,
  'flowerWhite.svg': `${process.env.PUBLIC_URL}/assets/flowerWhite.svg`,
  'flowerWhiteLogo.svg': `${process.env.PUBLIC_URL}/assets/flowerWhiteLogo.svg`,
  'freeDowngrade.svg': `${process.env.PUBLIC_URL}/assets/freeDowngrade.svg`,
  'hair-dress-cut.1.svg': `${process.env.PUBLIC_URL}/assets/hair-dress-cut.1.svg`,
  'house-chimney-1.svg': `${process.env.PUBLIC_URL}/assets/house-chimney-1.svg`,
  'ivana-black.jpeg': `${process.env.PUBLIC_URL}/assets/ivana-black.jpeg`,
  'login-bg.svg': `${process.env.PUBLIC_URL}/assets/login-bg.svg`,
  'login-illustration.svg': `${process.env.PUBLIC_URL}/assets/login-illustration.svg`,
  'loginImg.svg': `${process.env.PUBLIC_URL}/assets/loginImg.svg`,
  'loginImg2.svg': `${process.env.PUBLIC_URL}/assets/loginImg2.svg`,
  'loginStep.svg': `${process.env.PUBLIC_URL}/assets/loginStep.svg`,
  'logo-blue.svg': `${process.env.PUBLIC_URL}/assets/logo-blue.svg`,
  'logo-color2.svg': `${process.env.PUBLIC_URL}/assets/logo-color2.svg`,
  'logo-color_3.svg': `${process.env.PUBLIC_URL}/assets/logo-color_3.svg`,
  'logo-icon.svg': `${process.env.PUBLIC_URL}/assets/logo-icon.svg`,
  'logo-new-color.svg': `${process.env.PUBLIC_URL}/assets/logo-new-color.svg`,
  'logo-new-white.svg': `${process.env.PUBLIC_URL}/assets/logo-new-white.svg`,
  'logo-new-white_1.svg': `${process.env.PUBLIC_URL}/assets/logo-new-white_1.svg`,
  'logo-purple.svg': `${process.env.PUBLIC_URL}/assets/logo-purple.svg`,
  'logo-white.svg': `${process.env.PUBLIC_URL}/assets/logo-white.svg`,
  'logoLetter.svg': `${process.env.PUBLIC_URL}/assets/logoLetter.svg`,
  'logoLetter_1.svg': `${process.env.PUBLIC_URL}/assets/logoLetter_1.svg`,
  'logoWhite.svg': `${process.env.PUBLIC_URL}/assets/logoWhite.svg`,
  'male1.svg': `${process.env.PUBLIC_URL}/assets/male1.svg`,
  'male10.svg': `${process.env.PUBLIC_URL}/assets/male10.svg`,
  'male11.svg': `${process.env.PUBLIC_URL}/assets/male11.svg`,
  'male12.svg': `${process.env.PUBLIC_URL}/assets/male12.svg`,
  'male13.svg': `${process.env.PUBLIC_URL}/assets/male13.svg`,
  'male14.svg': `${process.env.PUBLIC_URL}/assets/male14.svg`,
  'male2.svg': `${process.env.PUBLIC_URL}/assets/male2.svg`,
  'male3.svg': `${process.env.PUBLIC_URL}/assets/male3.svg`,
  'male4.svg': `${process.env.PUBLIC_URL}/assets/male4.svg`,
  'male5.svg': `${process.env.PUBLIC_URL}/assets/male5.svg`,
  'male6.svg': `${process.env.PUBLIC_URL}/assets/male6.svg`,
  'male7.svg': `${process.env.PUBLIC_URL}/assets/male7.svg`,
  'male8.svg': `${process.env.PUBLIC_URL}/assets/male8.svg`,
  'male9.svg': `${process.env.PUBLIC_URL}/assets/male9.svg`,
  'microPlan.svg': `${process.env.PUBLIC_URL}/assets/microPlan.svg`,
  'narucime_logo_white-02.svg': `${process.env.PUBLIC_URL}/assets/narucime_logo_white-02.svg`,
  'navigation-menu.svg': `${process.env.PUBLIC_URL}/assets/navigation-menu.svg`,
  'navigation-menu2.svg': `${process.env.PUBLIC_URL}/assets/navigation-menu2.svg`,
  'noImage.svg': `${process.env.PUBLIC_URL}/assets/noImage.svg`,
  'notificationSettings.svg': `${process.env.PUBLIC_URL}/assets/notificationSettings.svg`,
  'ooops.svg': `${process.env.PUBLIC_URL}/assets/ooops.svg`,
  'passwordReset.svg': `${process.env.PUBLIC_URL}/assets/passwordReset.svg`,
  'pets-paw.1.svg': `${process.env.PUBLIC_URL}/assets/pets-paw.1.svg`,
  'placeholder.jpg': `${process.env.PUBLIC_URL}/assets/placeholder.jpg`,
  'review.svg': `${process.env.PUBLIC_URL}/assets/review.svg`,
  'salonjpg.jpg': `${process.env.PUBLIC_URL}/assets/salonjpg.jpg`,
  'settings-illustration.svg': `${process.env.PUBLIC_URL}/assets/settings-illustration.svg`,
  'smile5-eng.svg': `${process.env.PUBLIC_URL}/assets/smile5-eng.svg`,
  'smile5.svg': `${process.env.PUBLIC_URL}/assets/smile5.svg`,
  'smile7-eng.png': `${process.env.PUBLIC_URL}/assets/smile7-eng.png`,
  'smile7.png': `${process.env.PUBLIC_URL}/assets/smile7.png`,
  'spa-lotus-1.1.svg': `${process.env.PUBLIC_URL}/assets/spa-lotus-1.1.svg`,
  'spa-stone.1.svg': `${process.env.PUBLIC_URL}/assets/spa-stone.1.svg`,
  'successFree.svg': `${process.env.PUBLIC_URL}/assets/successFree.svg`,
  'trialExpired.svg': `${process.env.PUBLIC_URL}/assets/trialExpired.svg`,
  'upgradePlan.svg': `${process.env.PUBLIC_URL}/assets/upgradePlan.svg`,
  'z-darker.svg': `${process.env.PUBLIC_URL}/assets/z-darker.svg`,
  'zoyya-black.svg': `${process.env.PUBLIC_URL}/assets/zoyya-black.svg`,
  'zoyya-celebrate.svg': `${process.env.PUBLIC_URL}/assets/zoyya-celebrate.svg`,
  'zoyya-dark.svg': `${process.env.PUBLIC_URL}/assets/zoyya-dark.svg`,
  'zoyya-eye-white.svg': `${process.env.PUBLIC_URL}/assets/zoyya-eye-white.svg`,
  'zoyya-final-logo-white.svg': `${process.env.PUBLIC_URL}/assets/zoyya-final-logo-white.svg`,
  'zoyya.png': `${process.env.PUBLIC_URL}/assets/zoyya.png`,
  'zoyyaLetter.svg': `${process.env.PUBLIC_URL}/assets/zoyyaLetter.svg`,
}
