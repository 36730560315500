/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import * as Styles from 'components/Avatar/style'

type TAvatarWrapper = {
  size?: keyof typeof Styles.wrapper
}

export const AvatarWrapper = styled.div<TAvatarWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => Styles.wrapper[props.size]}
`
export const DefaultAvatar = styled.div<TAvatarWrapper>`
  ${props => Styles.wrapper[props.size]}
  border-radius: 100%;
`

type TImage = {
  type: keyof typeof Styles.image
  size: keyof typeof Styles.image.default
  src?: string
  alt?: string
  fallbackUrl?: string
  style?: any
}

const Image = styled.div<TImage>`
  ${props => Styles.image[props.type][props.size]}
`

const Avatar = (props: any) => {
  const { src, alt, type, size, style, fallbackUrl } = props
  return (
    <AvatarWrapper size={size} style={style}>
      <Image
        size={size}
        type={type}
        src={src}
        alt={alt}
        fallbackUrl={fallbackUrl}
      />
    </AvatarWrapper>
  )
}

Avatar.defaultProps = {
  type: 'default',
  size: 'small',
  fallbackUrl: '',
  isOnline: false,
}

export default Avatar
