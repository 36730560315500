import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const CardCanceled = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11 12.5H2.5h0a2 2 0 01-2-2s0 0 0 0v-8h0a2 2 0 012-2h13a2 2 0 012 2V9M.5 3.5h17M8.5 5.5h-5M15.5 5.5h-2M6.5 7.5h-3M22.04 14.964a5 5 0 11-7.072 7.072 5 5 0 017.072-7.072M20 17l-3 3M17 17l3 3"></path>
      </g>
    </StyledIcon>
  )
}
