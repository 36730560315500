import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Eye = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.475 9.525a3.5 3.5 0 11-4.95 4.95 3.5 3.5 0 014.95-4.95"></path>
        <path d="M23.376 11.672C22.213 10.352 17.562 5.5 12 5.5 6.438 5.5 1.787 10.352.624 11.672h0a.5.5 0 000 .656C1.787 13.648 6.438 18.5 12 18.5c5.562 0 10.213-4.852 11.376-6.172h0a.5.5 0 000-.656z"></path>
      </g>
    </StyledIcon>
  )
}
export default Eye
