import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Day = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M4 4.5c-.6 0-1-.3-1-.7V2.3c0-.4.4-.7 1-.7h17c.6 0 1 .3 1 .7v1.5c0 .4-.4.7-1 .7H4zM21 22.9c.6 0 1-.7 1-1.5v-13c0-.8-.5-1.4-1-1.4L4 7.1c-.6 0-1 .7-1 1.5v13c0 .8.5 1.4 1 1.4l17-.1z"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Day
