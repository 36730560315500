import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};

  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Tour = props => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path d="M21 7.5l3-3-3-3M4 17.5l-3 3 3 3" fill="none"></path>
      <path
        d="M24 4.5H11c-2.2 0-4 1.8-4 4s1.8 4 4 4h3c2.2 0 4 1.8 4 4s-1.8 4-4 4H1"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Tour
