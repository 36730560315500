import React, { useContext } from 'react'
import styled from 'styled-components'
import * as Styles from './style'
import Tooltip from '../Tooltip/Tooltip'
import { Icons } from 'components'
import { SmallHeightContext } from 'Context'
import { MobileContext } from '../../App'
import { useFormContext } from 'components/Form/Form'
import { device } from '../../constants'
export const ButtonStyled = styled.button`
  ${Styles.ButtonBase};
  ${props => Styles[props.size]};
  ${props => Styles[props.disabled ? 'disabled' : props.buttonType]};
  ${props => Styles[props.fullWidth ? 'fullWidth' : '']}
  ${props => Styles[props.isSelect ? 'isSelect' : '']}
  ${props => Styles[props.hasError ? 'hasError' : '']}    
  @media ${device.tablet} {
    -webkit-tap-highlight-color: transparent;
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
    &:active {
      background: none;
    }
  }
`

const VisuallyHiddenLabel = styled.span`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`

/** All buttons, including text, link and split-buttons, follow the same core principles in dimensions, padding, and font sizes.
 * Combined with simple modifiers, they can be changed in size and appearance.  */
const Button = props => {
  const {
    disabled,
    onClick,
    buttonType,
    size,
    label,
    icon,
    hasIconOnly,
    isSelect,
    fullWidth,
    tooltip,
    forwardedRef,
    className,
    type,
    hasError,
    isTableFilter,
    tabIndex,
    footerButton,
    iconRight,
    style,
    iconComponent,
    name,
    dataIntercomTarget,
    isLoading,
    isAnimated,
  } = props

  const isSmallSize = useContext(SmallHeightContext)
  const isMobile = useContext(MobileContext)
  const formContext = useFormContext()

  const isDisabled =
    disabled || (type === 'submit' && formContext?.isSubmitting)

  return tooltip ? (
    <Tooltip label={tooltip} key={'tooltip'}>
      <ButtonStyled
        hasError={!!hasError}
        onClick={!disabled && onClick ? onClick : undefined}
        disabled={disabled}
        size={size}
        buttonType={buttonType}
        icon={icon}
        hasIconOnly={hasIconOnly}
        fullWidth={fullWidth}
        isSelect={isSelect}
        data-tip={tooltip}
        forwardedRef={forwardedRef}
        className={className}
        type={type}
        tabIndex={tabIndex}
        isSmallSize={isSmallSize && !isMobile}
        footerButton={footerButton}
        style={style}
        data-cy={`tooltip_button_${name}`}
      >
        {
          <>
            {isAnimated && <Styles.ShineAnimation />}
            {iconComponent && !iconRight && (
              <div
                style={{
                  marginRight: hasIconOnly ? 0 : 10,
                  display: 'flex',
                }}
              >
                {iconComponent}
              </div>
            )}

            {hasIconOnly && <VisuallyHiddenLabel>{label}</VisuallyHiddenLabel>}
            {!hasIconOnly && (
              <Styles.ButtonLabel
                isTableFilter={isTableFilter}
                hasIcon={!!icon || !!iconComponent}
                iconRight={iconRight}
              >
                {label}
              </Styles.ButtonLabel>
            )}
            {isLoading && (
              <img
                src={process.env.PUBLIC_URL + '/assets/buttonLoading.svg'}
                style={{
                  width: 30,
                  opacity: '0.7',
                  position: 'absolute',
                  right: 15,
                }}
                alt={'loading'}
              />
            )}
            {iconComponent && iconRight && iconComponent}
            {isSelect &&
              (buttonType === 'primary' || buttonType === 'secondary') && (
                <Styles.ButtonArrow>
                  <Icons.ChevronDown
                    color={buttonType === 'primary' ? 'white' : 'grayDark'}
                    size={size}
                    isChevron
                  />
                </Styles.ButtonArrow>
              )}
          </>
        }
      </ButtonStyled>
    </Tooltip>
  ) : (
    <ButtonStyled
      tabIndex={tabIndex}
      key={'button'}
      hasError={!!hasError}
      onClick={!disabled && onClick ? onClick : undefined}
      disabled={isDisabled}
      size={size}
      buttonType={buttonType}
      icon={icon}
      hasIconOnly={hasIconOnly}
      fullWidth={fullWidth}
      isSelect={isSelect}
      data-tip={tooltip}
      forwardedRef={forwardedRef}
      className={className}
      type={type}
      style={style}
      data-intercom-target={dataIntercomTarget || 'Button'}
      isSmallSize={isSmallSize && !isMobile}
      footerButton={footerButton}
      data-cy={`button_${name}`}
    >
      {
        <>
          {isAnimated && <Styles.ShineAnimation />}
          {iconComponent && !iconRight && (
            <div
              style={{
                marginRight: hasIconOnly ? 0 : 10,
                display: 'flex',
              }}
            >
              {iconComponent}
            </div>
          )}

          {hasIconOnly && <VisuallyHiddenLabel>{label}</VisuallyHiddenLabel>}
          {!hasIconOnly && (
            <Styles.ButtonLabel
              isTableFilter={isTableFilter}
              hasIcon={!!icon || !!iconComponent}
              iconRight={iconRight}
            >
              {label}
            </Styles.ButtonLabel>
          )}
          {isLoading && (
            <img
              src={process.env.PUBLIC_URL + '/assets/buttonLoading.svg'}
              style={{
                width: 30,
                opacity: '0.7',
                position: 'absolute',
                right: 15,
              }}
              alt={'loading'}
            />
          )}
          {iconComponent && iconRight && iconComponent}
          {isSelect &&
            (buttonType === 'primary' || buttonType === 'secondary') && (
              <Styles.ButtonArrow>
                <Icons.ChevronDown
                  color={buttonType === 'primary' ? 'white' : 'grayDark'}
                  size={size}
                  isChevron
                />
              </Styles.ButtonArrow>
            )}
        </>
      }
    </ButtonStyled>
  )
}

// Button.propTypes = {
//   /** Is the button disabled */
//   disabled: PropTypes.bool,

//   /** Size of the Button */
//   size: PropTypes.oneOf([
//     "small",
//     "extraSmall",
//     "large",
//     "medium",
//     "extraLarge",
//   ]),

//   /** OnClick handler */
//   onClick: PropTypes.func,

//   /** Button label */
//   label: PropTypes.string,

//   /** Type of button */
//   buttonType: PropTypes.oneOf([
//     "link",
//     "primary",
//     "secondary",
//     "text",
//     "secondaryAccent",
//     "secondaryLink",
//   ]),

//   /** Is this the Select button with chevron */
//   isSelect: PropTypes.bool,

//   /** Is the Button Loading  */
//   loading: PropTypes.bool,

//   /** Does the button have only an icon and no label */
//   hasIconOnly: PropTypes.bool,

//   /** Icon to show with the label */
//   icon: PropTypes.node,

//   /** Position of the Select popup */
//   selectPosition: PropTypes.oneOf(["top", "bottom"]),

//   /** Function to call on Split Button selected item click */
//   onSelectClick: PropTypes.func,

//   /** Is the button the full width of the parent container */
//   fullWidth: PropTypes.bool,

//   /** Tooltip to show on the component */
//   tooltip: PropTypes.string,

//   /** Is search hidden */
//   hideSearch: PropTypes.bool,

//   /** class passed by the dom element */
//   className: PropTypes.string,
// };

Button.defaultProps = {
  disabled: false,
  loading: false,
  size: 'medium',
  buttonType: 'secondary',
  label: undefined,
  hasIconOnly: false,
  icon: undefined,
  isSelect: undefined,
  items: undefined,
  selectPosition: 'bottom',
  onSelectClick: undefined,
  fullWidth: false,
  tooltip: undefined,
  forwardedRef: undefined,
  hideSearch: true,
  className: undefined,
}

export default Button
