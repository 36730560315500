import React from 'react'
import Text from '../Text/Text'
import { CheckboxInput } from '../Form/Fields/Checkbox'
import styled from 'styled-components'
import { device } from '../../constants'

export const StyledCheck = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-top: 10px;

  @media ${device.tablet} {
    margin-left: 15px;
  }
`

export const Checkbox = props => {
  const { html, label, handleChange, checked, disabled, style } = props
  return (
    <StyledCheck
      style={{
        ...style,
      }}
    >
      <CheckboxInput
        onChange={handleChange}
        style={{ marginRight: 15, height: 38, flexShrink: 0 }}
        checked={checked}
        disabled={disabled}
        type={'checkbox'}
      />
      <div dangerouslySetInnerHTML={html ? { __html: html } : undefined}>
        {label ? (
          <Text
            style={{
              display: 'flex',
              whiteSpace: 'break-spaces',
            }}
            type="label"
          >
            {label}
          </Text>
        ) : null}
      </div>
    </StyledCheck>
  )
}
