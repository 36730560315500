import styled from 'themes'
import { Form } from 'formik'
import { device } from '../../constants'

export const UsersSelect = styled.div`
  margin-right: 14px;

  @media ${device.tablet} {
    margin-bottom: 10px;
    overflow: hidden;
  }
`

export const StickyHeader = styled.div`
  display: flex;
  background: ${props => props.theme.colors.light};
  @media ${device.tablet} {
    height: 45px;
    ${UsersSelect} {
      overflow: hidden;
      &:not(:first-child) {
        margin-right: 5px;
        margin-left: auto;
      }
    }
  }
`

type SliderListBodyProps = {
  isWide?: boolean
  isFullHeight?: boolean
  isSettings?: boolean
}
export const SliderListBody = styled.div<SliderListBodyProps>`
  padding: ${props => (props.isWide ? '0' : '1.429rem;')};
  overflow: auto;
  height: ${props =>
    props.isFullHeight
      ? '100%'
      : props.isSettings
      ? 'calc(100vh - 285px)'
      : 'calc(100vh - 230px)'};
  width: 100%;
`
export const ListBody = styled.div`
  padding: 0px 1.429rem;

  @media ${device.tablet} {
    padding: 10px;
  }
`

export const SettingsAccountAvatarRow = styled.div`
  display: flex;
  margin: 0px 0 1.714rem;
`

export const SettingsAccountAvatar = styled.img`
  width: 128px;
  height: 128px;
  min-width: 128px;
  min-height: 128px;
  border-radius: 0.286rem;
  margin-right: 1.714rem;
  object-fit: cover;
`

export const SettingsAccountSubtitle = styled.span`
  font-size: 1.143rem;
  line-height: 1.571rem;
  color: ${props => props.theme.colors.secondaryDark};
  margin-bottom: 0.857rem;
  margin-top: 1.714rem;
`

export const SettingsAccountList = styled.div`
  display: flex;
  flex-direction: column;
`

export const SettingsAccountListItem = styled.span`
  padding: 0.714rem 0px;
  border-top: 1px solid ${props => props.theme.colors.outline};
  font-size: 1rem;
  color: ${props => props.theme.colors.text};
  padding-left: 1rem;
`

export const SettingsAccountSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const SettingsAccountAvatarButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 324px;
`

export const SettingsAccountAvatarButton = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0px 0px 0.714rem 0.714rem;
`

export const SettingsAccountAvatarButtonSave = styled(
  SettingsAccountAvatarButton
)`
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
  background: ${props => props.theme.colors.background};
  width: 100%;
  left: 0;
  height: 74px;
  justify-content: flex-start;
  padding-left: 40px;
  border-top: 1px solid ${props => props.theme.colors.outline};
`

type TSettingsAccountFormProps = {
  isMobile?: boolean
  isFullHeight?: boolean
}
export const SettingsAccountForm = styled(Form).withConfig<
  TSettingsAccountFormProps
>({
  shouldForwardProp: prop => !['isMobile', 'isFullHeight'].includes(prop),
})`
  display: flex;
  flex-direction: column;

  padding: ${props => (props.isMobile ? '15px' : '3px')};
  height: ${props => (props.isFullHeight ? '100%' : 'auto')};
`

export const SettingsAccountHeader = styled.div`
  margin-bottom: 14px;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 2;
  background: ${props => props.theme.colors.light};
`
type TFieldColumnProps = {
  isNarrow?: boolean
}
export const FieldColumn = styled.div<TFieldColumnProps>`
  display: flex;
  flex: 1;
  flex-direction: ${props => (props.isNarrow ? 'column' : 'initial')};
  max-width: ${props => (props.isNarrow ? '160px' : 'auto')};
  margin-bottom: ${props => (props.isNarrow ? '10px' : '0')};

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 14px;
  }
  :not(:last-child) {
    padding-right: 14px;
  }

  form {
    width: 100%;
  }
`

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    padding: 3px;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 215px;
  margin-bottom: 14px;
  @media ${device.tablet} {
    flex-direction: column;
    width: 49%;
    margin-bottom: 0px;
  }
`

export const SettingsAccountEmpty = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`

export const ModalBodyContainer = styled.div`
  max-height: calc((var(--vh, 1vh) * 100 - 30px));
  width: 100%;
  @media ${device.tablet} {
    max-height: calc((var(--vh, 1vh) * 100));
    display: initial;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const ContactConfirmedInfo = styled.div`
  display: flex;
  align-items: center;
`
export const ContactConfirmedSpan = styled.span`
  margin-left: 5px;
  @media ${device.tablet} {
    font-size: 12px;
  }
`
export const ContactLinkButton = styled.div`
  color: ${props => props.theme.colors.primary};
  margin-left: 27px;
  cursor: pointer;
  @media ${device.tablet} {
    font-size: 12px;
    padding: 0 5px;
    margin-left: 19px;
  }
`
export const ContactContainer = styled.div`
  display: flex;
  padding: 3px;
`
export const ErrorIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
`
export const ErrorText = styled.span`
  color: #fe9060;
  max-width: 400px;
  margin-left: 12px;
  font-size: 12px;
`
