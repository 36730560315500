import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};

  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const Gear = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 25 25"
        version="1.1"
        viewBox="0 0 25 25"
        xmlSpace="preserve"
      >
        <path
          d="M20.8 14H23c.6 0 1-.4 1-1v-1c0-.6-.4-1-1-1h-2.2c-.2-1-.5-2-1-2.9l1.6-1.6c.4-.4.4-1 0-1.4L20 3.7c-.4-.4-1-.4-1.4 0L17 5.3c-1-.5-2-.8-3-1V2c0-.6-.4-1-1-1h-1c-.6 0-1 .4-1 1v2.2c-1 .2-2 .5-2.9 1L6.5 3.7c-.4-.4-1-.4-1.4 0L3.7 5.1c-.4.4-.4 1 0 1.4l1.6 1.6c-.5.9-.8 1.9-1 2.9H2c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1h2.2c.2 1 .5 2 1 2.9l-1.6 1.6c-.4.4-.4 1 0 1.4L5 21.3c.4.4 1 .4 1.4 0L8 19.7c.9.5 1.9.8 2.9 1V23c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-2.2c1-.2 2-.5 2.9-1l1.6 1.6c.4.4 1 .4 1.4 0l1.4-1.4c.4-.4.4-1 0-1.4L19.6 17c.6-1 .9-2 1.2-3z"
          fill="none"
        ></path>
        <path
          d="M15.7 9.3c1.8 1.8 1.8 4.6 0 6.4s-4.6 1.8-6.4 0-1.8-4.6 0-6.4 4.6-1.7 6.4 0"
          fill="none"
        ></path>
      </svg>
    </StyledIcon>
  )
}
Gear.displayName = 'Gear'

export default Gear
