import React from 'react'

export const CroatianFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={'22px'}
    >
      <path
        fill="#41479B"
        d="M0 385.38c0 21.176 17.167 38.345 38.345 38.345h435.311c21.165 0 38.324-17.148 38.344-38.307v-73.509H0v73.471z"
      ></path>
      <path
        fill="#FF4B55"
        d="M473.656 88.275H38.345C17.167 88.275 0 105.443 0 126.62v73.471h512v-73.509c-.02-21.158-17.179-38.307-38.344-38.307z"
      ></path>
      <path fill="#F5F5F5" d="M0 200.089H512V311.899H0z"></path>
      <g fill="#FF4B55">
        <path d="M194.21 203.029H218.92700000000002V231.279H194.21z"></path>
        <path d="M243.64 203.029H268.35699999999997V231.279H243.64z"></path>
        <path d="M218.93 231.279H243.647V259.529H218.93z"></path>
        <path d="M293.08 203.029H317.79699999999997V231.279H293.08z"></path>
        <path d="M268.36 231.279H293.077V259.529H268.36z"></path>
      </g>
      <g fill="#F5F5F5">
        <path d="M218.93 203.029H243.647V231.279H218.93z"></path>
        <path d="M268.36 203.029H293.077V231.279H268.36z"></path>
        <path d="M194.21 231.279H218.92700000000002V259.529H194.21z"></path>
        <path d="M243.64 231.279H268.35699999999997V259.529H243.64z"></path>
        <path d="M293.08 231.279H317.79699999999997V259.529H293.08z"></path>
      </g>
      <g fill="#FF4B55">
        <path d="M243.64 259.529H268.35699999999997V287.779H243.64z"></path>
        <path d="M218.93 287.779H243.647V316.029H218.93z"></path>
        <path d="M268.36 287.779H293.077V316.029H268.36z"></path>
      </g>
      <g fill="#F5F5F5">
        <path d="M218.93 259.529H243.647V287.779H218.93z"></path>
        <path d="M268.36 259.529H293.077V287.779H268.36z"></path>
        <path d="M243.64 287.779H268.35699999999997V316.029H243.64z"></path>
      </g>
      <g fill="#FF4B55">
        <path d="M317.793 203.035v79.448c0 34.128-27.666 61.793-61.793 61.793s-61.793-27.666-61.793-61.793v-79.448h123.586m4.413-4.415H189.792v83.862c0 36.507 29.7 66.207 66.207 66.207s66.207-29.701 66.207-66.207V198.62z"></path>
        <path d="M218.924 287.779V259.53h-24.717v22.953c0 1.789.118 3.546.267 5.297l24.45-.001z"></path>
      </g>
      <g fill="#F5F5F5">
        <path d="M317.526 287.779h-24.45v28.248h14.774a61.375 61.375 0 009.676-28.248zM204.149 316.028h14.774V287.78h-24.45a61.383 61.383 0 009.676 28.248z"></path>
      </g>
      <g fill="#FF4B55">
        <path d="M293.075 287.779h24.45c.149-1.749.267-3.508.267-5.297V259.53h-24.717v28.249zM218.924 331.833v-15.805H204.15c3.957 6.105 8.972 11.439 14.774 15.805zM307.849 316.028h-14.774v15.806c5.801-4.367 10.815-9.701 14.774-15.806z"></path>
      </g>
      <g fill="#F5F5F5">
        <path d="M218.924 331.833c7.195 5.415 15.567 9.345 24.717 11.202v-27.007h-24.717v15.805zM268.358 343.034c9.15-1.857 17.523-5.788 24.717-11.202v-15.805h-24.717v27.007z"></path>
      </g>
      <path
        fill="#FF4B55"
        d="M268.358 316.028h-24.717v27.008A62.043 62.043 0 00256 344.277c4.233 0 8.365-.43 12.359-1.241v-27.008h-.001z"
      ></path>
      <g fill="#4173CD">
        <path d="M211.27 156.29a180.927 180.927 0 00-16.489-1.779 189.969 189.969 0 00-12.398 11.15l11.823 28.545a161.574 161.574 0 0124.247-7.867l-7.183-30.049zM271.002 151.604a184.805 184.805 0 00-15.003-6.765 185.653 185.653 0 00-15.003 6.765l2.41 30.803a161.163 161.163 0 0125.186 0l2.41-30.803zM329.616 165.661a190.81 190.81 0 00-12.398-11.15c-5.609.346-11.111.947-16.489 1.779l-7.184 30.049a161.46 161.46 0 0124.247 7.867l11.824-28.545z"></path>
      </g>
      <g fill="#41479B">
        <path d="M240.996 151.604a183.1 183.1 0 00-15.894-4.346 187.354 187.354 0 00-13.832 9.033l7.183 30.049a161.442 161.442 0 0124.952-3.933l-2.409-30.803zM300.729 156.29a187.453 187.453 0 00-13.832-9.033 183.718 183.718 0 00-15.894 4.346l-2.41 30.803a161.322 161.322 0 0124.952 3.933l7.184-30.049z"></path>
      </g>
      <g fill="#FF4B55">
        <path d="M241.96 163.925a179.702 179.702 0 00-27.817 4.385l-1.437-6.011a186.473 186.473 0 0128.771-4.535l.483 6.161zM242.925 176.245a167.663 167.663 0 00-25.907 4.084l-1.437-6.009a173.602 173.602 0 0126.862-4.234c.159 2.051.321 4.107.482 6.159z"></path>
      </g>
      <path
        fill="#FFE15A"
        d="M285.326 178.32a167.55 167.55 0 00-6.482-1.022c-3.562-.492-6.215-3.516-5.906-6.915.308-3.398 3.713-5.863 7.59-5.327 2.325.32 4.646.685 6.959 1.097 3.859.682 6.357 4.082 5.604 7.406-.75 3.332-4.218 5.386-7.765 4.761z"
      ></path>
      <path
        fill="#FF4B55"
        d="M320.157 188.497a167.924 167.924 0 00-25.175-8.168l1.437-6.009a173.739 173.739 0 0126.103 8.468l-2.365 5.709z"
      ></path>
      <g fill="#FFE15A">
        <path d="M317.952 169.958c-.873 2.407-3.817 3.552-6.599 2.647-2.781-.906-4.483-3.55-3.779-6.012.704-2.462 3.686-3.765 6.636-2.806 2.953.957 4.616 3.764 3.742 6.171zM204.424 166.594c.703 2.464-1 5.109-3.779 6.012-2.781.9-5.725-.245-6.599-2.647-.874-2.408.79-5.214 3.742-6.171 2.95-.956 5.934.346 6.636 2.806zM254.589 162.548c.006 2.047-1.834 3.714-4.114 3.784-2.279.073-4.222-1.481-4.342-3.52-.119-2.04 1.719-3.806 4.111-3.883 2.393-.075 4.336 1.572 4.345 3.619zM265.865 162.811c-.121 2.044-2.065 3.593-4.342 3.52-2.279-.07-4.119-1.737-4.114-3.784.009-2.045 1.953-3.697 4.344-3.619 2.393.073 4.229 1.842 4.112 3.883zM260.007 172.079c-.057 2.047-1.85 3.657-4.008 3.657-2.156-.001-3.949-1.609-4.008-3.657-.056-2.041 1.737-3.755 4.008-3.75 2.271-.005 4.064 1.707 4.008 3.75z"></path>
      </g>
    </svg>
  )
}
export default CroatianFlag
