import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

export const PasswordLock = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7 10.5V13M2 17.5h-.5 0a1 1 0 01-1-1s0 0 0 0v-8h0a1 1 0 011-1h11a1 1 0 011 1V13M11.5 7.5V5v0a4.5 4.5 0 00-9 0v2.5"></path>
        <path d="M5.5 23.5a1 1 0 01-1-1v-6a1 1 0 011-1h17a1 1 0 011 1v6a1 1 0 01-1 1zM7 18l3 3M10 18l-3 3M12.5 18l3 3M15.5 18l-3 3M17.5 21.5h4M7 10.25h0a.25.25 0 11-.25.25h0a.25.25 0 01.25-.25s0 0 0 0"></path>
      </g>
    </StyledIcon>
  )
}
export default PasswordLock
