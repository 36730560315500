import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

const House = props => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.5 13.5v9h0a1 1 0 001 1H9a.5.5 0 00.5-.5v-4h0a2.5 2.5 0 015 0s0 0 0 0v4h0a.5.5 0 00.5.5h4.5a1 1 0 001-1V14M.5 13L12 1.5 23.5 13"></path>
      </g>
    </StyledIcon>
  )
}

export default House
