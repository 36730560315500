import moment from 'moment'
import { debounce, sumBy } from 'lodash'
import i18n from 'localization'
import {
  employeeScheduleRowHeight,
  eventRowHeight,
  OrganizationSwitchIcon,
  workScheduleRowHeight,
} from 'styles'
import React, { useEffect, useMemo, useState } from 'react'
import Avatar, { AvatarWrapper, DefaultAvatar } from 'components/Avatar/Avatar'
import { Icons } from 'components'
import { ITheme } from 'themes'
import parsePhoneNumber from 'libphonenumber-js'
import { useTranslation } from 'react-i18next'

export function getLogoImg() {
  return (
    <img
      style={{ marginLeft: 15, width: 110 }}
      alt="zoyya-logo"
      onClick={() => window.open('https://zoyya.com', '_self')}
      src={process.env.PUBLIC_URL + '/assets/zoyya-final-logo-white.svg'}
    />
  )
}

export const languages = [
  {
    id: 'hr',
    title: i18n.t('translation.MyAccountContact.lanugage-hr'),
  },
  {
    id: 'en',
    title: i18n.t('translation.MyAccountContact.lanugage-en'),
  },
  {
    id: 'es',
    title: i18n.t('translation.MyAccountContact.lanugage-es'),
  },
  {
    id: 'de',
    title: i18n.t('translation.MyAccountContact.lanugage-de'),
  },
]

export const categories = [
  {
    id: 0,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde2721db19110159bb52de_zoyya7-min.png',
    name: i18n.t('translation.Home.categoryAll'),
    type: 'all',
    typeName: 'all',
  },
  {
    id: 1,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde2721db19110159bb52de_zoyya7-min.png',
    name: i18n.t('translation.Home.categoryBeauty'),
    type: 'SALON LJEPOTE',
    typeName: i18n.t('translation.Home.beauty'),
  },
  {
    id: 2,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde2721db19113569bb52dd_zoyya8-min.png',
    name: i18n.t('translation.Home.categoryHair'),
    type: 'FRIZERSKI SALON',
    typeName: i18n.t('translation.Home.hair'),
  },
  {
    id: 3,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde2721d4359b5ad1465558_zoyya9-min.png',
    name: i18n.t('translation.Home.categoryDentist'),
    type: 'STOMATOLOG',
    typeName: i18n.t('translation.Home.dentist'),
  },
  {
    id: 4,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde27219e6927547f1422bd_zoyya13-min.png',
    name: i18n.t('translation.Home.categoryMassage'),
    type: 'FIZIOTERAPIJA',
    typeName: i18n.t('translation.Home.physio'),
  },
  {
    id: 5,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde2721c9f4a42a1dfaa319_zoyya11-min.png',
    name: i18n.t('translation.Home.categoryFitness'),
    type: 'FITNESS',
    typeName: i18n.t('translation.Home.fitness'),
  },
  {
    id: 6,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde272114b4b96e80a2d08c_zoyya12-min.png',
    name: i18n.t('translation.Home.categoryPet'),
    type: 'KUĆNI LJUBIMCI',
    typeName: i18n.t('translation.Home.pets'),
  },
  {
    id: 7,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde272186683c73228857db_zoyya10-min.png',
    name: i18n.t('translation.Home.categoryPhysio'),
    type: 'FIZIOTERAPIJA',
    typeName: i18n.t('translation.Home.physio'),
  },
  {
    id: 8,
    url:
      'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fde272143589f48538381d7_zoyya14-min.png',
    name: i18n.t('translation.Home.categoryRest'),
    type: 'OSTALO',
    typeName: i18n.t('translation.Home.rest'),
  },
]

export const analyticsTrack = (event: string, metadata?: any) => {
  let analytics = (window.analytics = window.analytics || [])
  if (analytics && typeof analytics.track === 'function') {
    analytics.track(
      event,
      Object.assign(metadata || {}, {
        time: moment().format('DD.MM.YYYY HH:mm'),
      })
    )
  }
}

export function formatPhoneNumber(phoneNumberString) {
  const phoneNumber = parsePhoneNumber(phoneNumberString)
  return phoneNumber.formatInternational()
}

export const formatTime = (appointment, isBooking) => {
  let startTime = ''
  if (isBooking) {
    startTime = moment(appointment?.startTimeBooking).format('HH:mm')
  } else {
    startTime = appointment?.startTime
  }
  const minutes = startTime?.split(':')[1]
  const hour = startTime?.split(':')[0]

  const minutesWithHours =
    parseInt(hour) * 60 + parseInt(minutes) + parseInt(appointment?.duration)

  const newMinutes = Math.floor(minutesWithHours % 60)
  const newHours = Math.floor(minutesWithHours / 60)

  return (
    (newHours < 10 ? '0' + newHours : newHours) +
    ':' +
    (newMinutes < 10 ? '0' + newMinutes : newMinutes)
  )
}

export const hours = [
  { id: '00:00', display: '00:00' },
  { id: '', display: '00:15' },
  { id: '00:30', display: '00:30' },
  { id: '', display: '00:45' },
  { id: '01:00', display: '01:00' },
  { id: '', display: '01:15' },
  { id: '01:30', display: '01:30' },
  { id: '', display: '01:45' },
  { id: '02:00', display: '02:00' },
  { id: '', display: '02:15' },
  { id: '02:30', display: '02:30' },
  { id: '', display: '02:45' },
  { id: '03:00', display: '03:00' },
  { id: '', display: '03:15' },
  { id: '03:30', display: '03:30' },
  { id: '', display: '03:45' },
  { id: '04:00', display: '04:00' },
  { id: '', display: '04:15' },
  { id: '04:30', display: '04:30' },
  { id: '', display: '04:45' },
  { id: '05:00', display: '05:00' },
  { id: '', display: '05:15' },
  { id: '05:30', display: '05:30' },
  { id: '', display: '05:45' },
  { id: '06:00', display: '06:00' },
  { id: '', display: '06:15' },
  { id: '06:30', display: '06:30' },
  { id: '', display: '06:45' },
  { id: '07:00', display: '07:00' },
  { id: '', display: '07:15' },
  { id: '07:30', display: '07:30' },
  { id: '', display: '07:45' },
  { id: '08:00', display: '08:00' },
  { id: '', display: '08:15' },
  { id: '08:30', display: '08:30' },
  { id: '', display: '08:45' },
  { id: '09:00', display: '09:00' },
  { id: '', display: '09:15' },
  { id: '09:30', display: '09:30' },
  { id: '', display: '09:45' },
  { id: '10:00', display: '10:00' },
  { id: '', display: '10:15' },
  { id: '10:30', display: '10:30' },
  { id: '', display: '10:45' },
  { id: '11:00', display: '11:00' },
  { id: '', display: '11:15' },
  { id: '11:30', display: '11:30' },
  { id: '', display: '11:45' },
  { id: '12:00', display: '12:00' },
  { id: '', display: '12:15' },
  { id: '12:30', display: '12:30' },
  { id: '', display: '12:45' },
  { id: '13:00', display: '13:00' },
  { id: '', display: '13:15' },
  { id: '13:30', display: '13:30' },
  { id: '', display: '13:45' },
  { id: '14:00', display: '14:00' },
  { id: '', display: '14:15' },
  { id: '14:30', display: '14:30' },
  { id: '', display: '14:45' },
  { id: '15:00', display: '15:00' },
  { id: '', display: '15:15' },
  { id: '15:30', display: '15:30' },
  { id: '', display: '15:45' },
  { id: '16:00', display: '16:00' },
  { id: '', display: '16:15' },
  { id: '16:30', display: '16:30' },
  { id: '', display: '16:45' },
  { id: '17:00', display: '17:00' },
  { id: '', display: '17:15' },
  { id: '17:30', display: '17:30' },
  { id: '', display: '17:45' },
  { id: '18:00', display: '18:00' },
  { id: '', display: '18:15' },
  { id: '18:30', display: '18:30' },
  { id: '', display: '18:45' },
  { id: '19:00', display: '19:00' },
  { id: '', display: '19:15' },
  { id: '19:30', display: '19:30' },
  { id: '', display: '19:45' },
  { id: '20:00', display: '20:00' },
  { id: '', display: '20:15' },
  { id: '20:30', display: '20:30' },
  { id: '', display: '20:45' },
  { id: '21:00', display: '21:00' },
  { id: '', display: '21:15' },
  { id: '21:30', display: '21:30' },
  { id: '', display: '21:45' },
  { id: '22:00', display: '22:00' },
  { id: '', display: '22:15' },
  { id: '22:30', display: '22:30' },
  { id: '', display: '22:45' },
  { id: '23:00', display: '23:00' },
  { id: '', display: '23:15' },
  { id: '23:30', display: '23:30' },
  { id: '', display: '23:45' },
]

export const workHours = [
  { id: '00:00', display: '00:00' },
  { id: '', display: '00:15' },
  { id: '', display: '00:30' },
  { id: '', display: '00:45' },
  { id: '', display: '01:00' },
  { id: '', display: '01:15' },
  { id: '', display: '01:30' },
  { id: '', display: '01:45' },
  { id: '02:00', display: '02:00' },
  { id: '', display: '02:15' },
  { id: '', display: '02:30' },
  { id: '', display: '02:45' },
  { id: '', display: '03:00' },
  { id: '', display: '03:15' },
  { id: '', display: '03:30' },
  { id: '', display: '03:45' },
  { id: '04:00', display: '04:00' },
  { id: '', display: '04:15' },
  { id: '', display: '04:30' },
  { id: '', display: '04:45' },
  { id: '', display: '05:00' },
  { id: '', display: '05:15' },
  { id: '', display: '05:30' },
  { id: '', display: '05:45' },
  { id: '06:00', display: '06:00' },
  { id: '', display: '06:15' },
  { id: '', display: '06:30' },
  { id: '', display: '06:45' },
  { id: '', display: '07:00' },
  { id: '', display: '07:15' },
  { id: '', display: '07:30' },
  { id: '', display: '07:45' },
  { id: '08:00', display: '08:00' },
  { id: '', display: '08:15' },
  { id: '', display: '08:30' },
  { id: '', display: '08:45' },
  { id: '', display: '09:00' },
  { id: '', display: '09:15' },
  { id: '', display: '09:30' },
  { id: '', display: '09:45' },
  { id: '10:00', display: '10:00' },
  { id: '', display: '10:15' },
  { id: '', display: '10:30' },
  { id: '', display: '10:45' },
  { id: '', display: '11:00' },
  { id: '', display: '11:15' },
  { id: '', display: '11:30' },
  { id: '', display: '11:45' },
  { id: '12:00', display: '12:00' },
  { id: '', display: '12:15' },
  { id: '', display: '12:30' },
  { id: '', display: '12:45' },
  { id: '', display: '13:00' },
  { id: '', display: '13:15' },
  { id: '', display: '13:30' },
  { id: '', display: '13:45' },
  { id: '14:00', display: '14:00' },
  { id: '', display: '14:15' },
  { id: '', display: '14:30' },
  { id: '', display: '14:45' },
  { id: '', display: '15:00' },
  { id: '', display: '15:15' },
  { id: '', display: '15:30' },
  { id: '', display: '15:45' },
  { id: '16:00', display: '16:00' },
  { id: '', display: '16:15' },
  { id: '', display: '16:30' },
  { id: '', display: '16:45' },
  { id: '', display: '17:00' },
  { id: '', display: '17:15' },
  { id: '', display: '17:30' },
  { id: '', display: '17:45' },
  { id: '18:00', display: '18:00' },
  { id: '', display: '18:15' },
  { id: '', display: '18:30' },
  { id: '', display: '18:45' },
  { id: '', display: '19:00' },
  { id: '', display: '19:15' },
  { id: '', display: '19:30' },
  { id: '', display: '19:45' },
  { id: '20:00', display: '20:00' },
  { id: '', display: '20:15' },
  { id: '', display: '20:30' },
  { id: '', display: '20:45' },
  { id: '', display: '21:00' },
  { id: '', display: '21:15' },
  { id: '', display: '21:30' },
  { id: '', display: '21:45' },
  { id: '22:00', display: '22:00' },
  { id: '', display: '22:15' },
  { id: '', display: '22:30' },
  { id: '', display: '22:45' },
  { id: '', display: '23:00' },
  { id: '', display: '23:15' },
  { id: '', display: '23:30' },
  { id: '', display: '23:45' },
]

export const calculateDuration = event => {
  return moment(`2000-01-01 ${event.endTime}`).diff(
    moment(`2000-01-01 ${event.startTime}`),
    'minutes'
  )
}

export function getEventPosition(event, isWorkSchedule, isEmployeeSchedule) {
  const rowHeight = isWorkSchedule
    ? workScheduleRowHeight
    : isEmployeeSchedule
    ? employeeScheduleRowHeight
    : eventRowHeight
  const numberOfMinutes = event.duration
    ? parseInt(event.duration)
    : calculateDuration(event)
  const heightOf1Minute = rowHeight / 15
  const height = numberOfMinutes * heightOf1Minute

  const timeOfHour = event.startTime.split(':')[1]
  const hourSection =
    timeOfHour === '00'
      ? 0
      : timeOfHour === '15'
      ? 1
      : timeOfHour === '30'
      ? 2
      : 3
  const hour = parseInt(event.startTime.split(':')[0])

  const positionOfTheHour = rowHeight * 4 * hour //eventRowHeight represents
  // 15 min, so x2 to represent an hour. We multiply that by the number of hours

  const positionOfMinutes = hourSection * rowHeight
  const topPosition = positionOfTheHour + positionOfMinutes
  return {
    height,
    topPosition,
  }
}

export function getCurrentTimePosition(isWorkSchedule, isEmployeeSchedule) {
  const rowHeight = isWorkSchedule
    ? workScheduleRowHeight
    : isEmployeeSchedule
    ? employeeScheduleRowHeight
    : eventRowHeight
  const minutes = moment().minute()
  const minutesDivider = rowHeight / (minutes + 1)
  const hourSection =
    minutes < 15
      ? 0
      : minutes === 15 || minutes < 30
      ? 1
      : minutes === 30 || minutes < 45
      ? 2
      : 3

  const minutesSection = minutes * minutesDivider
  const hour = moment().hour()
  const positionOfTheHour = rowHeight * 4 * hour //eventRowHeight represents
  // 15 min, so x2 to represent an hour. We multiply that by the number of hours

  const positionOfMinutes = hourSection * rowHeight
  const topPosition = positionOfTheHour + positionOfMinutes + minutesSection

  return {
    topPosition,
  }
}

export const getMomentDays = dayId => {
  const getDayName = idx =>
    moment(dayId)
      .startOf('week')
      .add(idx, 'day')
  const getDayNumber = idx =>
    moment(dayId)
      .startOf('week')
      .add(idx, 'day')

  return [
    {
      id: getDayName(0).format('YYYY-MM-DD'),
      name: getDayName(0).format('dddd'),
      day: getDayNumber(0).format('DD'),
      isToday: getDayNumber(0).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(1).format('YYYY-MM-DD'),
      name: getDayName(1).format('dddd'),
      day: getDayNumber(1).format('DD'),
      isToday: getDayNumber(1).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(2).format('YYYY-MM-DD'),
      name: getDayName(2).format('dddd'),
      day: getDayNumber(2).format('DD'),
      isToday: getDayNumber(2).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(3).format('YYYY-MM-DD'),
      name: getDayName(3).format('dddd'),
      day: getDayNumber(3).format('DD'),
      isToday: getDayNumber(3).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(4).format('YYYY-MM-DD'),
      name: getDayName(4).format('dddd'),
      day: getDayNumber(4).format('DD'),
      isToday: getDayNumber(4).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(5).format('YYYY-MM-DD'),
      name: getDayName(5).format('dddd'),
      day: getDayNumber(5).format('DD'),
      isToday: getDayNumber(5).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(6).format('YYYY-MM-DD'),
      name: getDayName(6).format('dddd'),
      day: getDayNumber(6).format('DD'),
      isToday: getDayNumber(6).isSame(new Date(), 'day'),
    },
  ]
}

export const themeAccentColors = [
  { id: '#3bcbe8', color: '#3bcbe8', title: '#3bcbe8' },
  { id: '#d282af', color: '#d282af', title: '#d282af' },
  { id: '#ffbd00', color: '#ffbd00', title: '#ffbd00' },
  { id: '#9373b4', color: '#9373b4', title: '#9373b4' },
  { id: '#FF974A', color: '#FF974A', title: '#FF974A' },
  { id: '#4D7CFE', color: '#4D7CFE', title: '#4D7CFE' },
  { id: '#ff6b52', color: '#ff6b52', title: '#ff6b52' },
  { id: '#97bf62', color: '#97bf62', title: '#97bf62' },
  { id: '#5b7fff', color: '#5b7fff', title: '#5b7fff' },
  { id: '#cd8452', color: '#cd8452', title: '#cd8452' },
  { id: '#949a8e', color: '#949a8e', title: '#949a8e' },
]

export const getHoursInDay = timeSlotDuration => {
  let x = timeSlotDuration || 15 //minutes interval
  type TTimeItem = {
    id: string
    title: string
  }
  let times: TTimeItem[] = [] // time array
  let tt = 0 // start time

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    let mm = tt % 60 // getting minutes of the hour in 0-55 format

    if (hh === 0 && mm === 0) {
      tt = tt + x
    } else {
      times[i] = {
        id: ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2),
        title: ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2),
      }
      tt = tt + x
    }
  }
  return times
}

export const getServiceHoursAndMinutes = durationMinutes => {
  let hours = durationMinutes / 60
  let rhours = Math.floor(hours)
  let minutes = (hours - rhours) * 60

  const time =
    (rhours < 10 ? '0' + rhours : rhours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes)
  return time
}

export const getServiceDurationInMinutes = durationMinutes => {
  if (typeof durationMinutes !== 'string') {
    return durationMinutes
  }
  const time = durationMinutes?.split(':')
  const hours = time[0]
  const minutes = time[1]
  const durationInMinutes = Number(hours) * 60 + Number(minutes)
  return durationInMinutes
}

export const times = [
  { id: '00:00', title: '00:00' },
  { id: '00:15', title: '00:15' },
  { id: '00:30', title: '00:30' },
  { id: '00:45', title: '00:45' },
  { id: '01:00', title: '01:00' },
  { id: '01:15', title: '01:15' },
  { id: '01:30', title: '01:30' },
  { id: '01:45', title: '01:45' },
  { id: '02:00', title: '02:00' },
  { id: '02:15', title: '02:15' },
  { id: '02:30', title: '02:30' },
  { id: '02:45', title: '02:45' },
  { id: '03:00', title: '03:00' },
  { id: '03:15', title: '03:15' },
  { id: '03:30', title: '03:30' },
  { id: '03:45', title: '03:45' },
  { id: '04:00', title: '04:00' },
  { id: '04:15', title: '04:15' },
  { id: '04:30', title: '04:30' },
  { id: '04:45', title: '04:45' },
  { id: '05:00', title: '05:00' },
  { id: '05:15', title: '05:15' },
  { id: '05:30', title: '05:30' },
  { id: '05:45', title: '05:45' },
  { id: '06:00', title: '06:00' },
  { id: '06:15', title: '06:15' },
  { id: '06:30', title: '06:30' },
  { id: '06:45', title: '06:45' },
  { id: '07:00', title: '07:00' },
  { id: '07:15', title: '07:15' },
  { id: '07:30', title: '07:30' },
  { id: '07:45', title: '07:45' },
  { id: '08:00', title: '08:00' },
  { id: '08:15', title: '08:15' },
  { id: '08:30', title: '08:30' },
  { id: '08:45', title: '08:45' },
  { id: '09:00', title: '09:00' },
  { id: '09:15', title: '09:15' },
  { id: '09:30', title: '09:30' },
  { id: '09:45', title: '09:45' },
  { id: '10:00', title: '10:00' },
  { id: '10:15', title: '10:15' },
  { id: '10:30', title: '10:30' },
  { id: '10:45', title: '10:45' },
  { id: '11:00', title: '11:00' },
  { id: '11:15', title: '11:15' },
  { id: '11:30', title: '11:30' },
  { id: '11:45', title: '11:45' },
  { id: '12:00', title: '12:00' },
  { id: '12:15', title: '12:15' },
  { id: '12:30', title: '12:30' },
  { id: '12:45', title: '12:45' },
  { id: '13:00', title: '13:00' },
  { id: '13:15', title: '13:15' },
  { id: '13:30', title: '13:30' },
  { id: '13:45', title: '13:45' },
  { id: '14:00', title: '14:00' },
  { id: '14:15', title: '14:15' },
  { id: '14:30', title: '14:30' },
  { id: '14:45', title: '14:45' },
  { id: '15:00', title: '15:00' },
  { id: '15:15', title: '15:15' },
  { id: '15:30', title: '15:30' },
  { id: '15:45', title: '15:45' },
  { id: '16:00', title: '16:00' },
  { id: '16:15', title: '16:15' },
  { id: '16:30', title: '16:30' },
  { id: '16:45', title: '16:45' },
  { id: '17:00', title: '17:00' },
  { id: '17:15', title: '17:15' },
  { id: '17:30', title: '17:30' },
  { id: '17:45', title: '17:45' },
  { id: '18:00', title: '18:00' },
  { id: '18:15', title: '18:15' },
  { id: '18:30', title: '18:30' },
  { id: '18:45', title: '18:45' },
  { id: '19:00', title: '19:00' },
  { id: '19:15', title: '19:15' },
  { id: '19:30', title: '19:30' },
  { id: '19:45', title: '19:45' },
  { id: '20:00', title: '20:00' },
  { id: '20:15', title: '20:15' },
  { id: '20:30', title: '20:30' },
  { id: '20:45', title: '20:45' },
  { id: '21:00', title: '21:00' },
  { id: '21:15', title: '21:15' },
  { id: '21:30', title: '21:30' },
  { id: '21:45', title: '21:45' },
  { id: '22:00', title: '22:00' },
  { id: '22:15', title: '22:15' },
  { id: '22:30', title: '22:30' },
  { id: '22:45', title: '22:45' },
  { id: '23:00', title: '23:00' },
  { id: '23:15', title: '23:15' },
  { id: '23:30', title: '23:30' },
  { id: '23:45', title: '23:45' },
]

export const duration = [
  { id: 15, title: '15 min' },
  { id: 30, title: '30 min' },
  { id: 45, title: '45 min' },
  { id: 60, title: '1 sat' },
  { id: 75, title: '1 sat 15 min' },
  { id: 90, title: '1 sat 30 min' },
  { id: 105, title: '1 sat 45 min' },
  { id: 120, title: '2 sata' },
  { id: 135, title: '2 sata 15 min' },
  { id: 150, title: '2 sata 30 min' },
  { id: 165, title: '2 sata 45 min' },
  { id: 180, title: '3 sata' },
  { id: 195, title: '3 sata 15 min' },
  { id: 215, title: '3 sata 30 min' },
  { id: 225, title: '3 sata 45 min' },
  { id: 240, title: '4 sata' },
  { id: 270, title: '4 sata 30 min' },
  { id: 300, title: '5 sati' },
  { id: 330, title: '5 sati 30 min' },
  { id: 360, title: '6 sati' },
  { id: 390, title: '6 sati 30 min' },
  { id: 420, title: '7 sati' },
  { id: 480, title: '8 sati' },
  { id: 540, title: '9 sati' },
  { id: 600, title: '10 sati' },
  { id: 660, title: '11 sati' },
  { id: 720, title: '12 sati' },
  { id: 840, title: '14 sati' },
  { id: 960, title: '16 sati' },
  { id: 1080, title: '18 sati' },
  { id: 1200, title: '20 sati' },
  { id: 1320, title: '22 sati' },
  { id: 1440, title: '24 sati' },
]

export const colors = [
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
  '#4D7CFE',
  '#FE4D97',
  '#6DD230',
  '#FFAB2B',
]

export const getOrgId = () => {
  const storedOrgId = sessionStorage.getItem('orgId')
  return storedOrgId && storedOrgId !== 'null' ? storedOrgId : null
}

export const getLocationId = () => {
  return (
    sessionStorage.getItem('locationId') || localStorage.getItem('locationId')
  )
}

export const getClientAvatar = (resource, isColor, size, large) => {
  return resource?.user?.avatarUrl &&
    !resource?.user?.avatarUrl.endsWith('null') ? (
    <Avatar
      style={{ margin: '0px 4px' }}
      size={size}
      src={resource?.user?.avatarUrl}
    />
  ) : resource?.user?.google_profilePicUrl ? (
    <Avatar
      style={{ margin: '0px 4px' }}
      size={size}
      src={resource?.user?.google_profilePicUrl}
    />
  ) : resource?.user?.fb_profilePicUrl ? (
    <Avatar
      style={{ margin: '0px 4px' }}
      size={size}
      src={resource?.user?.fb_profilePicUrl}
    />
  ) : (
    <AvatarWrapper size={size} style={{ marginRight: 10 }}>
      <OrganizationSwitchIcon
        style={{ width: 32, height: 32, margin: '0px 4px' }}
        background={resource?.color || '#6a4ee1'}
        size={size}
      >
        {resource?.firstName ? resource?.firstName.substring(0, 2) : 'NN'}
      </OrganizationSwitchIcon>
    </AvatarWrapper>
  )
}

export const hasUserAvatar = (resource?: any) => {
  return (
    resource?.user?.avatarUrl && !resource?.user?.avatarUrl.endsWith('null')
  )
}

export const getUserAvatar = (
  resource?: any,
  size?: any,
  hasDefaultAvatar?: boolean
) => {
  let userAvatar = null

  if (hasUserAvatar(resource)) {
    userAvatar = resource?.user?.avatarUrl
  } else if (!!resource?.user?.google_profilePicUrl) {
    userAvatar = resource?.user?.google_profilePicUrl
  } else if (!!resource?.user?.fb_profilePicUrl) {
    userAvatar = resource?.user?.fb_profilePicUrl
  }

  if (resource?.avatarUrl && !resource?.avatarUrl.endsWith('null')) {
    userAvatar = resource?.avatarUrl
  }

  return userAvatar ? (
    <Avatar size={size} src={userAvatar} />
  ) : hasDefaultAvatar ? (
    <DefaultAvatar size={size}>
      <img
        alt=""
        style={{ borderRadius: '100%' }}
        src={process.env.PUBLIC_URL + '/assets/default-avatar.svg'}
      />
    </DefaultAvatar>
  ) : (
    <AvatarWrapper size={size}>
      <Icons.UserBold color={'#333'} size={size} />
    </AvatarWrapper>
  )
}

export const getLocationImage = location => {
  const imageUrl = location?.imageUrl || location?.avatarUrl

  return !imageUrl || imageUrl?.endsWith('null')
    ? process.env.PUBLIC_URL + '/assets/noImage.svg'
    : imageUrl
}
export const useStaticData = () => {
  const { t } = useTranslation()
  const genders = useMemo(
    () => [
      { id: 'Female', title: t('translation.helpers.female') },
      { id: 'Male', title: t('translation.helpers.male') },
      { id: 'Unspecified', title: t('translation.helpers.dont-say') },
    ],
    [t]
  )

  return { genders }
}

function getSize() {
  const isClient = typeof window === 'object'
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

export function useViewportDimensions() {
  const [dimensions, setDimensions] = useState({
    isMobile: window?.innerWidth < 1020,
  })
  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) {
      return
    }

    const handleResize = debounce(() => {
      const dimensionsCalc = {
        isMobile: window?.innerWidth < 1020,
      }

      if (dimensionsCalc.isMobile !== dimensions.isMobile) {
        setDimensions(dimensionsCalc)
      }
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [dimensions]) // Empty array ensures that effect is only run on mount and unmount

  return dimensions
}
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) {
      return
    }

    const handleResize = debounce(() => {
      setWindowSize(getSize())
    }, 400)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowSize]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export const getHour = date => {
  return moment(date).format('HH:mm')
}

export const getISODate = (date?: moment.MomentInput) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getStartOfWeek = (date: moment.MomentInput) => {
  return moment(date)
    .startOf('week')
    .format('YYYY-MM-DD')
}

export const getEndOfWeek = (date: moment.MomentInput) => {
  return moment(date)
    .endOf('week')
    .format('YYYY-MM-DD')
}

export const getStartOfMonth = (date: moment.MomentInput) => {
  return moment(date)
    .startOf('month')
    .subtract(5, 'day')
    .format('YYYY-MM-DD')
}

export const getEndOfMonth = (date: moment.MomentInput) => {
  return moment(date)
    .endOf('month')
    .add(5, 'day')
    .format('YYYY-MM-DD')
}

export const getStartOfYear = (date: moment.MomentInput) => {
  return moment(date)
    .startOf('year')
    .format('YYYY-MM-DD')
}

export const getEndOfYear = (date: moment.MomentInput) => {
  return moment(date)
    .endOf('year')
    .format('YYYY-MM-DD')
}

export const getTodayISO = () => {
  return moment().format('YYYY-MM-DD')
}

export const getDuration = totalMinutes => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return (
    (hours > 0 ? hours + ' h ' : '') + (minutes > 0 ? minutes + ' min' : '')
  )
}

export const getStatusLabel = (
  label?: string,
  t?: any,
  isMobile?: boolean,
  reason?: string
) => {
  switch (label) {
    case 'CONFIRMED':
      return isMobile ? '✓' : t('translation.status.confirmed')
    case 'new':
      return isMobile ? '⟲' : t('translation.status.new')
    case 'DRAFT':
      return isMobile ? '⟲' : t('translation.status.draft')
    case 'PENDING_PAYMENT':
      return isMobile ? '⟲' : t('translation.status.payment-pending')
    case 'PAID':
      return t('translation.status.paid')
    case 'CANCELED':
      return t('translation.status.payment-canceled')
    case 'REFUNDED':
      return t('translation.status.payment-refunded')
    case 'canceled':
      return isMobile
        ? '⤫'
        : reason === 'CLIENT_NO_SHOW'
        ? t('translation.helpers.noShow')
        : reason === 'CLIENT_CANCELED'
        ? t('translation.helpers.clientCanceled')
        : t('translation.status.canceled')
    case 'concluded':
      return isMobile ? '✓' : t('translation.helpers.concluded')
    default:
      return isMobile ? '⟲' : t('translation.status.new')
  }
}

export const getStatusColor = (label: string, selectedTheme: ITheme) => {
  switch (label) {
    case 'CONFIRMED':
      return selectedTheme.colors.accent2
    case 'new':
      return selectedTheme.colors.primary
    case 'DRAFT':
      return selectedTheme.colors.primary
    case 'PENDING_PAYMENT':
      return selectedTheme.colors.accent5
    case 'PAID':
      return selectedTheme.colors.accent2
    case 'CANCELED':
      return selectedTheme.colors.red
    case 'REFUNDED':
      return selectedTheme.colors.accent5
    case 'canceled':
      return selectedTheme.colors.accent5
    case 'noShow':
      return selectedTheme.colors.accent5
    case 'concluded':
      return selectedTheme.colors.primary
    default:
      return selectedTheme.colors.primary
  }
}

export const getStatusIcon = (label: string, bold?: boolean) => {
  switch (label) {
    case 'CONFIRMED':
      return (
        <Icons.Checkmark
          size={'smaller'}
          style={{ strokeWidth: bold ? 2 : null }}
        />
      )
    case 'new':
      return (
        <Icons.Checkmark
          size={'smaller'}
          style={{ strokeWidth: bold ? 2 : null }}
        />
      )
    case 'DRAFT':
      return (
        <Icons.Shop size={'smaller'} style={{ strokeWidth: bold ? 2 : null }} />
      )
    case 'PENDING_PAYMENT':
      return (
        <Icons.CreditCard
          size={'smaller'}
          style={{ strokeWidth: bold ? 2 : null }}
        />
      )
    case 'PAID':
      return (
        <Icons.CreditCard
          size={'smaller'}
          style={{ strokeWidth: bold ? 2 : null }}
        />
      )
    case 'REFUNDED':
      return (
        <Icons.CreditCard
          size={'smaller'}
          style={{ strokeWidth: bold ? 2 : null }}
        />
      )
    case 'CANCELED':
      return (
        <Icons.CardCanceled
          size={'smaller'}
          style={{ strokeWidth: bold ? 2 : null }}
        />
      )
    case 'canceled':
      return (
        <Icons.Cross
          size={'extraSmall'}
          style={{ strokeWidth: bold ? 3 : 2 }}
        />
      )
    case 'noShow':
      return (
        <Icons.Cross
          size={'extraSmall'}
          style={{ strokeWidth: bold ? 3 : 2 }}
        />
      )
    default:
      return (
        <Icons.Checkmark
          size={'smaller'}
          style={{ strokeWidth: bold ? 2 : null }}
        />
      )
  }
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        typeof ref.current.contains != 'function' ||
        ref.current.contains(event.target)
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const getDurationString = duration => {
  const hours = Math.floor(duration / 60)
  const minutes = Math.floor(duration % 60)

  return (
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes)
  )
}

export const normalizeMobilePhoneNumber = number => {
  let normalized = number
  if (!normalized) return
  normalized = normalized.replace(/[^\d+]+/g, '')
  normalized = normalized.replace(/^3850/, '+385')
  normalized = normalized.replace(/^385/, '+385')
  return normalized
}
export const getServiceDuration = selectedServices => {
  return sumBy(selectedServices, 'durationMinutes')
}

export const getEndTime = (timeId, selectedServices) => {
  const duration = getServiceDuration(selectedServices)
  const time = moment().format('YYYY-MM-DD') + ' ' + timeId
  return moment(time)
    .add(duration, 'minutes')
    .format('HH:mm')
}

export const formatPhone = phone => {
  return phone?.length > 4
    ? phone?.replace(/[^\dxX+]/g, '').replace('3850', '385')
    : null
}
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export const isInStandaloneMode = () =>
  //@ts-ignore
  'standalone' in window.navigator && window.navigator.standalone

export const getCalendarTimes = (calendarData, startDate, endDate) => {
  return calendarData?.data?.calendar?.getOpenHours?.filter(x =>
    moment(x.date).isBetween(startDate, endDate)
  )
}

export const getPlanName = planId => {
  switch (planId) {
    case 'free': {
      return i18n.t('translation.PlansForm.besplatan')
    }
    case 'trial': {
      return i18n.t('translation.helpers.proba')
    }
    case 'business': {
      return i18n.t('translation.PlansForm.business')
    }
    case 'advanced': {
      return i18n.t('translation.PlansForm.advanced')
    }
    default: {
      return i18n.t('translation.PlansForm.besplatan')
    }
  }
}

export const getPlanNameWithSuffix = planId => {
  switch (planId) {
    case 'free': {
      return 'Besplatni'
    }
    case 'business': {
      return 'Poslovni'
    }
    case 'advanced': {
      return 'Napredni'
    }
    default: {
      return 'Besplatni'
    }
  }
}

export const getPlanStatus = status => {
  switch (status) {
    case 'completed': {
      return i18n.t('translation.helpers.zavrsen')
    }
    case 'verification': {
      return 'verifikacija'
    }
    case 'pending': {
      return i18n.t('translation.helpers.u-tijeku')
    }
    default: {
      return i18n.t('translation.helpers.greska')
    }
  }
}

export const getPlanInterval = interval => {
  switch (interval) {
    case '1 month': {
      return i18n.t('translation.helpers.mjesecno')
    }
    case '365 days': {
      return i18n.t('translation.helpers.godisnje')
    }
    default: {
      return i18n.t('translation.helpers.mjesecno')
    }
  }
}

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const capitalizeCitiesAndCountries = string => {
  if (typeof string !== 'string') return ''
  let splitted = string.split(' ')
  let result = []
  for (let word of splitted) {
    const trimmed = word.toLowerCase().trim()
    if (word.length > 2) {
      result.push(trimmed.charAt(0).toUpperCase() + trimmed.slice(1))
    } else if (trimmed) {
      result.push(trimmed)
    }
  }
  return result.join(' ')
}

export const getDayName = day => {
  switch (day) {
    case 'mon': {
      return i18n.t('translation.helpers.monday')
    }
    case 'tue': {
      return i18n.t('translation.helpers.tuesday')
    }
    case 'wed': {
      return i18n.t('translation.helpers.wednesday')
    }
    case 'thu': {
      return i18n.t('translation.helpers.thursday')
    }
    case 'fri': {
      return i18n.t('translation.helpers.friday')
    }
    case 'sat': {
      return i18n.t('translation.helpers.saturday')
    }
    default: {
      return i18n.t('translation.helpers.sunday')
    }
  }
}

export const totalServicesCost = selectedServices => {
  return (
    Math.round((sumBy(selectedServices, 'price') + Number.EPSILON) * 100) / 100
  )
}

export const formatPriceNumberWithDelimiter = (
  number,
  addZerosIfNoDecimals = true
) => {
  const parsed = Math.round(parseFloat(number) * 100) / 100
  if ((!number && number !== 0) || isNaN(parsed)) return
  const formatter = Intl.NumberFormat('en-US')
  const decimalChange = Math.floor(parsed * 100) % 100
  const zerosToAdd =
    decimalChange === 0 ? '.00' : decimalChange % 10 === 0 ? '0' : ''

  return addZerosIfNoDecimals && zerosToAdd
    ? formatter.format(parsed) + zerosToAdd
    : formatter.format(parsed)
}
