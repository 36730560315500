import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const WorkHours = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M22.2 13.8c2.3 2.3 2.3 6.1 0 8.5-2.3 2.3-6.1 2.3-8.5 0-2.3-2.3-2.3-6.1 0-8.5 2.4-2.4 6.2-2.4 8.5 0"
        fill="none"
      ></path>
      <path
        d="M20 18.2h-2V15M3 2.7c1.1 1.1 2.6 1.8 4.2 1.8.8 0 1.5-.2 2.3-.5"
        fill="none"
      ></path>
      <path
        d="M8.5 2c1.4 1.4 1.4 3.6 0 4.9s-3.6 1.4-5 .1-1.3-3.6 0-5 3.6-1.3 5 0M10.5 12.3C9.3 9.9 6.3 8.8 3.8 10c-1.7.8-2.8 2.6-2.8 4.5V18h2.5l.5 6h4l.5-6H10"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default WorkHours
