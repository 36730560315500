import React from 'react'
import { Loader } from './components'
import { useParams } from 'react-router'
import { useSdk } from './sdk'
import { useMarketGetByOrgUrlNameQuery } from 'state/graphql'

export const RedirectRoute = () => {
  const { orgId } = useParams<any>()
  const { navigateTo } = useSdk()
  const { data } = useMarketGetByOrgUrlNameQuery({
    variables: { orgUrlName: orgId },
  })

  const newOrgId = data?.market?.getByOrgUrlName?.id

  if (newOrgId) {
    navigateTo.selectedOrg({ orgId: newOrgId })
  }

  return <Loader />
}
