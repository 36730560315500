import styled from 'styled-components'
import { gray, grayDark } from '../style/colors'

import { fontFamily } from '../style/fonts'

export const NavBarStyled = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: relative;
  z-index: 2;
`

export const NavBarUser = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding-top: 1px;
`

export const NavBarAvatar = styled.div<{ avatar: any }>`
  border-radius: 100px;
  width: 30px;
  height: 30px;
  background: ${gray};
  background-size: cover;
  background-image: url(${props => props.avatar});
  margin-right: 12px;
  min-width: 30px;
`

export const NavBarName = styled.div`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: 500;
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const NavBarBadge = styled.div`
  background: #c7beee;
  color: #2e1a85;
  font-size: 9px;
  padding: 5px 5px;
  font-weight: 600;
  width: 100%;
  text-align: center;
`

export const NavBarEmail = styled.div`
  color: ${grayDark};
  font-family: ${fontFamily};
  font-size: 10px;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 8px;
`

export const NavBarChavron = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.outline};
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  width: 18px;
  height: 18px;
`
