import { useContext } from 'react'
import baseStyled, {
  ThemedStyledInterface,
  ThemeContext,
} from 'styled-components'

type TThemeColors = {
  background: string
  backgroundDarker: string
  primary: string
  outline: string
  accent1: string
  accent2: string
  accent3: string
  accent4: string
  accent5: string
  accent6: string
  accent7: string
  secondaryDark: string
  secondaryDarker: string
  text: string
  light: string
  gradientBackground: string
  mainBackground: string
  lightBackground: string
  primaryDark: string
  red: string
}
const colors = {
  background: '#dcdcfb',
  backgroundDarker: '#f2f4f6',
  outline: '#f0f0f0',
  accent1: '#fe9060',
  accent2: '#64c939',
  accent3: '#333',
  accent4: '#ffbd00',
  accent5: '#fe9060',
  accent6: '#f1d7cb',
  accent7: '#a34218',
  secondaryDark: '#98a9bc',
  secondaryDarker: '#778ca2',
  text: '#333',
  primary: '#6a4ee1',
  light: '#ffffff',
  accent: '#0062ff',
  gradientBackground: '#e9e9ff',
  mainBackground: '#e9e9ff',
  lightBackground: '#f4f4ff',
  primaryDark: '#482fb4',
  warningRedText: '#ff0000',
  warningRedBackground: '#ffe8e8',
  green: '#53b827',
  red: '#fc5a5a',
}

export const themes = {
  default: {
    colors: colors,
  },
  dark: {
    colors: colors,
  },
}
export interface ITheme {
  colors: TThemeColors
}
export const styled = baseStyled as ThemedStyledInterface<ITheme>
export default styled

export const useTheme = () => {
  return useContext(ThemeContext) as ITheme
}
