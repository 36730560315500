import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import {
  DesktopLocationContainer,
  DesktopLocationWrapper,
} from 'views/Booking/SelectedLocation/styles'
import { useRescheduleBookingMutation, MyBookingsDocument } from 'state/graphql'
import { useResourceBookingGetQuery } from 'state/graphql'
import { useParams } from 'react-router'
import moment from 'moment-timezone'
import {
  Button,
  NotFound,
  Loader,
  Tooltip,
  Icons,
  AnimatedDiv,
} from 'components'
import { Popup } from 'components/Popup/Popup'
import {
  HeaderSubTitle,
  HeaderTitle,
  HeaderTitles,
  WizardBackHeader,
  WizardBody,
  WizardHeaderSticky,
  WizardNoDateContainer,
  WizardNoDatePlaceholder,
  WizardNoTimeTitle,
  WizardTimeContainer,
  WizardTimesItem,
  WizardHourSlot,
  WizardHourItem,
  WizardWrapper,
  DatePicked,
} from 'views/Booking/BookingSteps/styles'
import {
  useMarketGetFreeSlotsForRescheduleQuery,
  useMarketGetDateAvailabilityForRescheduleQuery,
} from 'state/graphql'
import { useSdk } from 'sdk'
import { Calendar } from 'components'
import { range } from 'lodash'
import { useHeader } from 'mobile/Header'
import styled from 'styled-components'
import { device } from '../../../constants'
import { getCurrentLanguage } from 'localization'
import { orderBy } from 'lodash'
import { MobileContext } from 'App'
import { themes } from 'themes'

export const StyledNextButton = styled(Button)`
  margin-top: 0px !important;
  margin-left: 10px;
  line-height: 1.5;
`
export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: 1rem;
  margin-top: 30px;
  max-width: 820px;

  @media ${device.tablet} {
    margin-top: 15px;
    padding-right: 1em;
  }
`
export const PanelWrapperInner = styled.div`
  border-top: 1px solid rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
`

export const TimesGrid = styled.div<{ timeSlotDuration: number }>`
  display: grid;
  flex-flow: row wrap;
  padding-bottom: 6px;
  padding-top: 6px;
  width: 100%;
  grid-template-columns: ${props =>
    props.timeSlotDuration === 5
      ? '1fr 1fr 1fr 1fr 1fr 1fr'
      : '1fr 1fr 1fr 1fr'};
  margin: 0 auto;

  @media ${device.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    width: 100%;
  }
`

const TimesPanel = props => {
  const { selectedTime, onTimeSelected, timeSlotDuration, slotsForDay } = props
  const hrs = range(0, 24).map(e => (e < 10 ? `0${e}` : String(e)))
  const timeSlotMinutes = Array.from(
    new Set(
      slotsForDay?.timeSlots?.map(x => x?.startTime).map(x => x?.split(':')[1])
    )
  ).sort((a, b) => +a - +b)
  const isSlotAvailable = time =>
    slotsForDay.timeSlots.find(e => e.startTime === time)
  const isHourAvailable = hr =>
    slotsForDay.timeSlots.find(e => e.startTime.includes(hr))

  return (
    <PanelWrapper style={{ maxWidth: 'unset' }}>
      <PanelWrapperInner>
        {hrs.map(hr => (
          <WizardHourSlot key={hr} isVisible={isHourAvailable(`${hr}:`)}>
            <WizardHourItem>{hr}</WizardHourItem>
            <TimesGrid timeSlotDuration={timeSlotDuration}>
              {timeSlotMinutes?.map((min, idx) => (
                <WizardTimesItem
                  key={idx}
                  data-cy={`${hr}:${min}`}
                  onClick={() =>
                    isSlotAvailable(`${hr}:${min}`) &&
                    onTimeSelected(`${hr}:${min}`)
                  }
                  isSelected={selectedTime === `${hr}:${min}`}
                  isAvailable={isSlotAvailable(`${hr}:${min}`)}
                >
                  {`${hr}:${min}`}
                </WizardTimesItem>
              ))}
            </TimesGrid>
          </WizardHourSlot>
        ))}
      </PanelWrapperInner>
    </PanelWrapper>
  )
}

const AppointmentReschedule = () => {
  const { bookingId } = useParams<any>()

  const { navigateTo, appServices, t } = useSdk()

  const containerRef = useRef<any>()

  const {
    data: bookingData,
    loading: loadingBooking,
    error,
  } = useResourceBookingGetQuery({
    variables: { id: bookingId },
    skip: !bookingId,
  })
  const [rescheduleBookingMutation] = useRescheduleBookingMutation()
  const confirmButtonRef = useRef<any>()

  const booking = bookingData?.market?.getBooking
  const [selectedTime, setSelectedTime] = useState<string | undefined | null>()
  const [confirmModalOpen, setConfirmModalOpen] = useState<any>(false)

  const [show, setShow] = useState(false)
  const isMobile = useContext(MobileContext)

  let observer = useRef<any>()
  const calendarRef = useCallback(node => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(
      entries => {
        if (!entries[0].isIntersecting) {
          setShow(true)
        } else {
          setShow(false)
        }
      },
      {
        threshold: 0.01,
      }
    )

    if (node) observer.current.observe(node)
  }, [])

  const [isBusy, setIsBusy] = useState<any>(false)
  const [dayId, setDayId] = useState<any>(
    moment(booking?.startTime).format('YYYY-MM-DD')
  )

  const {
    data: freeSlotsData,
    loading: loadingFreeSlotsData,
  } = useMarketGetFreeSlotsForRescheduleQuery({
    fetchPolicy: 'no-cache',
    variables: {
      resourceBookingId: bookingId,
      dateFrom: dayId,
    },
    skip: !bookingId,
  })

  const {
    data: availableDatesData,
  } = useMarketGetDateAvailabilityForRescheduleQuery({
    fetchPolicy: 'no-cache',
    variables: {
      resourceBookingId: bookingId,
      dateFrom: dayId,
      dateTo: moment(dayId)
        .add(1, 'M')
        .endOf('month')
        .format('YYYY-MM-DD'),
    },
    skip: !bookingId,
  })

  const availableDates = orderBy(
    availableDatesData?.market?.getDateAvailabilityForReschedule,
    'date',
    ['asc']
  )
  useHeader({
    content: () => (
      <span style={{ margin: 'auto' }}>
        {t('translation.Appointment.tooltip-changeTime')}
      </span>
    ),
    onGoBack: () => {
      navigateTo.myBooking({ bookingId })
    },
  })
  const [nextAvailableDate, setNextAvailableDate] = useState<any>(null)

  useEffect(() => {
    setNextAvailableDate(
      availableDates?.find(item => {
        return (
          item?.slotCount &&
          moment(item?.date).format('YYYY-MM-DD') >
            moment(dayId).format('YYYY-MM-DD')
        )
      })?.date
    )
  }, [dayId, availableDates])

  useEffect(() => {
    if (!selectedTime) return
    confirmButtonRef?.current?.scrollIntoView()
  }, [selectedTime])

  const freeSlots = freeSlotsData?.market?.getFreeSlotsForReschedule || []
  const slotsForDay = freeSlots.find(x => x.date === dayId)

  const handleDateChanged = e => {
    setSelectedTime(null)
    setDayId(moment(e).format('YYYY-MM-DD'))
  }

  const handleTimeSelected = time => {
    setSelectedTime(time)
    setConfirmModalOpen(true)
  }

  const handleActiveStartDateChanged = e => {
    handleDateChanged(e.activeStartDate)
  }

  async function rescheduleBooking() {
    setIsBusy(true)
    let results = await rescheduleBookingMutation({
      variables: {
        input: {
          id: bookingId,
          date: moment(dayId).format('YYYY-MM-DD'),
          startTime: selectedTime,
        },
      },
      refetchQueries: [
        {
          query: MyBookingsDocument,
          variables: {
            dateFrom: moment()
              .startOf('year')
              .format('YYYY-MM-DD'),
            dateTo: moment()
              .endOf('year')
              .format('YYYY-MM-DD'),
          },
        },
      ],
    })

    if (!results.errors) {
      navigateTo.myBooking({ bookingId: booking?.id })
      appServices.toast.success(
        t('translation.Appointment.success-toast-reschedule')
      )
    } else {
      appServices.toast.danger(results?.errors?.[0]?.message)
    }
    setIsBusy(false)
  }
  const isLoading = isBusy || loadingBooking
  if (error) return <NotFound />
  return (
    <AnimatedDiv>
      {isLoading ? <Loader isComponent /> : null}
      <DesktopLocationWrapper>
        {confirmModalOpen ? (
          <Popup
            text={t('translation.Appointment.text-popup-reschedule', {
              oldDate: moment(booking?.startTime)
                .tz('Europe/Zagreb')
                .format('DD.MM.YYYY. HH:mm'),
              newDate: moment(`${dayId} ${selectedTime}`)
                .tz('Europe/Zagreb')
                .format('DD.MM.YYYY. HH:mm'),
            })}
            title={t('translation.Appointment.title-popup-reschedule')}
            onCancel={() => {
              setSelectedTime(null)
              setConfirmModalOpen(false)
            }}
            onConfirm={() => {
              rescheduleBooking()
              setConfirmModalOpen(false)
            }}
            confirmButtonLabel={t(
              'translation.Appointment.label-button-confirm'
            )}
            closeButtonLabel={t('translation.Appointment.label-button-close')}
          />
        ) : null}

        <DesktopLocationContainer ref={containerRef}>
          {isLoading ? (
            <Loader isComponent />
          ) : (
            <WizardWrapper>
              <WizardBody isFullWidth style={{ margin: 'auto' }}>
                <WizardHeaderSticky>
                  <WizardBackHeader>
                    <HeaderTitles
                      onClick={() =>
                        navigateTo.myBooking({ bookingId: booking.id })
                      }
                    >
                      <Tooltip label={t('translation.Step3Time.label-back')}>
                        <Icons.ArrowLeft size={'large'} />
                      </Tooltip>
                      <HeaderSubTitle>
                        {t('translation.Appointment.title-headerSubTitle')}
                      </HeaderSubTitle>
                    </HeaderTitles>
                  </WizardBackHeader>
                  <HeaderTitle>
                    {t('translation.Appointment.tooltip-changeTime')}
                  </HeaderTitle>
                </WizardHeaderSticky>

                <WizardTimeContainer>
                  <div ref={calendarRef}>
                    <Calendar
                      availableDates={availableDates || []}
                      minDate={new Date()}
                      isRescheduleCalendar
                      onChange={handleDateChanged}
                      defaultValue={new Date(dayId)}
                      value={new Date(dayId)}
                      formatDay={(locale, date) => moment(date).format('DD')}
                      style={{ width: 700 }}
                      locale={getCurrentLanguage()}
                      next2Label={null}
                      prev2Label={null}
                      onActiveStartDateChange={handleActiveStartDateChanged}
                    />
                  </div>
                  {isMobile ? (
                    <DatePicked className={` ${show ? 'show' : ''}`}>
                      {t('translation.Step3Time.chosen-date')}
                      <span
                        style={{
                          color: themes.default.colors.primary,
                          fontWeight: 500,
                        }}
                      >
                        {moment(dayId).format(
                          t('regionalFormats.date.momentFormatDate')
                        )}
                      </span>
                    </DatePicked>
                  ) : null}
                  {loadingFreeSlotsData ? (
                    <WizardNoDateContainer>
                      <Loader isComponent />
                    </WizardNoDateContainer>
                  ) : !slotsForDay?.timeSlots || !slotsForDay?.timeSlots[0] ? (
                    <WizardNoDateContainer>
                      <WizardNoDatePlaceholder>
                        <WizardNoTimeTitle>
                          {t(
                            'translation.Step3Time.title-noAvailableSlotsFor'
                          ) +
                            moment(dayId).format(
                              t('regionalFormats.date.momentFormatDate')
                            )}
                        </WizardNoTimeTitle>
                        {nextAvailableDate ? (
                          <StyledNextButton
                            label={t(
                              'translation.Step3Time.title-nextAvailableSlot'
                            )}
                            buttonType={'primary'}
                            size={'medium'}
                            onClick={() => {
                              setSelectedTime(null)
                              setDayId(
                                moment(nextAvailableDate).format('YYYY-MM-DD')
                              )
                            }}
                            iconComponent={<Icons.ArrowRight size={'small'} />}
                            iconRight
                            name={'nextAvailableDate'}
                          />
                        ) : null}
                      </WizardNoDatePlaceholder>
                    </WizardNoDateContainer>
                  ) : (
                    <TimesPanel
                      timeSlotDuration={booking?.location?.timeSlotDuration}
                      selectedTime={selectedTime}
                      onTimeSelected={handleTimeSelected}
                      slotsForDay={slotsForDay}
                    />
                  )}
                </WizardTimeContainer>
              </WizardBody>
            </WizardWrapper>
          )}
        </DesktopLocationContainer>
      </DesktopLocationWrapper>
    </AnimatedDiv>
  )
}

export default AppointmentReschedule
