import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Trash = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M21.5 5l-1.8 17.2c-.1 1-1 1.8-2 1.8H7.3c-1 0-1.9-.8-2-1.8L3.5 5M1 5h23M8 5V2c0-.6.4-1 1-1h7c.6 0 1 .4 1 1v3M12.5 9.5V20M17 9.5L16.5 20M8 9.5L8.5 20"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Trash
