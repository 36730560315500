import Add from 'components/Icon/Icons/Add'
import Alarm from 'components/Icon/Icons/Alarm'
import ArrowLeft from 'components/Icon/Icons/ArrowLeft'
import ArrowNext from 'components/Icon/Icons/ArrowNext'
import ArrowRight from 'components/Icon/Icons/ArrowRight'
import ArrowRightThick from 'components/Icon/Icons/ArrowRightThick'
import Billing from 'components/Icon/Icons/Billing'
import Book from 'components/Icon/Icons/Book'
import Briefcase from 'components/Icon/Icons/Briefcase'
import BriefCaseLight from 'components/Icon/Icons/BriefCaseLight'
import Calendar from 'components/Icon/Icons/Calendar'
import Chart from 'components/Icon/Icons/Chart'
import ChartArrowDown from 'components/Icon/Icons/ChartArrowDown'
import ChartArrowUp from 'components/Icon/Icons/ChartArrowUp'
import ChatHelp from 'components/Icon/Icons/ChatHelp'
import Check from 'components/Icon/Icons/Check'
import CheckBox from 'components/Icon/Icons/CheckBox'
import Checkmark from 'components/Icon/Icons/Checkmark'
import ChevDown from 'components/Icon/Icons/ChevDown'
import ChevronDown from 'components/Icon/Icons/ChevronDown'
import ChevronLeft from 'components/Icon/Icons/ChevronLeft'
import ChevronRight from 'components/Icon/Icons/ChevronRight'
import ChevronUp from 'components/Icon/Icons/ChevronUp'
import ChewronDownLight from 'components/Icon/Icons/ChewronDownLight'
import Clients from 'components/Icon/Icons/Clients'
import Clock from 'components/Icon/Icons/Clock'
import CreditCard from 'components/Icon/Icons/CreditCard'
import CroatianFlag from 'components/Icon/Icons/CroatianFlag'
import Cross from 'components/Icon/Icons/Cross'
import CSV from 'components/Icon/Icons/CSV'
import Dashboard from 'components/Icon/Icons/Dashboard'
import Day from 'components/Icon/Icons/Day'
import Edit from 'components/Icon/Icons/Edit'
import Email from 'components/Icon/Icons/Email'
import Employees from 'components/Icon/Icons/Employees'
import Error from 'components/Icon/Icons/Error'
import Facebook from 'components/Icon/Icons/Facebook'
import Flash from 'components/Icon/Icons/Flash'
import Gear from 'components/Icon/Icons/Gear'
import GeneralInfo from 'components/Icon/Icons/GeneralInfo'
import Gift from 'components/Icon/Icons/Gift'
import Google from 'components/Icon/Icons/Google'
import Group from 'components/Icon/Icons/Group'
import HamburgerMenu from 'components/Icon/Icons/HamburgerMenu'
import Hand from 'components/Icon/Icons/Hand'
import Holidays from 'components/Icon/Icons/Holidays'
import House from 'components/Icon/Icons/House'
import HouseBold from 'components/Icon/Icons/HouseBold'
import Icon from 'components/Icon/Icons/Icon'
import Info from 'components/Icon/Icons/Info'
import Intercom from 'components/Icon/Icons/Intercom'
import Light from 'components/Icon/Icons/Light'
import Location from 'components/Icon/Icons/Location'
import Login from 'components/Icon/Icons/Login'
import Logout from 'components/Icon/Icons/Logout'
import Menu from 'components/Icon/Icons/Menu'
import More from 'components/Icon/Icons/More'
import NewLocation from 'components/Icon/Icons/NewLocation'
import NewWindow from 'components/Icon/Icons/NewWindow'
import OnlineBooking from 'components/Icon/Icons/OnlineBooking'
import PasswordLock from 'components/Icon/Icons/PasswordLock'
import PDF from 'components/Icon/Icons/PDF'
import Phone from 'components/Icon/Icons/Phone'
import PhoneBold from 'components/Icon/Icons/PhoneBold'
import PhoneDownload from 'components/Icon/Icons/PhoneDownload'
import PhoneEmail from 'components/Icon/Icons/PhoneEmail'
import Professions from 'components/Icon/Icons/Professions'
import RatingStar from 'components/Icon/Icons/RatingStar'
import Refresh from 'components/Icon/Icons/Refresh'
import Register from 'components/Icon/Icons/Register'
import SafariShare from 'components/Icon/Icons/SafariShare'
import Search from 'components/Icon/Icons/Search'
import SendEmail from 'components/Icon/Icons/SendEmail'
import Services from 'components/Icon/Icons/Services'
import Shop from 'components/Icon/Icons/Shop'
import ShoppingCart from 'components/Icon/Icons/ShoppingCart'
import Star from 'components/Icon/Icons/Star'
import ThreeDay from 'components/Icon/Icons/ThreeDay'
import Tour from 'components/Icon/Icons/Tour'
import Trash from 'components/Icon/Icons/Trash'
import UKFlag from 'components/Icon/Icons/UKFlag'
import User from 'components/Icon/Icons/User'
import UserBold from 'components/Icon/Icons/UserBold'
import UserMan from 'components/Icon/Icons/UserMan'
import Warning from 'components/Icon/Icons/Warning'
import Web from 'components/Icon/Icons/Web'
import Week from 'components/Icon/Icons/Week'
import WorkHours from 'components/Icon/Icons/WorkHours'
import Calendar2 from 'components/Icon/Icons/Calendar2'
import Notes from 'components/Icon/Icons/Notes'
import Eye from 'components/Icon/Icons/Eye'
import EyeDisabled from 'components/Icon/Icons/EyeDisabled'
import Share from 'components/Icon/Icons/Share'
import Heart from 'components/Icon/Icons/Heart'
import SpanishFlag from 'components/Icon/Icons/SpanishFlag'
import GermanFlag from 'components/Icon/Icons/GermanFlag'
import { SerbianFlag } from 'components/Icon/Icons/SerbianFlag'
import { CardCanceled } from 'components/Icon/Icons/CardCanceled'

export const Icons = {
  Add,
  Alarm,
  ArrowLeft,
  ArrowNext,
  ArrowRight,
  ArrowRightThick,
  Billing,
  Book,
  Briefcase,
  BriefCaseLight,
  Calendar,
  Chart,
  ChartArrowDown,
  ChartArrowUp,
  ChatHelp,
  Check,
  CheckBox,
  Checkmark,
  ChevDown,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChewronDownLight,
  Clients,
  Clock,
  CreditCard,
  CroatianFlag,
  Cross,
  CSV,
  Dashboard,
  Day,
  Edit,
  Email,
  Employees,
  Error,
  Eye,
  EyeDisabled,
  Facebook,
  Flash,
  Gear,
  GeneralInfo,
  Gift,
  Google,
  Group,
  HamburgerMenu,
  Hand,
  Holidays,
  House,
  Share,
  HouseBold,
  Icon,
  Info,
  Intercom,
  Light,
  Location,
  Login,
  Logout,
  Menu,
  More,
  NewLocation,
  NewWindow,
  OnlineBooking,
  PasswordLock,
  PDF,
  Phone,
  PhoneBold,
  PhoneDownload,
  PhoneEmail,
  Professions,
  RatingStar,
  Refresh,
  Register,
  SafariShare,
  Search,
  SendEmail,
  Services,
  Shop,
  ShoppingCart,
  Star,
  ThreeDay,
  Tour,
  Trash,
  UKFlag,
  User,
  UserBold,
  UserMan,
  Warning,
  Web,
  Week,
  WorkHours,
  Calendar2,
  Notes,
  Heart,
  SpanishFlag,
  GermanFlag,
  SerbianFlag,
  CardCanceled,
}
