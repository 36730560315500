import React, { useContext } from 'react'
import {
  FooterColumn,
  FooterContainer,
  FooterCopyright,
  FooterCopyrightLeft,
  FooterCopyrightRight,
  FooterFlex,
  FooterLink,
  FooterList,
  FooterListItem,
  FooterLogo,
  FooterSide,
  FooterSideRight,
  FooterSocialLinkStyled,
  FooterSocialWrapper,
  FooterText,
  FooterWrapper,
  VersionNumber,
} from './styles'
import { getCurrentLanguage } from '../../../localization'
import { publicAssets } from 'assets/publicAssets'
import { useSdk } from 'sdk'
import { MobileContext } from 'App'
import TikTok from 'components/Icon/Icons/TikTok'
import Youtube from 'components/Icon/Icons/Youtube'
import Linkedin from 'components/Icon/Icons/Linkedin'
import Instagram from 'components/Icon/Icons/Instagram'
import Facebook2 from 'components/Icon/Icons/Facebook2'

const Footer = () => {
  const { t } = useSdk()
  const isMobile = useContext(MobileContext)
  const lang = getCurrentLanguage()
  return (
    <FooterContainer>
      <FooterWrapper>
        {!isMobile ? (
          <FooterFlex>
            <FooterSide>
              <FooterLink>
                <FooterLogo src={publicAssets['zoyya-final-logo-white.svg']} />
              </FooterLink>
              <FooterText>
                {t('translation.Footer.label-footerText')}
              </FooterText>
              <FooterSocialWrapper>
                <FooterSocialLinkStyled
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'https://www.facebook.com/ZoyyaApp'}
                >
                  <Facebook2 size={'smaller'} />
                </FooterSocialLinkStyled>
                <FooterSocialLinkStyled
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'https://www.instagram.com/zoyyaapp/'}
                >
                  <Instagram size={'smaller'} />
                </FooterSocialLinkStyled>
                <FooterSocialLinkStyled
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'https://www.linkedin.com/company/zoyya'}
                >
                  <Linkedin size={'smaller'} />
                </FooterSocialLinkStyled>
                <FooterSocialLinkStyled
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'https://www.youtube.com/@zoyyaApp'}
                >
                  <Youtube size={'smaller'} />
                </FooterSocialLinkStyled>
                <FooterSocialLinkStyled
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'https://www.tiktok.com/@zoyyaapp'}
                >
                  <TikTok size={'smaller'} />
                </FooterSocialLinkStyled>
              </FooterSocialWrapper>
            </FooterSide>
            <FooterSideRight>
              <FooterColumn>
                <FooterList role="list">
                  <FooterListItem>
                    <a
                      href={process.env.PUBLIC_URL + '/login'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('translation.Footer.label-login')}
                    </a>
                  </FooterListItem>
                  <FooterListItem>
                    <a
                      href={process.env.PUBLIC_URL + '/signup'}
                      rel="noopener noreferrer"
                    >
                      {t('translation.Footer.label-register')}
                    </a>
                  </FooterListItem>
                </FooterList>
              </FooterColumn>
              <FooterColumn>
                <FooterList role="list">
                  <FooterListItem>
                    <a
                      href={`https://zoyya.com/${lang}/partner/frequently-asked-questions`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('translation.Footer.label-faq')}
                    </a>
                  </FooterListItem>
                  <FooterListItem>
                    <a
                      href={`https://zoyya.com/${lang}/partner/about`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('translation.Footer.label-aboutUs')}
                    </a>
                  </FooterListItem>
                </FooterList>
              </FooterColumn>
              <VersionNumber>{`v${process.env.REACT_APP_VERSION_NUMBER}`}</VersionNumber>
            </FooterSideRight>
          </FooterFlex>
        ) : null}

        <FooterCopyright>
          <FooterCopyrightLeft>
            {t('translation.Footer.label-withLoveFromCroatia')}
          </FooterCopyrightLeft>
          <FooterCopyrightRight>
            <a
              href={`https://zoyya.com/${lang}/partner/privacy-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('translation.Footer.label-privacyPolicy')}
            </a>
            <a
              href={`https://zoyya.com/${lang}/partner/cookies-policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('translation.Footer.label-cookies')}
            </a>
            <a
              href={`https://zoyya.com/${lang}/partner/terms-of-use`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('translation.Footer.label-termsOfUse')}
            </a>
          </FooterCopyrightRight>
        </FooterCopyright>
      </FooterWrapper>
    </FooterContainer>
  )
}
export default Footer
