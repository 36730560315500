import { useApolloClient } from '@apollo/client'
import React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useAppServices } from 'sdk/appServices'
import {
  MarketMeDocument,
  useLoginMutation,
  useMarketMeLazyQuery,
} from 'state/graphql'
import { useExternalLoginMutation } from 'state/graphql'
import { browserLogs } from './browserLogs'

const isSignedIn = () => {
  const token = localStorage.getItem('token')

  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('zoyya-is-signedIn='))

  if (!cookie) return !!token
  const cookieValue = cookie.split('=')[1]

  return cookieValue === 'true'
}

export const UserContext = React.createContext<{
  user?: any
  setUser: (user) => any
}>({ setUser: () => {} })

export const useAuth = () => {
  let signedIn = isSignedIn()
  const appServices = useAppServices()
  const [initialUserLoad, setInitialUserLoad] = useState(signedIn)
  const { user, setUser } = useContext(UserContext)
  const apolloClient = useApolloClient()
  const [loginMutation, { loading: loadingLogin }] = useLoginMutation()
  const [loginExternalMutation] = useExternalLoginMutation()

  const [
    getMeQuery,
    { data: userData, loading: loadingUser, refetch, called },
  ] = useMarketMeLazyQuery()

  useEffect(() => {
    if (!signedIn) return
    getMeQuery()
  }, [getMeQuery, signedIn])

  useEffect(() => {
    if (loadingUser || !called) return
    if (userData?.market?.me) {
      setUser(userData?.market?.me)
    }
    setInitialUserLoad(false)
  }, [userData, loadingUser, called, setUser])

  const signOut = useCallback(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    let authDomain = getAuthDomain()
    if (authDomain === '.klijent.zoyya.localhost') {
      authDomain = '.zoyya.localhost'
    }
    document.cookie = `zoyya-is-signedIn=; Path=/; domain=${authDomain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    document.cookie = `zoyya-auth-token=; Path=/;  domain=${authDomain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    setUser(null)
  }, [setUser])
  const signIn = useCallback(
    async (userName: string, password: string) => {
      const result = await loginMutation({ variables: { userName, password } })

      appServices.handleMutationResult(result, '').onSuccess(() => {
        browserLogs.info('Login success', { result })
        setUser(result!.data!.market!.login.user)
      })
      return result?.data?.market?.login?.user
    },
    [loginMutation, appServices, setUser]
  )
  const signInExternal = useCallback(
    async (variables: {
      accessToken: string
      provider: string
      userId: string
    }) => {
      const result = await loginExternalMutation({ variables })

      appServices.handleMutationResult(result, '').onSuccess(() => {
        setUser(result!.data!.market!.loginExternal.user)
      })
      return result?.data?.market?.loginExternal?.user
    },
    [loginExternalMutation, appServices, setUser]
  )

  const refetchUser = useCallback(() => {
    if (!refetch) {
      return new Promise(resolve => {
        apolloClient
          .query({ query: MarketMeDocument, fetchPolicy: 'network-only' })
          .then(result => {
            const user = result.data?.market?.me
            setUser(user)
            resolve(user)
          })
      })
    } else {
      return refetch().then(result => {
        setUser(result.data?.market?.me)
        return result.data?.market?.me
      })
    }
  }, [refetch, apolloClient, setUser])
  let loggedOnUser = user || userData?.market?.me
  return {
    isSignedIn: !!loggedOnUser,
    inProgress: loadingUser || loadingLogin || initialUserLoad,
    user: loggedOnUser,
    signIn,
    signInExternal,
    signOut,
    refetchUser,
    setUser,
  }
}

export const getAuthToken = () => {
  const token = localStorage.getItem('token')

  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('zoyya-auth-token='))
  if (!cookie) return token
  const cookieValue = cookie.split('=')[1]
  return cookieValue
}
const getAuthDomain = () => {
  return process.env.REACT_APP_AUTH_DOMAIN || '.zoyya.com'
}
