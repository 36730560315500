import React from 'react'
import { Flex } from '../Flex/Flex'
import styled from 'styled-components'

const StyledToolbarContainer = styled(Flex.Row)``
const StyledItemsContainer = styled(Flex.Row)`
  > :not(first-child) {
    margin-left: 14px;
  }
`
export const Toolbar = props => {
  return (
    <StyledToolbarContainer>
      <StyledItemsContainer>{props.children}</StyledItemsContainer>
    </StyledToolbarContainer>
  )
}
