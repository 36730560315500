import React from 'react'
import ReactCalendar, { CalendarProps } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import styled from 'styled-components'
import { device } from '../../constants'
import { fontFamily } from 'components/style/fonts'
import moment from 'moment'

export const CalendarContainer = styled.div<{ isRescheduleCalendar?: boolean }>`
  position: sticky;
  //top: 0;
  z-index: 100;
  background: ${props => props.theme.colors.light};
  .react-calendar {
    border: none;
    font-size: 1.2rem;
    width: ${props => (props.isRescheduleCalendar ? 'unset' : undefined)};
    min-width: 820px;
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.light};
    font-family: ${fontFamily};

    @media ${device.tablet} {
      min-width: ${props => (props.isRescheduleCalendar ? '95vw' : '100vw')};
    }
  }

  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    font-family: ${fontFamily} !important;
    font-size: 16px;
    padding: 0.75em 0.5em;
    :hover {
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.light};
      opacity: 0.7;
    }
  }
  .react-calendar__tile:disabled {
    background-color: ${props => props.theme.colors.lightBackground};
    color: ${props => props.theme.colors.primary};
    opacity: 0.6;
  }
  .react-calendar__tile--now {
    background-color: ${props => props.theme.colors.gradientBackground};
    color: ${props => props.theme.colors.dark};
    font-weight: bold;
  }
  .react-calendar__tile--active:enabled:focus {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.light};
  }
  .react-calendar__tile--active {
    background: ${props => props.theme.colors.primary};
    border-radius: 2px;
    color: ${props => props.theme.colors.light} !important;
  }
  .react-calendar__month-view__days__day--weekend {
    color: inherit;
    font-weight: bold;
  }
  .react-calendar__month-view,
  .react-calendar__navigation {
    @media ${device.tablet} {
      margin: 10px;
    }
  }
  abbr[data-original-title],
  abbr[title] {
    text-decoration: none;
    -webkit-text-decoration: none;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    font-size: 1.1rem;
  }
  .react-calendar__navigation button {
    font-size: 25px;
  }
  .unavailable-day {
    background-color: ${props => props.theme.colors.accent6};
    color: ${props => props.theme.colors.light};
    opacity: 0.4;
    color: ${props => props.theme.colors.accent7};

    :hover {
      opacity: 0.6;
      background-color: ${props => props.theme.colors.accent1};
    }
  }
  .react-calendar__tile_unavailable_day--active {
    border-radius: 2px;
    background-color: ${props => props.theme.colors.accent1};
    color: ${props => props.theme.colors.light};

    :hover {
      opacity: 0.6;
      background-color: ${props => props.theme.colors.accent1};
    }
  }
  .react-calendar__tile_unavailable_day--active:enabled:focus {
    background: ${props => props.theme.colors.accent1};
    color: ${props => props.theme.colors.light};
  }
`

export const Calendar = (props: CalendarProps) => {
  return (
    <CalendarContainer isRescheduleCalendar={props.isRescheduleCalendar}>
      <ReactCalendar
        {...props}
        tileClassName={
          props.availableDates?.length
            ? ({ date, view }) => {
                if (
                  moment(date).isSameOrAfter(props.availableDates[0]?.date) &&
                  view === 'month'
                ) {
                  const dateAvailable = props.availableDates?.find(
                    item =>
                      moment(date).format('YYYY-MM-DD') ===
                        moment(item?.date).format('YYYY-MM-DD') &&
                      item?.slotCount > 0
                  )
                  return moment(props.value).format('YYYY-MM-DD') ===
                    moment(date).format('YYYY-MM-DD') && !dateAvailable
                    ? 'react-calendar__tile_unavailable_day--active'
                    : moment(props.value).format('YYYY-MM-DD') ===
                      moment(date).format('YYYY-MM-DD')
                    ? 'selected-day'
                    : !dateAvailable
                    ? 'unavailable-day'
                    : 'selected-day'

                  // if (
                  //   moment(props.value).format('YYYY-MM-DD') ===
                  //     moment(date).format('YYYY-MM-DD') &&
                  //   !dateAvailable
                  // )
                  //   return 'react-calendar__tile_unavailable_day--active'

                  // if (
                  //   moment(props.value).format('YYYY-MM-DD') ===
                  //   moment(date).format('YYYY-MM-DD')
                  // )
                  //   return 'selected-day'
                  // if (!dateAvailable) return 'unavailable-day'
                  // if (!dateAvailable?.slotCount) return 'unavailable-day'
                }
              }
            : 'selected-day'
        }
        locale={(props.locale === 'sr' ? 'sr-ME' : props.locale) || 'hr'}
        showNeighboringMonth={false}
        maxDetail="month"
      />
    </CalendarContainer>
  )
}
