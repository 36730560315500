import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const HouseBold = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M4 14v9c0 .6.4 1 1 1h4.5c.3 0 .5-.2.5-.5v-4c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5v4c0 .3.2.5.5.5H20c.6 0 1-.4 1-1v-8.5M1 13.5L12.5 2 24 13.5"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default HouseBold
