import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  MouseEventHandler,
  useState,
} from 'react'
import { useField } from 'formik'
import { useFormContext } from '../Form'
import styled from 'styled-components'
import { FieldContainer } from '../FieldContainer'

export const CheckboxInput = styled.input<{
  checkboxSize?: 'small' | 'medium' | 'large'
}>`
  position: relative;
  cursor: pointer;
  height: ${props =>
    props.checkboxSize === 'small'
      ? '15px'
      : props.checkboxSize === 'medium'
      ? '30px'
      : '40px'};
  margin-right: ${props =>
    props.checkboxSize === 'small'
      ? '-70px'
      : props.checkboxSize === 'medium'
      ? '5px'
      : '50px'} !important;
  &[type='checkbox'] {
    width: 0px; // hide original blue checkbox
    margin-left: 0px !important;
  }
  &[disabled] {
    &:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
    :checked:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    height: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    top: ${props =>
      props.checkboxSize === 'small'
        ? '-2px'
        : props.checkboxSize === 'medium'
        ? '0px'
        : '0px'};
    left: ${props =>
      props.checkboxSize === 'small'
        ? '6px'
        : props.checkboxSize === 'medium'
        ? '5px'
        : '-1px'};
    background-color: ${props => props.theme.colors.light};
    border: 1px solid ${props => props.theme.colors.outline};
    border-radius: 4px;
  }
  :checked:before {
    content: '';
    display: block;
    position: absolute;
    width: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    height: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    top: ${props =>
      props.checkboxSize === 'small'
        ? '-2px'
        : props.checkboxSize === 'medium'
        ? '0px'
        : '0px'};
    left: ${props =>
      props.checkboxSize === 'small'
        ? '6px'
        : props.checkboxSize === 'medium'
        ? '5px'
        : '-1px'};
    background-color: ${props => props.theme.colors.primary};
  }
  :checked:after {
    content: '';
    display: block;
    width: ${props =>
      props.checkboxSize === 'small'
        ? '6px'
        : props.checkboxSize === 'medium'
        ? '8px'
        : '10px'};
    height: ${props =>
      props.checkboxSize === 'small'
        ? '12px'
        : props.checkboxSize === 'medium'
        ? '17px'
        : '20px'};
    border: solid ${props => props.theme.colors.light};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: ${props =>
      props.checkboxSize === 'small'
        ? '0px'
        : props.checkboxSize === 'medium'
        ? '5px'
        : '7px'};
    left: ${props =>
      props.checkboxSize === 'small'
        ? '12.5px'
        : props.checkboxSize === 'medium'
        ? '16px'
        : '15px'};
  }
`

type TFormFieldCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  checkboxSize: 'small' | 'medium' | 'large'
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  labelAlignment?: string
  style?: any
  parseValue?: (value?: string) => any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  html?: any
  helpText?: string
  helpLink?: string
}

export const FormFieldCheckbox = (props: TFormFieldCheckboxProps) => {
  const { parseValue, helpText, helpLink, checkboxSize, ...rest } = props
  const [field, meta] = useField(props)
  const formContext = useFormContext()
  const [isChecked, setIsChecked] = useState(props.checked)
  const size = checkboxSize ? checkboxSize : 'large'

  const handleChange = useCallback(
    event => {
      setIsChecked(!isChecked)
      formContext?.valueChange(field, event, !isChecked)
    },
    [formContext, field, isChecked]
  )
  return (
    <FieldContainer
      label={props.label}
      labelAlignment={'horizontal'}
      error={meta.error}
      fieldName={field.name}
      type={props.type}
      html={props.html}
      helpText={props.helpText}
      helpLink={props.helpLink}
      style={props.style}
    >
      <CheckboxInput
        {...rest}
        name={props.name}
        checkboxSize={size}
        onChange={handleChange}
        value={field.value || ''}
        checked={props.checked || field.value}
        disabled={props.disabled}
        data-cy={`checkbox_${props.name}`}
      />
    </FieldContainer>
  )
}
