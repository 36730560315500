import styled from 'themes'
import { StyledFieldsContainer } from '../../components/Form/Form'
import { device } from '../../constants'
import { FieldRow } from '../Settings/styles'
import { ButtonStyled } from '../../components/Button/Button'

export const LoginAccountHint = styled.div`
  font-size: 1rem;
  line-height: 1.571rem;
  text-align: left;
  color: ${props => props.theme.colors.secondaryDark};
  margin-top: 1.714rem;
  margin-bottom: 0.571rem;
  cursor: pointer;
`

export const LoginPage = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(72, 47, 180, 0.3) 100%,
    #30364d
  );
  @media ${device.tablet} {
    display: initial;
  }
`

export const LoginForm = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  z-index: 999;
  width: 500px;
  height: auto;

  @media ${device.tablet} {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
    ${FieldRow} {
      flex-direction: row;
    }
  }
`
type TLoginFormHeaderProps = {
  isPartner?: boolean
}
export const LoginFormHeader = styled.a<TLoginFormHeaderProps>`
  height: 270px;
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin: 20px; */
  background: ${props => (props.isPartner ? '#5a47b6' : '#846fdb')};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media ${device.tablet} {
    display: none;
  }
`

export const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 30px;
  z-index: 9999;

  @media ${device.tablet} {
    position: relative;
  }
`

export const LogoColor = styled.img`
  width: 130px;
  margin-right: auto;
`

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 30px;
  ${StyledFieldsContainer} {
    height: auto;
  }

  @media ${device.tablet} {
    display: initial;
    height: 100%;
    padding-bottom: 50px;

    ${FieldRow} {
      ${ButtonStyled} {
        margin-bottom: 14px !important;
      }
    }
  }
`
export const LoginImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  margin: auto;
  width: 100%;

  @media ${device.tablet} {
    display: none;
  }
`

export const LoginTitle = styled.span`
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginHint = styled.span`
  display: flex;
  margin: auto;
  text-align: center;
  color: ${props => props.theme.colors.secondaryDark};
  margin-top: 10px;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const LoginLink = styled.span`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;

  @media ${device.tablet} {
    margin-left: 0;
  }
`

export const LoginPartnerRedirect = styled.span`
  color: ${props => props.theme.colors.primary};
  margin-top: 5px;
  cursor: pointer;
`
