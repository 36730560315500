import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: #ffffff;
`

export const Star = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="#ffffff"
      viewBox="0 0 25 25"
    >
      <path
        fill="#ffffff"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.533 1.571h0a.5.5 0 01.936 0L15 8.747h7.148a.5.5 0 01.325.88L16.5 14.579l2.5 7.51h0a.5.5 0 01-.77.561L12 18.079 5.767 22.65h0A.5.5 0 015 22.089l2.5-7.51-5.974-4.952h0a.5.5 0 01.325-.88H9z"
      ></path>
    </StyledIcon>
  )
}
export default Star
