import React from 'react'

const GermanFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={'22px'}
    >
      <path
        fill="#464655"
        d="M473.655 88.276H38.345C17.167 88.276 0 105.443 0 126.621v73.471h512v-73.471c0-21.178-17.167-38.345-38.345-38.345z"
      ></path>
      <path
        fill="#FFE15A"
        d="M0 385.379c0 21.177 17.167 38.345 38.345 38.345h435.31c21.177 0 38.345-17.167 38.345-38.345v-73.471H0v73.471z"
      ></path>
      <path fill="#FF4B55" d="M0 200.09H512V311.9H0z"></path>
    </svg>
  )
}
export default GermanFlag
