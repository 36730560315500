import styled from 'styled-components'
import { device } from '../../../constants'
import {
  StyledChevronContainer,
  StyledSelectValueContainer,
} from 'components/MobileSelect/style'

export const DesktopLocationsContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0px;
  margin: 0px auto;
  overflow: auto;
  @media ${device.tablet} {
    display: block !important;
    padding: 0px;
  }
`

export const DesktopLocationsGrid = styled.div<any>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  padding: 15px;
  min-height: ${props => (props.isLocations ? 'unset' : '600px')};
  max-width: 1220px;
  margin: auto;
  padding-bottom: 60px;
  width: 100%;
  @media ${device.tablet} {
    padding: 0px 15px 0px 15px;
    padding-bottom: 20px;
    grid-template-columns: repeat(2, 50%);
    column-gap: 0px;
    height: auto;
    display: ${props => (props.isLocations ? 'flex' : 'grid')};
    overflow: auto;
    min-height: 0;
    margin: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const DesktopLocationsGridColumn = styled(DesktopLocationsGrid)`
  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 15px;
  }
`
export const DesktopLocationsItem = styled.div<any>`
  display: flex;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: space-between;
  border-style: none;
  border-width: 1px;
  border-color: rgb(210, 210, 245);
  border-radius: 10px;
  box-shadow: rgb(210 210 245 / 60%) 0px 0px 10px 0px;
  transition-property: transform;
  transition-duration: 400ms;
  transition-timing-function: ease;
  max-height: auto;
  cursor: pointer;
  background: ${props => props.theme.colors.light};

  :hover {
    transform: translate(0px, -5px);
  }

  @media ${device.tablet} {
    margin-right: ${props => (!props.hasMarginRight ? 0 : '15px')};
    min-width: ${props => (props.isLocations ? '70vw' : '0')};
  }
`

export const DesktopLocationsLink = styled.a`
  display: flex;
  height: 100%;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  transition: transform 330ms ease-in-out;
  :active {
    transform: scale(0.93);
  }
`

export const DesktopLocationImage = styled.img<any>`
  object-fit: cover;
  display: flex;
  width: 100%;
  min-height: 220px;
  border-radius: 10px 10px 0px 0px;
  background-position: center;
  max-height: 215px;
  object-fit: ${props =>
    props.src.includes('noImage.svg') ? 'contain' : 'cover'};
  @media ${device.tablet} {
    border-radius: 10px 10px 0px 0px;
    min-height: ${props => (props.isLocations ? '174px' : '90px')};
    height: ${props => (props.isLocations ? '174px' : '90px')};
  }
`

export const DesktopLocationsImageDesc = styled.div`
  display: flex;
  height: auto;
  padding: 20px 20px 20px;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 0%;
  color: rgb(102, 102, 102);
  text-decoration: none;
  width: 100%;

  @media ${device.tablet} {
    padding: 15px 20px 15px;
  }
`

export const DesktopLocationTag = styled.div`
  color: rgb(106, 78, 225);
  font-size: 12px;
  font-weight: 500;
`

export const DesktopLocationCardTitle = styled.p<any>`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  color: ${props => props.theme.colors.text};
  margin-bottom: 10px;
  text-align: ${props => (props.isLocations ? 'left' : 'center')};

  @media ${device.tablet} {
    font-size: 16px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline;
    white-space: nowrap;
  }
`

export const DesktopLocationCardSubtitle = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.text};
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0px 0px 2px;
  font-family: 'DM Sans', serif;

  @media ${device.tablet} {
    margin-bottom: 0px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline;
    white-space: nowrap;
  }
`

export const DesktopLocationLink = styled.div`
  margin-bottom: 10px;
  text-decoration: underline;
  color: rgb(106, 78, 225);
  font-size: 12px;
  font-weight: 500;
  font-family: 'DM Sans', serif;
  text-transform: uppercase;
`
export const CategoriesContainer = styled.div`
  display: flex;
  //margin-bottom: 40px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  /* background: linear-gradient(
    90deg,
    transparent 50%,
    #ffffff9c,
    #ffffff00 90% 100%
  ); */
  //backdrop-filter: blur(5px);
  justify-content: center;
  height: auto;
  width: 100%;
  padding-top: 13px;
  background: rgba(255, 255, 255, 0.5);
  height: 43px;
`

type TCategoryTag = {
  isActive?: boolean
}
export const CategoryTag = styled.a<TCategoryTag>`
  //border-radius: 50px;
  padding: 2px 10px;
  font-size: 15px;
  cursor: pointer;
  :not(last-of-type) {
    margin-right: 20px;
  }
  height: 30px;
  border-bottom: ${props =>
    props.isActive
      ? `2px solid ${props.theme.colors.primary}`
      : '2px solid transparent'};
  :hover {
    border-bottom: 2px solid ${props => props.theme.colors.primary};
  }
  font-weight: 500;
  letter-spacing: 0.1px;
  padding-bottom: 10px;
`
export const FiltersContainer = styled.div`
  width: 100%;
  max-width: 1220px;
  padding: 15px;
  display: flex;
  align-items: center;
  padding-bottom: 0;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: baseline;
  }
`

export const ButtonContainer = styled.div`
  margin-left: auto;
  //align-self: flex-end;
  @media ${device.tablet} {
    width: 100%;
  }
`
export const SectionStickyHeader = styled.div`
  padding-top: 20px;
  width: 100%;
  max-width: 1220px;
  position: relative;
  @media ${device.tablet} {
    position: fixed;
    width: 100%;
    background: ${props => props.theme.colors.primary};
    padding-top: unset;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 16px 0px rgba(16, 25, 40, 0.08);
  }
`

export const EmptyCardShimmer = styled(DesktopLocationsItem)<any>`
  height: 320px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #faf9fd 4%, #f4f4ff 25%, #faf9fd 36%);
  background-size: 400px 100%;

  @media ${device.tablet} {
    width: ${props => (props.isFullWidth ? '100%' : '70vw')};
    height: 252px;
    margin-right: 15px;
  }
  @keyframes shimmer {
    0% {
      background-position: -400px 0;
    }
    100% {
      background-position: 400px 0;
    }
  }
`
export const EmptyTitleShimmer = styled.p`
  height: 48px;
  margin-bottom: 60px;
  width: 400px;
  border-radius: 10px;
  text-align: center;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #faf9fd 4%, #f4f4ff 25%, #faf9fd 36%);
  background-size: 400px 100%;
  margin-left: auto;
  margin-right: auto;

  @keyframes shimmer {
    0% {
      background-position: -400px 0;
    }
    100% {
      background-position: 400px 0;
    }
  }
`

export const LocationCardTitle = styled.div`
  font-size: 16px;
  font-weight: 500;

  @media ${device.tablet} {
    font-size: 16px;
  }
`
export const LocationCardDescription = styled.div`
  color: ${props => props.theme.colors.secondaryDark};
`

export const LoacationSection = styled.div`
  height: 600px;
  @media ${device.tablet} {
    height: 800px;
    height: auto;
  }
`

export const BackgroundImage = styled.div`
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(72, 47, 180, 0.3) 100%,
    #30364d
  );
  height: 600px;
  position: relative;

  .mid-circle {
    position: absolute;
    top: 170px;
    right: 657px;
    width: 50px;
    height: 50px;
    border-radius: 30%;
    background-color: hsla(0, 0%, 100%, 0.61);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }

  .mid-circle-second {
    position: absolute;
    top: 250px;
    right: 100px;
    width: 50px;
    height: 50px;
    border-radius: 30%;
    background-color: hsla(0, 0%, 100%, 0.61);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }

  .big-square {
    position: absolute;
    left: auto;
    top: 18%;
    right: 195px;
    display: block;
    width: 500px;
    height: 500px;
    margin-top: 0;
    margin-right: 0;
    border-radius: 40%;
    background-color: rgba(255, 255, 255, 0.5);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }

  @media ${device.tablet} {
    background-image: linear-gradient(135deg, #6a4ee1, #30364d);
    position: relative;
    height: auto;
  }
`
export const ModalMainContent = styled.div`
  overflow: auto;
  //height: calc(90vh - 165px);
  padding-left: 20px;
  margin-right: 20px;

  height: 100%;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
  @media ${device.tablet} {
    margin-right: 10px;
    padding-right: 10px;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #dbdfe2;
    }
  }
`
export const HeaderImage = styled.div`
  width: 100%;
  height: 80%;
  margin-top: auto;
  padding-top: 0;
  background-image: url(https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5f5e0340104799417c728807_b2c-smaller-min.png);
  background-position: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: block;

  margin-left: auto;

  @media ${device.tablet} {
    margin-top: auto;
    margin-right: 0px;
    height: 43vh;
    background-position: center;
  }
`
export const LocationsGridTitle = styled.div`
  margin: 20px 0;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: #30364d;
  position: relative;
  font-size: 53px;
  font-weight: 700;
  line-height: 60px;
  max-width: 520px;
  letter-spacing: -1px;
  z-index: 999;
  @media ${device.tablet} {
    align-items: center;
    font-size: 30px;
    line-height: 34px;
    padding: 20px 30px;
    text-align: center;
    max-width: 360px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 30px;
    font-weight: 700;
  }
`

export const HeaderText = styled.div`
  margin-bottom: 24px;
  padding: 20px 0 10px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.2px;

  @media ${device.tablet} {
    padding: 10px;
    font-weight: 300;
    letter-spacing: 0.2px;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
    text-align: center;
    color: ${props => props.theme.colors.light};
  }
`

export const Container = styled.div<{ light?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props =>
    props.light ? props.theme.colors.light : '#f8f8ff'};
  min-height: 550px;
  padding-top: 160px;
  padding-bottom: 100px;

  .react-select__control .react-select__indicator {
    color: ${props => props.theme.colors.text};
  }
  .react-select__control .react-select__value-container {
    overflow: visible !important;
  }
  .react-select__control {
    flex-wrap: nowrap !important;
  }
  @media ${device.tablet} {
    min-height: auto;
    padding-top: 40px;
    padding-bottom: 40px;

    ${StyledSelectValueContainer} {
      font-size: 22px;
      line-height: 34px;
      font-weight: 600;
      border: none;
      align-self: flex-start;
      padding: 0px;
    }

    ${StyledChevronContainer} {
      margin-left: 5px;
    }
  }
`

export const SectionTitle = styled.h2`
  margin-top: 0;
  color: #333;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 60px;
  font-family: Poppins, sans-serif;

  span {
    font-weight: 400;
  }

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 34px;
    font-weight: 400;
    margin-bottom: 0px;
    color: ${props => props.theme.colors.light};
  }
`

export const LocationImageSection = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1220px;
  width: 100%;
  height: 600px;
  position: relative;

  @media ${device.tablet} {
    flex-direction: column;
    margin: 0px;
    height: 100%;
    max-height: calc((var(--vh, 1vh) * 100));
  }
`

export const CityTitleHome = styled(SectionTitle)`
  font-size: 18px;
  margin-bottom: 0px;
  margin-left: 36px;
  font-weight: 500;
`
export const CityTitleHomeWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 10px 0;
  /* :hover {
    transform: scale(1.02);
  }
  transition: all 0.2s ease-in; */
  svg {
    position: absolute;
    top: 20px;
    stroke-width: 1.8px;
    //left: 14px;
  }
`

export const ModalBodyContainer = styled.div`
  //max-height: calc((var(--vh, 1vh) * 100 - 35px));
  width: 100%;
  display: flex;
  height: 90vh;
  //height: 90vh;
  //padding: 5px 10px 10px 10px;
  flex-direction: column;
  @media ${device.tablet} {
    //display: inline;
    //overflow: auto;
    //min-height: 200px;
    // max-height: 740px;
    height: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const ModalBodyContainerContent = styled.div`
  max-height: 100%;
  overflow: hidden;
  padding-bottom: 90px;
  padding: 10px;
  width: 100%;
  height: 100%;
  @media ${device.tablet} {
    padding: 10px;
    overflow: auto;
    padding-top: 60px;
  }
`

export const FormFooterContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  padding: 1.429rem 1.429rem;
  background: ${props => props.theme.colors.lightBackground};
  border-top: 1px solid ${props => props.theme.colors.outline};
  min-height: 85px;
  & > * {
    margin: 2px;
  }
  @media ${device.tablet} {
    padding-bottom: 15px;
    padding-right: 15px;
    padding-top: 15px;
    border-top: none;
    background: none;
    border-top: none;
    align-items: center;
    box-shadow: ${props =>
      props.hasShadow ? '0 0 10px rgba(0,0,0,0.1)' : null};
    position: ${props => (props.hasShadow ? 'relative' : null)};
    z-index: ${props => (props.hasShadow ? '9' : null)};
  }
`
export const FormFooterContainerStyled = styled(FormFooterContainer)`
 /*  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  @media ${device.tablet} {
    position: fixed;
  } */
`
export const InputContainer = styled.div`
  padding: 0 15px;
  padding-bottom: 0px;
  :not(last-of-type) {
    margin-right: 20px;
  }
  width: 100%;
  //margin-bottom: 30px;

  input {
    background: transparent;
    color: white;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding-left: 45px;
    ::placeholder {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 300;
      letter-spacing: 0.3px;
    }
  }
  svg {
    stroke-width: 0;
    fill: rgba(255, 255, 255, 0.5);
    top: 13px;
    width: 22px;
    height: 22px;
  }
`
export const StyledHomeInputContainer = styled(InputContainer)`
  padding: 0px;
  padding-bottom: 0px;
  :not(last-of-type) {
    margin-right: 20px;
  }
  width: 500px;
  margin-top: -30px;

  input {
    background: transparent;
    color: #333;
    border: 0.5px solid ${props => props.theme.colors.text};
    border-radius: 10px;

    ::placeholder {
      color: ${props => props.theme.colors.text};
      font-weight: 300;
      letter-spacing: 0.3px;
    }
  }
  svg {
    stroke-width: 0;
    fill: ${props => props.theme.colors.text};
    top: 28px;
    width: 22px;
    height: 22px;
  }
`
export const LogoImg = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  img {
    margin-left: 0 !important;
  }
`
export const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CategoryImage = styled.img`
  height: 55px;
  width: 55px;
`
export const CategoryImageContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`
export const HeaderCategoriesContainer = styled.div<any>`
  max-width: 1100px;
  column-gap: 20px;
  min-height: 144px;
  display: flex;
  overflow: auto;
  padding: 20px 10px;
  &::-webkit-scrollbar {
    display: none;
  }
`
export const CategoryName = styled.div`
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 11.5px;
`
export const CityInputContainer = styled(InputContainer)`
  padding: 0px 10px 0px 15px;
  input {
    border: 0;
    font-size: 12px;
    padding-left: 45px;
    padding-bottom: 15px;

    ::placeholder {
      color: white;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.3px;
    }
  }
  svg {
    stroke-width: 1;

    color: rgb(255, 255, 255);
    top: 10px;
    width: 20px;
    height: 20px;
  }
`
export const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
`

export const CategoryTitleNumber = styled.span`
  font-size: 14px;
  margin-left: 5px;
  font-weight: 400;
  color: ${props => props.theme.colors.secondaryDarker};
`
export const CategoryContainer = styled.div`
  align-self: baseline;
  padding: 20px 15px;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1220px;
  margin: auto;
  cursor: pointer;
`
export const CityListItem = styled.div`
  padding: 8px 0;
  cursor: pointer;
  transition: all 0.15s ease-in;
  //padding-left: 15px;

  &:not(:last-of-type) {
    //border-bottom: 1px solid rgb(240, 240, 240);
  }
  :hover {
    color: ${props => props.theme.colors.primary};
  }
`
export const StyledInputContainer = styled.div`
  /* /margin-bottom: 15px; */
  padding: 15px 20px;
  input {
    border-radius: 10px;
    :focus {
      box-shadow: none;
    }
  }
  @media ${device.tablet} {
    padding: 10px;
    /*  position: fixed;
    width: 100%;
    left: 0;
    padding: 10px;
    top: 0;
    background: white;
    margin-bottom: 0; */
  }
`

export const LocationListItem = styled.section`
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.15s ease-in;
  //padding-left: 15px;
  display: flex;
  align-items: center;
  :not(:last-of-type) {
    border-bottom: 1px solid rgb(240, 240, 240);
  }
  :hover {
    color: ${props => props.theme.colors.primary};
  }
`
export const LocationImageContainer = styled.div`
  height: 60px;
  width: 60px;
  margin-right: 10px;
  @media ${device.tablet} {
    height: 50px;
    width: 50px;
  }
`
export const LocationImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
`
export const LocationInfo = styled.div``
export const CityCardTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  @media ${device.tablet} {
    font-size: 14.5px;
    letter-spacing: 0.2px;
  }
`
export const CityCardDescription = styled.span`
  color: ${props => props.theme.colors.secondaryDarker};
  font-weight: 400;
  font-size: 14px;
  @media ${device.tablet} {
    font-size: 12px;
  }
`

export const EmptyCategoryTitleShimmer = styled(CategoryTitle)`
  height: 32px;
  width: 200px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #faf9fd 4%, #f4f4ff 25%, #faf9fd 36%);
  @media ${device.tablet} {
    width: 100%;
  }
  @keyframes shimmer {
    0% {
      background-position: -300px 0;
    }
    100% {
      background-position: 300px 0;
    }
  }
`
export const RemoveFiltersIconContainer = styled.div`
  position: absolute;
  color: white;
  bottom: 18px;
  right: 20px;
  width: 16px;
  display: flex;
`

export const LocationsTitle = styled.div`
  white-space: break-spaces;
`

export const BackButton = styled.div`
  position: absolute;
  top: 18px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
    color: ${props => props.theme.colors.primary};
    transform: translateX(-2px);
  }
`
