import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};

  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const ChatHelp = props => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M9 12c.1 0 .2.1.2.2s-.1.3-.2.3-.2-.1-.2-.2c-.1-.2.1-.3.2-.3M17 18.5c.1 0 .2.1.2.2s-.1.3-.2.3-.2-.1-.2-.2c0-.2.1-.3.2-.3M17 16.5v-2"
        fill="none"
      ></path>
      <path
        d="M8.5 19.2l-6.6 3.1 2.6-5.2C2.3 15.5 1.1 13 1 10.4 1 5.2 6.1 1 12.5 1S24 5.2 24 10.4"
        fill="none"
      ></path>
      <path
        d="M10.2 16.9c0 3.1 3.1 5.6 6.9 5.6.8 0 1.6-.1 2.4-.4l4 1.8-1.6-3.1c1.3-1 2-2.4 2.1-4 0-3.1-3.1-5.6-6.9-5.6s-6.9 2.6-6.9 5.7zM7 6.5c0-1.1.9-2 2-2s2 .9 2 2c0 .9-.6 1.6-1.4 1.9-.4.1-.6.5-.6.9v.7"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default ChatHelp
