import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const Briefcase = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M2 16l.7 4.8c.1 1 1 1.7 2 1.7h15.6c1 0 1.8-.7 2-1.7L23 16c.6 0 1-.4 1-1V8.5c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2V15c0 .6.4 1 1 1zM17 6.5H8v-1c0-1.7 1.3-3 3-3h3c1.7 0 3 1.3 3 3v1zM2 16h8.5M14.5 16H23"
        fill="none"
      />
      <path
        d="M13.9 14.2c.8 1 .8 2.6 0 3.5s-2 1-2.8 0c-.8-1-.8-2.6 0-3.5s2-.9 2.8 0"
        fill="none"
      />
    </StyledIcon>
  )
}

export default Briefcase
