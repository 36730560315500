import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
`

export const Linkedin = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="inherit"
      viewBox="0 0 24 24"
    >
      <path
        fill="inherit"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.5 22.5h-5v-13h5zm9-9h0a2 2 0 00-2 2s0 0 0 0v7h-5v-13h5v1.485h0a6.307 6.307 0 013.99-1.495c2.962 0 5.01 2.2 5.01 6.355V22.5h-5v-7 0a2 2 0 00-2-2zM6.5 5v0A2.5 2.5 0 114 2.5h0A2.5 2.5 0 016.5 5s0 0 0 0z"
      ></path>
    </StyledIcon>
  )
}
export default Linkedin
