import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const PDF = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.5 23.5h0a1 1 0 001-1V6.473h0A5.975 5.975 0 0015.525.5H3.5h0a1 1 0 00-1 1s0 0 0 0v21h0a1 1 0 001 1z"></path>
        <path d="M16.5.579V4.5h0a1 1 0 001 1h3.92M8.479 15v0a1.5 1.5 0 01-1.5 1.5h-1.5v-3h1.5a1.5 1.5 0 011.5 1.5zM5.48 19.5v-3M10.479 13.5h0a3 3 0 010 6zM15.479 19.5v-5h0a1 1 0 011-1h2M15.48 16.5h2"></path>
      </g>
    </StyledIcon>
  )
}
export default PDF
