import React, { useContext } from 'react'
import { animated, useSpring } from 'react-spring'
import { MobileContext } from '../../App'
import styled from 'styled-components'
import { device } from '../../constants'

export const Container = styled.div`
  @media ${device.tablet} {
    padding: 10px 10px 0px 10px;
  }
`

export const AnimatedDiv = props => {
  const { children, handlers, style } = props
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 700 },
  })

  const isMobile = useContext(MobileContext)

  return (
    <animated.div
      style={{
        ...animationProps,
        overflow: isMobile ? 'auto' : 'hidden',
        width: '100%',
        height: '100%',
        ...style,
      }}
      handlers={handlers}
    >
      {children}
    </animated.div>
  )
}
