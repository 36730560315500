import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Google = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M12.5 24C6.1 24 1 18.9 1 12.5S6.1 1 12.5 1c3.2 0 6.2 1.3 8.4 3.6L18 7.4C16.6 5.8 14.6 5 12.5 5 8.4 5 5 8.4 5 12.5S8.4 20 12.5 20c3.2 0 6-2 7.1-5H14v-4h10v2.1C23.7 19.2 18.6 24 12.5 24zM6 8.7L2.8 6.2M6.1 16.3l-3 2.7M17 18.5l2.7 3"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Google
