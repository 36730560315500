import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};

  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const LogoutIcon = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M14 5.5V2c0-.6-.4-1-1-1H2c-.6 0-1 .4-1 1v21c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-3.5M24 12.5H5M19 17.5l5-5-5-5"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
LogoutIcon.displayName = 'LogoutIcon'

export default LogoutIcon
