import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import {
  StyledSelectContainer,
  StyledSelectValueContainer,
  StyledOptionContainer,
  StyledDropDownContainer,
  StyleDropDownFilterPanel,
  StyledMenuListContainer,
  StyleDropDownOverlay,
  StyledFilterInput,
  StyledSelectValueInput,
  StyledSelectValue,
  StyledSelectValueInputContainer,
  StyledOptionValue,
  StyledChevronContainer,
  StyledDropDownContent,
  EmptyElement,
} from './style'
import { FlexColumn } from 'components'
import { useInView } from 'react-intersection-observer'
import { debounce } from 'lodash'
import { some } from 'lodash'
import { Icons } from 'components'
const portalTarget = document.getElementById(
  //"mobile-select-portal-target"
  'body'
) as any

const SHOW_FILTER_INPUT_IF_MIN_OPTIONS = 10

export const MobileSelect = props => {
  const {
    options,
    onChange,
    isMulti,
    value,
    components,
    getOptionLabel,
    formatOptionLabel,
    isAsync,
    loadOptions,
    controlShouldRenderValue,
    isSearchable,
    isDisabled,
    placeholder,
    name,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>(value)
  const itemsNode = useRef()
  const handleOpenSelect = useCallback(
    e => {
      if (isDisabled) return
      setIsOpen(true)
    },
    [isDisabled]
  )

  const currentRef = itemsNode?.current
  useEffect(() => {
    if (isOpen && currentRef) {
      scrollToItem(
        itemsNode?.current,
        document.getElementById('dropdownTime-' + value?.id)
      )
    }
  }, [isOpen, currentRef, value])

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  const handleCloseDropDown = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      setIsOpen(false)
    },
    [setIsOpen]
  )

  const OptionPresenter = components?.MobileOption

  const renderMenuItem = useCallback(
    opt => {
      if (!opt) return null
      return OptionPresenter ? (
        <OptionPresenter
          selectProps={{ value: selectedOption }}
          data={opt}
        ></OptionPresenter>
      ) : formatOptionLabel ? (
        formatOptionLabel(opt)
      ) : (
        opt.name || opt.title
      )
    },
    [OptionPresenter, formatOptionLabel, selectedOption]
  )

  const handleOptionSelected = option => {
    const newSelectionAdd = isMulti
      ? [...(selectedOption ?? []), option]
      : option
    const newSelectionRemove = isMulti
      ? selectedOption?.filter(x => x.id !== option.id)
      : undefined

    if ((value && some(value, { id: option.id })) || option.isSelected) {
      setSelectedOption(newSelectionRemove)
      onChange?.(newSelectionRemove)
    } else {
      setSelectedOption(newSelectionAdd)
      onChange?.(newSelectionAdd)
    }

    //if (!isMulti) {
    setIsOpen(false)
    //}
  }

  const scrollToItem = (parent, child) => {
    if (!parent || !child) return
    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect()
    // What can you see?
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    }

    // Where is the child
    const childRect = child.getBoundingClientRect()
    // Is the child viewable?
    const isViewable =
      childRect.top >= parentRect.top &&
      childRect.top <= parentRect.top + parentViewableArea.height

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // scroll by offset relative to parent
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top
    }
  }

  return (
    <StyledSelectContainer
      onClick={handleOpenSelect}
      tabIndex={0}
      className={isDisabled ? 'is-disabled' : ''}
      data-cy={`mobileSelect_${name}`}
    >
      <StyledSelectValueContainer>
        <StyledSelectValue>
          {controlShouldRenderValue ? (
            isMulti ? (
              selectedOption?.map((option, idx) => (
                <div data-cy={'mobileSelect_value'}>
                  {option.name + (idx < selectedOption?.length - 1 ? ', ' : '')}
                </div>
              ))
            ) : selectedOption ? (
              <div data-cy={'mobileSelect_value'}>
                {formatOptionLabel(selectedOption)}
              </div>
            ) : (
              placeholder
            )
          ) : (
            placeholder
          )}
        </StyledSelectValue>
        <StyledSelectValueInputContainer>
          <StyledSelectValueInput
            name="mobileSelect_input"
            disabled={isDisabled}
            onFocus={e => {
              e.currentTarget.blur()
              setIsOpen(true)
              scrollToItem(
                itemsNode,
                document.getElementById('dropdownTime-' + value?.id)
              )
            }}
          />
        </StyledSelectValueInputContainer>
        <StyledChevronContainer>
          <Icons.ChevDown
            color={'grayDark'}
            isChevron
            style={{ width: 10, height: 10 }}
            stroke={5}
          />
        </StyledChevronContainer>
      </StyledSelectValueContainer>
      {isOpen ? (
        <SelectDropDown
          formatOptionLabel={renderMenuItem}
          getOptionLabel={getOptionLabel}
          onSelected={handleOptionSelected}
          options={options}
          closeDropDown={handleCloseDropDown}
          isMulti={isMulti}
          value={value}
          components={components}
          isAsync={isAsync}
          loadOptions={loadOptions}
          isSearchable={isSearchable}
          ref={itemsNode}
        />
      ) : null}
    </StyledSelectContainer>
  )
}

MobileSelect.defaultProps = {
  isSearchable: true,
}

const SelectDropDown = React.forwardRef((props: any, ref: any) => {
  const {
    closeDropDown,
    options,
    onSelected,
    formatOptionLabel,
    getOptionLabel,
    isMulti,
    isAsync,
    loadOptions,
    components,
    isSearchable,
  } = props
  const [currentPage, setCurrentPage] = useState(0)
  const [filterText, setFilterText] = useState('')
  const [renderOptions, setRenderOptions] = useState<any[]>(options || [])
  const [lastFetchData, setLastFetchData] = useState<any>()
  const containerRef = useRef<any>()
  const [lastMenuItemRef, lastMenuItemInView] = useInView({
    root: containerRef.current,
  })

  const asyncLoadData = useCallback(
    async (text, page) => {
      if (!isAsync || !loadOptions) return
      const data = await loadOptions(text, [], { page })
      setRenderOptions(loadedOptions => {
        return page === 0 ? data?.options : [...loadedOptions, ...data?.options]
      })
      setLastFetchData(data)
    },
    [isAsync, loadOptions, setRenderOptions]
  )
  useEffect(() => {
    if (!isAsync || !lastMenuItemInView || !lastFetchData?.hasMore) return
    setCurrentPage(page => page + 1)
  }, [lastMenuItemInView, lastFetchData, isAsync])

  useEffect(() => {
    if (!isAsync || currentPage === 0) return
    asyncLoadData(filterText, currentPage)
  }, [isAsync, currentPage, asyncLoadData, filterText])
  useEffect(() => {
    setCurrentPage(0)
    setFilterText('')
    asyncLoadData('', 0)
  }, [asyncLoadData])

  const debouncedFiltering = useMemo(
    () =>
      debounce((text: any) => {
        if (isAsync) {
          setCurrentPage(0)
          asyncLoadData(text, 0)
        } else {
          const filteredOptions = filterOptionsByText(
            options,
            text,
            getOptionLabel
          )
          setRenderOptions(filteredOptions)
        }
      }, 200),
    [asyncLoadData, getOptionLabel, isAsync, options]
  )

  useEffect(() => {
    debouncedFiltering(filterText)
  }, [filterText, debouncedFiltering])

  const handleSelectOption = useCallback(
    (e, option) => {
      e.stopPropagation()
      onSelected(option)
    },
    [onSelected]
  )

  const renderOption = (option, index) => {
    const groupOptions = option.options

    return (
      <StyledOptionContainer
        key={option.id || option.id === 0 ? option.id : index}
      >
        {groupOptions ? (
          <GroupOptionPresenter
            group={option}
            formatOptionLabel={formatOptionLabel}
            isMulti={isMulti}
            onOptionClick={handleSelectOption}
          />
        ) : (
          <ValueOptionPresenter
            id={'dropdownTime-' + option.id}
            option={option}
            onClick={e => handleSelectOption(e, option)}
            optionPresenter={components?.Option}
            formatOptionLabel={formatOptionLabel}
            isMulti={isMulti}
          />
        )}
      </StyledOptionContainer>
    )
  }
  const MenuList = components.MobileMenuList || StyledMenuListContainer
  useOnClickOutside(containerRef, closeDropDown)
  return ReactDOM.createPortal(
    <StyleDropDownOverlay>
      <StyledDropDownContainer ref={containerRef} isSearchable={isSearchable}>
        <EmptyElement onClick={closeDropDown} />
        <StyledDropDownContent>
          {isAsync ||
          isMulti ||
          (isSearchable &&
            options?.length >= SHOW_FILTER_INPUT_IF_MIN_OPTIONS) ? (
            <StyleDropDownFilterPanel>
              <StyledFilterInput
                name="mobileSelect_filter"
                onChange={e => setFilterText(e.currentTarget.value)}
                value={filterText}
                icon={<Icons.Search style={{ strokeWidth: 0, top: 12 }} />}
              />
            </StyleDropDownFilterPanel>
          ) : null}
          <MenuList ref={ref} id={'dropdownTime'}>
            {renderOptions?.map(renderOption)}
            {isAsync && renderOptions?.length ? (
              <div
                ref={lastMenuItemRef}
                style={{ height: '1px', width: '1px' }}
              >
                &nbsp;
              </div>
            ) : null}
          </MenuList>
        </StyledDropDownContent>
      </StyledDropDownContainer>
    </StyleDropDownOverlay>,
    portalTarget
  )
})
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

const GroupOptionPresenter = props => {
  const { onOptionClick, group, formatOptionLabel, isMulti } = props
  const { options } = group
  return (
    <FlexColumn>
      <div style={{ fontWeight: 'bold' }}>
        {group.label || group.title || group.name}
      </div>
      {options.map((o, index) => (
        <ValueOptionPresenter
          option={o}
          key={o?.id || index}
          formatOptionLabel={formatOptionLabel}
          isMulti={isMulti}
          onClick={e => onOptionClick(e, o)}
        />
      ))}
    </FlexColumn>
  )
}

const ValueOptionPresenter = props => {
  const { onClick, option, isMulti, isSelected, formatOptionLabel, id } = props
  return (
    <StyledOptionValue
      onClick={onClick}
      id={id}
      data-cy={`mobileSelect_option-${option.name ||
        option.title ||
        option.countryName}`}
      data-name={'mobileSelect_option'}
    >
      {isMulti && isSelected ? <div>X</div> : null}
      {formatOptionLabel(option)}
    </StyledOptionValue>
  )
}

const isOptionVisible = (option, text, getOptionLabel) => {
  const isGroup = !!option.options

  if (isGroup) {
    return !!option.options.find(e => isOptionVisible(e, text, getOptionLabel))
  } else {
    return getOptionLabel(option)
      ?.toLowerCase()
      .includes(text)
  }
}
const filterOptionsByText = (options, text, getOptionLabel) => {
  const normalizedText = text?.toLowerCase()
  return options
    ?.filter(o => isOptionVisible(o, normalizedText, getOptionLabel))
    .map(o => {
      return {
        ...o,
        options: o?.options?.filter(o =>
          isOptionVisible(o, normalizedText, getOptionLabel)
        ),
      }
    })
}
