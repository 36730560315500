import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Intercom = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path d="M16.5 22h1c1.9 0 3.5-1.1 3.5-3v-2.2" fill="none"></path>
      <path
        d="M20 9c2.2 0 4 1.8 4 4 0 1.7-1.1 3.2-2.7 3.8-.5.2-1.1-.1-1.3-.6 0-.1-.1-.2-.1-.3L20 9zM5 9c-2.2 0-4 1.8-4 4 0 1.7 1.1 3.2 2.7 3.8.5.2 1.1-.1 1.3-.6V9z"
        fill="none"
      ></path>
      <path
        d="M20 10.5v-2C20 4.4 16.6 1 12.5 1S5 4.4 5 8.5v2M12 21h4c.3 0 .5.2.5.5v1c0 .3-.2.5-.5.5h-4c-.6 0-1-.4-1-1s.4-1 1-1zM15.7 15.2c-1.8 1.8-4.7 1.8-6.4 0"
        fill="none"
      ></path>
      <path
        d="M9.2 10.2c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2.1-.2.2-.2M15.8 10.2c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2c-.1-.1 0-.2.2-.2"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Intercom
