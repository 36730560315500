import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Book = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M4.9 1.4l9 3.6c.4.2.6.5.6.9v16.8c0 .6-.4 1-1 1-.1 0-.3 0-.4-.1l-8-3.2c-.4-.2-.6-.5-.6-.9V2.2c0-.6.4-1 1-1h14c.6 0 1 .4 1 1v17c0 .6-.4 1-1 1h-2"
        fill="none"
      ></path>
      <path
        d="M5.1 1.3l11.6 2.2c.5.1.8.5.8 1v16.7c0 .6-.4 1-1 1h-2"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Book
