import React, { CSSProperties, useState } from 'react'
import styled from 'styled-components'
import Text from '../Text/Text'
import { red, grayDark } from '../style/colors'
import { Icons } from 'components'
import ToolTip from 'components/Tooltip/Tooltip'
import { Tooltip2 } from '../Tooltip2/Tooltip2'

type TProps = {
  label?: string
  fieldName?: string
  children?: any
  error?: any
  style?: any
  labelStyle?: CSSProperties
  name?: string
  labelAlignment: 'vertical' | 'horizontal'
  type?: any
  icon?: any
  helpText?: any
  helpLink?: any
  html?: any
}

const InputIcon = styled.div<any>`
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 10px;
`

export const HelpIcon = styled.div<any>`
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #98a9bc;
`

export const FieldContainer = (props: TProps) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div
      style={Object.assign(
        {
          display: 'flex',
          flex: 1,
          flexDirection:
            props.labelAlignment === 'horizontal' ? 'row-reverse' : 'column',
          justifyContent:
            props.labelAlignment === 'horizontal' ? 'flex-end' : 'initial',
          alignItems:
            props.labelAlignment === 'horizontal' ? 'center' : 'stretch',
          width: props.labelAlignment === 'horizontal' ? '100%' : 'initial',
        },
        props.style || {}
      )}
    >
      <div
        dangerouslySetInnerHTML={
          props.html ? { __html: props.html } : undefined
        }
      >
        {props.label ? (
          <Text
            style={{
              marginBottom: props.type === 'checkbox' ? '0px' : '5px',
              display: 'flex',
              marginRight: props.labelAlignment === 'horizontal' ? '16px' : '0',
            }}
            type="label"
          >
            <span
              style={{
                marginRight: props.type === 'checkbox' ? '20px' : 'initial',
              }}
            >
              {props.label}
            </span>
            {props.helpText ? (
              <Tooltip2 {...props}>
                <HelpIcon>
                  <Icons.Info size={'smaller'} />
                </HelpIcon>
              </Tooltip2>
            ) : null}
          </Text>
        ) : null}
      </div>
      <div
        style={{
          flexGrow: props.labelAlignment === 'horizontal' ? 0 : 1,
          marginRight: props.labelAlignment === 'horizontal' ? '40px' : '0',
          position: 'relative',
          display: props.labelAlignment === 'horizontal' ? 'flex' : 'initial',
          alignItems:
            props.labelAlignment === 'horizontal' ? 'center' : 'initial',
        }}
      >
        {props.icon && (
          <div
            style={{
              position: 'absolute',
              left: '10px',
              zIndex: 1,
              top: '10px',
            }}
          >
            {props.icon}
          </div>
        )}
        {React.cloneElement(props.children, {
          hasError: props.error,
          hasIcon: !!props.icon,
          type: props.type === 'password' && showPassword ? 'text' : props.type,
        })}
        {props.type === 'password' && (
          <InputIcon>
            {showPassword ? (
              <Icons.Eye
                color={grayDark}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <Icons.EyeDisabled
                color={grayDark}
                onClick={() => setShowPassword(true)}
              />
            )}
          </InputIcon>
        )}
        {props.error && !props.html && props.type !== 'password' ? (
          <HelpTextWrapper>
            {' '}
            <ToolTip label={props.error}>
              <Icons.Warning
                size="smaller"
                data-cy={`valerr_${props.fieldName}`}
                data-valerr={props.error}
              />
            </ToolTip>
          </HelpTextWrapper>
        ) : null}
      </div>
    </div>
  )
}
export const HelpTextWrapper = styled.div`
  display: flex;
  left: calc(100% - 56px);
  top: calc(50% - 8px);
  position: absolute;
  z-index: 100;
  align-items: center;
  justify-content: center;
  color: ${red};
  min-width: 28px;
  width: 28px;
`

FieldContainer.defaultProps = {
  width: '100%',
  labelAlignment: 'vertical',
}
