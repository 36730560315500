import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const DashboardIcon = props => {
  return (
    <StyledIcon {...props} width="26" height="26" viewBox="0 0 26 26">
      <path
        d="M1.5 23.5c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1h8c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1h-8z"
        className="st0"
      ></path>
      <path
        d="M-14.5-23.5c.6 0 1 .4 1 1v11c0 .6-.4 1-1 1h-8c-.6 0-1-.4-1-1v-11c0-.6.4-1 1-1h8z"
        className="st0"
        transform="translate(37 34)"
      ></path>
      <path
        d="M-14.5-7.5c.6 0 1 .4 1 1v5c0 .6-.4 1-1 1h-8c-.6 0-1-.4-1-1v-5c0-.6.4-1 1-1h8z"
        className="st0"
        transform="translate(37 8)"
      ></path>
      <path
        d="M1.5 13.5c-.6 0-1-.4-1-1v-11c0-.6.4-1 1-1h8c.6 0 1 .4 1 1v11c0 .6-.4 1-1 1h-8z"
        className="st0"
      ></path>
    </StyledIcon>
  )
}
DashboardIcon.displayName = 'DashboardIcon'

export default DashboardIcon
