import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  MouseEventHandler,
} from 'react'
import { useField } from 'formik'
import { useFormContext } from '../Form'
import styled from 'styled-components'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../../style/fonts'
import { FieldContainer } from '../FieldContainer'
import {
  red,
  blue,
  grayLight,
  grayDarker,
  redLighter,
  boxShadow,
} from '../../style/colors'
type TProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  help?: string
  rows?: number
  helpText?: string
  helpLink?: string
}

export const FormFieldTextArea = (props: TProps) => {
  const [field, meta] = useField(props)
  const formContext = useFormContext()
  const handleChange = useCallback(
    event => {
      const value = event?.target.value
      formContext?.valueChange(field, event, value)
    },
    [formContext, field]
  )
  return (
    <FieldContainer
      label={props.label}
      error={meta.error}
      fieldName={field.name}
      type={props.type}
      helpText={props.helpText}
      helpLink={props.helpLink}
    >
      <StyledTextArea
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
        data-cy={`input_${props.name}`}
        value={field.value || ''}
        onChange={handleChange}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        style={{userSelect: "none"}}
      ></StyledTextArea>
    </FieldContainer>
  )
}

export const StyledTextArea = styled.textarea<any>`
  border-radius: 4px;
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
  box-sizing: border-box;
  resize: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
  padding: 8px;
  color: ${({ hasError }) => (hasError ? red : grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  width: 100%;
  :focus {
    border: 1px solid ${({ hasError }) => (hasError ? red : blue)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) => (hasError ? redLighter : boxShadow)};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }
`
FormFieldTextArea.defaultProps = {
  autoComplete: 'off',
  spellCheck: false,
  autoCorrect: 'off',
  autoCapitalize: 'off',
}
