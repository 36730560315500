import React, { useState } from 'react'
import { slide as SlderMenu } from 'react-burger-menu'
import { Icons } from 'components'
import {
  NavigationTitle,
  NavigationButton,
  SidebarMenuItem,
  SidebarRow,
} from 'styles'
import styled from 'styled-components'
import Button from 'components/Button/Button'

export const Container = styled.div<{ isDark?: boolean; ref?: any }>`
  .bm-burger-button {
    position: ${props => (!props.isDark ? 'absolute' : 'sticky')};
    margin-right: ${props => (!props.isDark ? '0px' : '15px')};
    width: 35px;
    height: 35px;
    right: 15px;
    top: ${props => (!props.isDark ? '10px' : '15px')};
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    width: 50px !important;
    height: 50px !important;
    right: 0px !important;
    top: 0px !important;
    padding: 15px;
  }

  /*
Sidebar wrapper styles
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 55px;
    width: 100vw !important;
    max-height: calc((var(--vh, 1vh) * 100 - 55px));
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${props => props.theme.colors.light};

    font-size: 1.15em;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 55px;
    left: 0px;
  }

  #burger-icon {
    width: 60px;
    height: 45px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #burger-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: #ffffff;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #burger-icon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  #burger-icon span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
  }

  #burger-icon span:nth-child(1),
  #burger-icon span:nth-child(2) {
    top: 5px;
  }

  #burger-icon span:nth-child(3),
  #burger-icon span:nth-child(4) {
    top: 15px;
  }

  #burger-icon span:nth-child(5),
  #burger-icon span:nth-child(6) {
    top: 25px;
  }

  #burger-icon.open span:nth-child(1),
  #burger-icon.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #burger-icon.open span:nth-child(2),
  #burger-icon.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #burger-icon.open span:nth-child(1) {
    left: 0px;
    top: 7px;
  }

  #burger-icon.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  #burger-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #burger-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #burger-icon.open span:nth-child(5) {
    left: 0px;
    top: 20px;
  }

  #burger-icon.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 20px;
  }
`
type TIconWrapper = {
  iconBackgroundColor?: string
  iconColor?: string
}
const IconWrapper = styled.div<TIconWrapper>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.iconBackgroundColor ? props.iconBackgroundColor : null};
  border-radius: 10px;
  svg {
    color: ${props => (props.iconColor ? props.iconColor : null)};
  }
`
export const Menu = React.forwardRef((props: any, ref: any) => {
  const { children, isOpen, handleMenuOpenChange, isDark } = props
  return (
    <Container isDark={isDark}>
      <SlderMenu
        right
        isOpen={isOpen}
        onStateChange={handleMenuOpenChange}
        customBurgerIcon={
          <div id="burger-icon" className={isOpen ? 'open' : ''}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        }
      >
        <div ref={ref}>{children}</div>
      </SlderMenu>
    </Container>
  )
})
type TMenuItemProps = {
  label?: string
  name?: string
  cy?: string
  icon?: any
  onClick: () => any
  isInstallButton?: boolean
  isMenu?: boolean
  childItems?: any
  isButton?: boolean
  isPrimaryButton?: boolean
  iconUrl?: string
  iconColor?: string
  iconBackgroundColor?: string
}
export const MenuItem = (props: TMenuItemProps) => {
  const {
    label,
    name,
    cy,
    icon,
    onClick,
    isInstallButton,
    isMenu,
    childItems,
    isButton,
    isPrimaryButton,
    iconUrl,
    iconColor,
    iconBackgroundColor,
  } = props
  const [showChildItems, setShowChildItems] = useState(false)

  return isInstallButton ? (
    <InstallWrapper onClick={onClick}>
      <SidebarRow style={{ flex: 1 }}>
        <InstallTitle id={name} data-cy={cy}>
          {label}
        </InstallTitle>
      </SidebarRow>
      <Button label={'Preuzmi'} buttonType={'secondary'} size={'small'} />
    </InstallWrapper>
  ) : (
    <NavigationButton
      isMenu={isMenu}
      isButton={isButton}
      isPrimaryButton={isPrimaryButton}
      onClick={() =>
        childItems ? setShowChildItems(!showChildItems) : onClick()
      }
    >
      <SidebarRow style={{ flex: 1 }}>
        <SidebarMenuItem
          isOpen={showChildItems}
          isButton={isButton}
          isPrimaryButton={isPrimaryButton}
        >
          {iconUrl ? (
            <img src={iconUrl} alt="mobile-menu-icon" style={{ width: 22 }} />
          ) : (
            <IconWrapper
              iconBackgroundColor={iconBackgroundColor}
              iconColor={iconColor}
            >
              {icon}
            </IconWrapper>
          )}
          <NavigationTitle
            id={name}
            data-cy={cy}
            isMenu
            isButton={isButton}
            isPrimaryButton={isPrimaryButton}
            onClick={() => (childItems ? false : onClick())}
          >
            {label}
          </NavigationTitle>
          {showChildItems && childItems ? (
            <Icons.ChevDown
              stroke={4}
              size={'extraSmall'}
              style={{ color: '#333', marginLeft: 'auto' }}
            />
          ) : childItems ? (
            <Icons.ChevronRight
              stroke={4}
              size={'extraSmall'}
              style={{ color: '#333', marginLeft: 'auto' }}
            />
          ) : null}
        </SidebarMenuItem>

        {showChildItems &&
          childItems.map(item => (
            <NavigationTitle
              key={item?.id}
              style={{ minHeight: 50 }}
              id={item.id}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                item.onClick
                  ? item.onClick()
                  : window.open(item.linkUrl, '_self')
              }}
            >
              {item.title}
            </NavigationTitle>
          ))}
      </SidebarRow>
    </NavigationButton>
  )
}

export const InstallWrapper = styled(NavigationButton)`
  background: ${props => props.theme.colors.primary};
  color: white;
  margin-left: -20px;
  margin-right: -20px;
  padding: 40px;
`

export const InstallTitle = styled.span`
  color: ${props => props.theme.colors.light};
`
