import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const OnlineBooking = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M19 8.8V22c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V8.8M19 5H6M19 20H6"
        fill="none"
      ></path>
      <path
        d="M12.5 21.8c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2c-.1-.1.1-.2.2-.2M19.3 1.6c-.2-.4-.5-.6-.9-.6H6.6c-.4 0-.7.2-.9.6L4 5h17l-1.7-3.4zM12.5 5H4v1c-.1 1.6 1.2 2.9 2.8 3 .8 0 1.6-.4 2.2-1 .8 1.2 2.1 2 3.5 2s2.7-.8 3.5-2c1.1 1.2 2.9 1.3 4 .2.6-.6 1-1.4 1-2.2V5h-8.5z"
        fill="none"
      ></path>
      <path
        d="M9 8V5l1-4M16 8V5l-1-4M15.2 14c.3 0 .5.2.5.5v.2l-1.1 3c-.1.2-.3.3-.5.3h-3.6c-.2 0-.4-.1-.5-.3l-1.1-3c-.1-.3 0-.5.3-.7h6zM10 14l1-1.5M15 14l-1-1.5"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default OnlineBooking
