import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

export const UserMan = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3 22.75h0a9 9 0 0118 0s0 0 0 0zM15.89 2.86a5.5 5.5 0 11-7.78 7.78 5.5 5.5 0 017.78-7.78"></path>
        <path d="M7.261 3.958h0a9.124 9.124 0 006.572 2.792h0a9.138 9.138 0 003.617-.744"></path>
      </g>
    </StyledIcon>
  )
}
export default UserMan
