import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Login = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.5 5V1.5v0a1 1 0 00-1-1h-11 0a1 1 0 00-1 1s0 0 0 0v21h0a1 1 0 001 1h11a1 1 0 001-1V19M4.5 12h19"></path>
        <path d="M9.5 7l-5 5 5 5"></path>
      </g>
    </StyledIcon>
  )
}
export default Login
