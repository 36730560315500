import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Billing = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.504 22.5a2 2 0 01-2-2v-10a2 2 0 012-2h15a2 2 0 012 2v10a2 2 0 01-2 2z"></path>
        <path d="M3.004 13.5a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM2.5 16.5h5M2.5 18.5h4M15.5 16h0a1.991 1.991 0 00-1.5.678h0a2 2 0 100 2.644h0A2 2 0 1015.5 16z"></path>
        <path d="M12.5 16h0a2 2 0 011.955 1.576M21.5 14.5h0a2 2 0 002-2v-9 0a2 2 0 00-2-2h-15 0a2 2 0 00-2 2s0 0 0 0v3M4.5 5.5h19"></path>
      </g>
    </StyledIcon>
  )
}
export default Billing
