import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAuth } from './useAuth'
type TProps = {
  path: string
  children: any
  referrer?: string
  exact?: boolean
}

export const PrivateRoute = (props: TProps) => {
  const { path, children } = props
  const location = useLocation()
  const { isSignedIn, inProgress } = useAuth()

  if (inProgress) return null
  if (
    !isSignedIn &&
    !location.pathname.startsWith('/signup') &&
    location.pathname !== '/register' &&
    location.pathname !== '/invite'
  ) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { referrer: location.pathname } }}
      />
    )
  }

  return <Route path={path}>{children}</Route>
}
