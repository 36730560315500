import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Light = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15 17.239v2.716c0 1.426-1.2 2-3 2s-3-.574-3-2v-2.716"></path>
        <path d="M13 17.455v-6h0a1 1 0 111 1h-4 0a1 1 0 110-2h0a.951.951 0 011 1v6"></path>
        <path d="M14.5 17.455h0a6.336 6.336 0 004-6h0a6.619 6.619 0 00-6.5-6.5h0a6.619 6.619 0 00-6.5 6.5h0a6.336 6.336 0 004 6zM9 19.45h6M12 .55v1.9M12 21.95v1.5M1 10.45h2.29M3.59 3.05l1.91 1.9M23 10.45h-2.29M20.41 3.05l-1.91 1.9"></path>
      </g>
    </StyledIcon>
  )
}
export default Light
