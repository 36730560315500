import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  fill: none;
`

export const ShoppingCart = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M23.5 2.5h-2.6c-.3 0-.5.2-.5.4l-.3 2.7c-.1.5-.5.9-1 .9H2C1.2 6.5.5 7.2.5 8c0 .2 0 .3.1.4l1.6 5c.2.6.8 1.1 1.4 1.1H18c.6 0 1 .4 1 1s-.4 1-1 1H4M19.5 8.5l-.4 4M7.1 18.9c.6.6.6 1.5 0 2.1-.6.6-1.5.6-2.1 0-.6-.6-.6-1.5 0-2.1.5-.5 1.5-.5 2.1 0M18.1 18.9c.6.6.6 1.5 0 2.1-.6.6-1.5.6-2.1 0-.6-.6-.6-1.5 0-2.1.5-.5 1.5-.5 2.1 0"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default ShoppingCart
