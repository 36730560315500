import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const Clients = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g>
        <path
          d="M6.4 6.8c1.1 1.1 1.1 2.8 0 3.9s-2.8 1.1-3.9 0-1.1-2.8 0-3.9 2.9-1.1 3.9 0M8 16c0-1.9-1.6-3.5-3.5-3.5S1 14.1 1 16v3h1.6l.4 5h3l.4-5H8v-3zM22.4 6.8c1.1 1.1 1.1 2.8 0 3.9s-2.8 1.1-3.9 0-1.1-2.8 0-3.9 2.9-1.1 3.9 0M24 16c0-1.9-1.6-3.5-3.5-3.5S17 14.1 17 16v3h1.6l.4 5h3l.4-5H24v-3zM14.1 1.7c.9.8.9 2.3 0 3.1s-2.3.9-3.2 0-.9-2.3 0-3.2 2.3-.8 3.2.1M12.5 6.5c-1.7 0-3 1.3-3 3V12H11l.5 4.5h2L14 12h1.5V9.5c0-1.7-1.3-3-3-3z"
          className="st0"
        />
      </g>
    </StyledIcon>
  )
}

export default Clients
