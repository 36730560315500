import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Holidays = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M18.5 24c-.3 0-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5h-5zM21 18v6M18 21h6M21 18l-2-2M23 16l-2 2M13.5 1c3.8 1.3 7.4 2 10.5 1.5M22.7 5.3c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0M16.7 4.8c.4.4.4 1 0 1.4-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0M22 5V2.7M16 4.5V1.8M13.1 13c.3 0 .5-.2.5-.5 0-.1 0-.2-.1-.3L10 7h1.1c.3 0 .5-.2.5-.5 0-.1 0-.2-.1-.3l-3.1-5c-.1-.2-.4-.3-.7-.1-.1 0-.1.1-.2.2l-3.1 5c-.1.2 0 .5.2.6.1.1.2.1.3.1H6l-3.5 5.2c-.2.2-.1.5.1.7.1.1.2.1.3.1H5l-3.9 5.2c-.2.2-.1.5.1.7.1.1.2.1.3.1h13c.3 0 .5-.2.5-.5 0-.1 0-.2-.1-.3L11 13h2.1zM8 19v5M3.9 10.2l6.9-1.9M3.5 15.1L11 13M8 5.2c.1 0 .2.1.2.2M7.8 5.5c0-.1.1-.2.2-.2M8 5.8c-.1 0-.2-.1-.2-.2M8.3 5.5c0 .1-.1.2-.2.2M7 11.5c.1 0 .2.1.2.2M6.8 11.8c0-.1.1-.2.2-.2M7 12c-.1 0-.2-.1-.2-.2M7.3 11.8c0 .1-.2.2-.3.2M10 10.8c.1 0 .2.1.2.2M9.8 11c0-.1.1-.2.2-.2M10 11.2c-.1 0-.2-.1-.2-.2M10.3 11c0 .1-.1.2-.2.2M11 16.8c.1 0 .2.1.2.2M10.8 17c0-.1.1-.2.2-.2M11 17.2c-.1 0-.2-.1-.2-.2M11.3 17c0 .1-.1.2-.2.2M5 16.8c.1 0 .2.1.2.2M4.8 17c0-.1.1-.2.2-.2M5 17.2c-.1 0-.2-.1-.2-.2M5.3 17c0 .1-.1.2-.2.2M8 16.2c.1 0 .2.1.2.2M7.8 16.5c0-.1.1-.2.2-.2M8 16.8c-.1 0-.2-.1-.2-.2M8.3 16.5c0 .1-.1.2-.2.2"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default Holidays
