import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const PhoneBold = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M9.6 7.9c.9-.9.9-2.4 0-3.3L7.1 2.2c-.9-.9-2.4-.9-3.3 0L2.5 3.5c-1.2 1.2-1.4 3-.4 4.4 4 6 9.1 11.1 15 15 1.4.9 3.2.7 4.4-.4l1.3-1.3c.9-.9.9-2.4 0-3.3l-2.5-2.5c-.9-.9-2.4-.9-3.3 0l-.8.8C13.5 14 11 11.5 8.8 8.7l.8-.8z"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default PhoneBold
