import { Toast } from './Toast'
import { useMemo, useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { produce } from 'immer'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { useAppServices } from './appServices'
import { routeBuilder } from './routeBuilder'
import { getOrgId, getLocationId } from 'helpers'
import { useContext } from 'react'
import { MobileContext } from 'App'
import { getAuthToken } from './useAuth'
import { useLanguage } from './useLanguage'
import { browserLogs } from './browserLogs'
export { DateUtil } from './DateUtil'

export { useAuth } from './useAuth'

export const useSdk = () => {
  const toast = useMemo(() => new Toast(), [])
  const history = useHistory()
  const { t } = useTranslation()
  const apolloClient = useApolloClient()
  const appServices = useAppServices()
  const isMobile = useContext(MobileContext)
  const { pathname } = useLocation()
  const { currentLanguage, changeLanguage } = useLanguage()

  const handleNavigate = useCallback(
    key => {
      return params => {
        const route = routeBuilder[key]({
          orgId: getOrgId(),
          locationId: getLocationId(),
          ...params,
        })
        history.push(route, { previousPath: pathname })
      }
    },
    [history, pathname]
  )

  const navigateTo = useMemo(() => {
    const mappedNavigateTo = {}
    Object.keys(routeBuilder).forEach(
      key => (mappedNavigateTo[key] = handleNavigate(key))
    )
    return mappedNavigateTo as typeof routeBuilder
  }, [handleNavigate])

  const updateQueryCache = useCallback(
    (q, p) => {
      browserLogs.info('Update query cache')
      const current = apolloClient.readQuery({
        query: q.query,
        variables: q.variables,
      })
      const newData = produce(current, draft => {
        p(draft, current)
      })
      apolloClient.writeQuery({
        query: q.query,
        variables: q.variables,
        data: newData,
      })
    },
    [apolloClient]
  )
  const getToken = useCallback(() => getAuthToken(), [])
  const sdk = useMemo(
    () => ({
      isMobile,
      currentLanguage,
      changeLanguage,
      toast,
      history,
      useMutation,
      useQuery,
      useApolloClient,
      useParams,
      apolloClient,
      updateQueryCache,
      t,
      produce,
      appServices,
      navigateTo,
      routeBuilder,
      getToken,
    }),
    [
      toast,
      history,
      t,
      apolloClient,
      appServices,
      navigateTo,
      updateQueryCache,
      getToken,
      isMobile,
      currentLanguage,
      changeLanguage,
    ]
  )
  return sdk
}
