import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const PhoneDownload = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.243 2.257a6 6 0 11-8.486 8.486 6 6 0 018.486-8.486M15 3.5v6M15 9.5l-2.25-2.25M15 9.5l2.25-2.25M15 19.5H2M8.5 21.25v0h0a.25.25 0 01.25.25v0a.25.25 0 01-.25.25v0h0a.25.25 0 01-.25-.25s0 0 0 0v0h0a.25.25 0 01.25-.25"></path>
        <path d="M15 15v6.5a2 2 0 01-2 2H4h0a2 2 0 01-2-2s0 0 0 0v-19h0a2 2 0 012-2h5"></path>
      </g>
    </StyledIcon>
  )
}
export default PhoneDownload
