import styled from 'styled-components'
import {
  blue,
  blueLighter,
  grayLight,
  red,
  redLighter,
  redLight,
  white,
} from '../style/colors'
import ReactSelect from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
export const StyledSelect = styled<any>(ReactSelect)`
  min-width: 140px;
  .react-select__input {
    color: ${props => props.theme.color};
  }

  flex: 1;

  .react-select__control {
    min-width: 120px;
    min-height: 0px;
    display: flex;
    border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
    background: ${({ hasError }) => (hasError ? redLight : white)};
    box-shadow: none;
    :focus-within {
      border: 1px solid ${({ hasError }) => (hasError ? red : blue)};
      box-shadow: 0px 0px 0px 3px
        ${({ hasError }) => (hasError ? redLighter : blueLighter)};
      outline: none;
      transition-property: border-width, border-color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;
    }
    :hover {
      border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
    }
    .react-select__indicator {
      padding-bottom: 0px;
      padding-top: 0px;
    }

    .react-select__single-value {
      font-size: 14px;
    }
    .react-select__value-container.react-select__value-container--is-multi {
      flex-wrap: wrap;
    }
    .react-select__value-container {
      padding: 4px;
      flex-wrap: nowrap;
      overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      .react-select__single-value {
        color: ${props => props.theme.color};
      }
    }
  }
`

export const StyledAsyncSelect = styled<any>(AsyncPaginate)`
  min-width: 140px;
  .react-select__input {
    color: ${props => props.theme.color};
  }

  flex: 1;

  .react-select__control {
    min-width: 120px;
    min-height: 0px;
    display: flex;
    border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
    background: ${({ hasError }) => (hasError ? redLight : white)};
    :focus-within {
      border: 1px solid ${({ hasError }) => (hasError ? red : blue)};
      box-shadow: 0px 0px 0px 3px
        ${({ hasError }) => (hasError ? redLighter : blueLighter)};
      outline: none;
      transition-property: border-width, border-color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;
    }
    :hover {
      border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
    }
    .react-select__indicator {
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .react-select__value-container {
      padding: 4px;
      flex-wrap: nowrap;
      overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      .react-select__single-value {
        color: ${props => props.theme.color};
      }
    }
  }
`

export const CheckIconWrapper = styled.span`
  margin-right: 0.571rem !important;
  height: 1.143rem;
  width: 1.143rem;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.text};
`
