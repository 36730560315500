import React from 'react'
import styled from 'styled-components'

export const Overlay = props => {
  const { setIsInfoPopupOpen } = props
  return <OverlayDiv onClick={() => setIsInfoPopupOpen()} />
}

const OverlayDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  height: 100%;
  width: 100%;
  position: absolute;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
`
