import styled from 'themes'
import * as colors from '../style/colors'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../style/fonts'
import { inputPadding } from '../Input/style'
import Input from 'components/Input'
import { FlexColumn, FlexRow } from '../Flex/Flex'

export const StyledSelectContainer = styled.div``
export const StyledSelectValueInputContainer = styled.div`
  display: inline-block;
  border: none;
  outline: none;
  min-width: 0px;
  align-self: flex-start;
  flex-grow: 0;
`

export const StyledSelectValueInput = styled.input`
  border: none;
  outline: none;
  width: 2px;
  min-width: 0px;
  align-self: flex-start;
  flex-grow: 0;
  box-sizing: content-box;
`
export const StyledSelectValue = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-content: stretch;
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const StyledSelectValueContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
  background: ${({ hasError }) => (hasError ? colors.redLight : colors.white)};
  border-radius: 4px;
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red : colors.grayLight)};
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  color: ${({ hasError }) => (hasError ? colors.red : colors.grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  ${inputPadding};
  padding-left: ${props => (props.hasIcon ? '40px' : 'auto')};
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
  cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};

  &:focus {
    border: 1px solid ${({ hasError }) => (hasError ? colors.red : colors.blue)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) => (hasError ? colors.redLighter : colors.blueLighter)};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  &:disabled {
    background: ${colors.grayLight};
    border: 1px solid ${colors.grayLight};
    color: ${colors.grayDark};
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }
  }
`

export const StyleDropDownOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10000000000;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
`
export const StyledDropDownContainer = styled(FlexColumn)<{
  isSearchable?: boolean
}>`
  align-self: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: ${props =>
    props.isSearchable
      ? 'calc((var(--vh, 1vh) * -90))'
      : 'calc((var(--vh, 1vh) * -70))'};
  height: ${props =>
    props.isSearchable
      ? 'calc((var(--vh, 1vh) * 50))'
      : 'calc((var(--vh, 1vh) * 70))'};
  width: 100%;
  animation-name: ${props =>
    props.isSearchable ? 'anim-open90vh ' : 'anim-open70vh'};

  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  @keyframes anim-open70vh {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc((var(--vh, 1vh) * -70)));
    }
  }
  @keyframes anim-open90vh {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc((var(--vh, 1vh) * -90)));
    }
  }
`

export const StyledDropDownContent = styled(FlexColumn)`
  background: ${props => props.theme.colors.light};
  //flex: 1;
  padding: 3px;
  border-radius: 30px 30px 0 0;
  padding: 20px;
  padding-bottom: 0;
`
export const StyledMenuListContainer = styled(FlexColumn)`
  overflow-y: auto;
  //flex: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyleDropDownFilterPanel = styled.div`
  //padding: 8px;
`

export const StyledDropDownFooterContainer = styled(FlexRow)`
  padding-right: 16px;
  padding-top: 8px;
`
export const StyledCloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  align-self: flex-end;
  padding: 3px;
  position: absolute;
  top: 20px;
  color: white;
  z-index: 999999999;
  right: 15px;
`
export const StyledOptionContainer = styled.div``
export const StyledFilterInput = styled(Input)``
export const StyledOptionValue = styled.div`
  padding: 9px;
`
export const StyledChevronContainer = styled.span<any>`
  display: flex;
  justify-content: center;
  align-self: center;
  padding-right: ${props => (props.isChevron ? '8px' : '0')};
`

export const StyledSelectValueContainerMenuItem = styled.div<any>`
  height: 50px;
  display: flex;
  align-items: center;
`

export const StyledSelectValueMenuItem = styled.div`
  font-size: 14px;
  font-weight: 500;
`
export const EmptyElement = styled.div`
  //height: 50px;
  flex: 1;
`
