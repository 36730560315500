import styled from 'styled-components'
import { red, grayDarker } from '../style/colors'
import {
  fontFamily,
  fontSize,
  fontWeight,
  fontWeightBold,
  fontWeightMedium,
  lineHeight,
} from '../style/fonts'

export const Span = styled.span`
  color: inherit;
  font-family: ${fontFamily};
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`

export const Paragraph = styled.p`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: 1.429rem;
`

export const H1 = styled.h1`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: 32px;
  font-weight: ${fontWeightBold};
  line-height: 44px;
`

export const H2 = styled.h2`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: 1.714rem;
  font-weight: ${fontWeightBold};
  line-height: 34px;
`

export const H3 = styled.h3`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: 1.286rem;
  font-weight: ${fontWeightBold};
  line-height: 1rem;
  margin-block-end: 0.429rem;
  margin-block-start: 0.429rem;
`

export const H4 = styled.h4`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: 1rem;
  font-weight: ${fontWeightBold};
  line-height: 0.429rem;
  margin-block-start: 0.429rem;
  margin-block-end: 0.429rem;
`

export const SmallLabel = styled.h4`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: 1rem;
  font-weight: ${fontWeightMedium};
  margin-block-start: 0.429rem;
  margin-block-end: 0.429rem;
`

export const SmallCapital = styled.label`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: 0.786rem;
  text-transform: uppercase;
  font-weight: ${fontWeightMedium};
  line-height: ${lineHeight};
`

export const Label = styled.label`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${props => (props.textLight ? fontWeight : fontWeightMedium)};
  line-height: ${lineHeight};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: inherit;
`

export const Help = styled.label`
  color: ${({ hasError }) => (hasError ? red : grayDarker)};
  font-family: ${fontFamily};
  font-size: 1rem;
  font-weight: ${fontWeightMedium};
  line-height: ${lineHeight};
`
