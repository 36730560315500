import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const EyeDisabled = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.518 8.288h0A22.111 22.111 0 0123.5 12s-5.148 6.5-11.5 6.5h0a10.3 10.3 0 01-3-.464M4.468 15.7h0A22.105 22.105 0 01.5 12S5.648 5.5 12 5.5h0c.844.004 1.684.113 2.5.325"></path>
        <path d="M8 12h0a4 4 0 014-4M16 12v0a4 4 0 01-4 4M21.75 2.25l-19.5 19.5"></path>
      </g>
    </StyledIcon>
  )
}
export default EyeDisabled
