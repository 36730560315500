/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { grayDarker, gray, grayLight } from '../../style/colors'
import { fontFamily, fontWeightMedium, fontSize } from '../../style/fonts'

export const SelectItemStyled = styled.li`
  min-height: 40px;
  align-items: center;
  color: ${props => (props.disabled ? grayLight : grayDarker)};
  cursor: pointer;
  display: flex;
  font-size: ${fontSize};
  font-family: ${fontFamily};
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-left: 0.571rem;
  margin-right: 0.571rem;
  border-radius: 0.286rem;
  background-color: ${props => (props.hovered ? grayLight : 'transparent')};
  &:hover {
    background-color: ${grayLight};
  }
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const SelectItemLabel = styled.div`
  font-size: ${fontSize};
  font-weight: ${fontWeightMedium};
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.571rem;
  padding-right: 0.571rem;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${props => (props.color ? props.color : 'inherit')};
  color: ${props => (props.color ? 'white' : 'inherit')};
`

export const SelectItemIcon = styled.div`
  display: flex;
  padding-right: 0.571rem;
  svg {
    color: ${props => props.theme.colors.text};
    width: 18px;
    height: 18px;
  }
  ${SelectItemStyled}:hover & * {
    color: ${props => props.theme.colors.text};
  }
`

export const SelectItemTitle = styled.div`
  margin: ${props => (props.moveRight ? '0px 0px 0px 1.714rem' : '0px')};
  width: 100%;
  overflow: hidden;
  display: flex;

  span {
    margin-left: 0.571rem;
  }
`

export const SelectItemCustom = styled.span`
  display: flex;
  align-items: center;
`

export const CheckIconWrapper = styled.span`
  margin-right: 0.571rem !important;
  display: inline-block ;
  height: 1.143rem;
  width: 1.143rem;
  display: flex;
  justify-content: center;
}
`

export const HotKeyPrompt = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 0.857rem;
  text-align: justify;
  letter-spacing: -0.233333px;
  color: ${gray};
`

export const Title = styled.span`
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 0.571rem;
`
