import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const SendEmail = props => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M21.9 17.7c-.1.5-.5.8-1 .8H6.5c-.6 0-1-.4-1-1v-.2l2.1-10c.1-.5.5-.8 1-.8H23c.6 0 1 .4 1 1v.2l-2.1 10z"
        fill="none"
      ></path>
      <path
        d="M7.9 6.7l5.1 5.5c.7.7 1.8.9 2.6.3l8.1-5.7M1 7.5h4M1 10.5h3M1 13.5h2"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
export default SendEmail
