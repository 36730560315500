import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const ChartArrowDown = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1.25 5.75L8.586 14h0a1.042 1.042 0 001.432.107l4.464-3.72h0a1.038 1.038 0 011.43.11l6.838 7.75"></path>
        <path d="M15.812 18.25h6.938v-5.979"></path>
      </g>
    </StyledIcon>
  )
}
export default ChartArrowDown
