import React from 'react'
import styled from 'styled-components'

const StyledLinkButton = styled.button<any>`
  color: ${props => props.theme.colors.primary};
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

type TProps = {
  children?: any
  type?: 'submit' | 'reset' | 'button'
  style?: any
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => any
}
export const LinkButton = React.forwardRef((props: TProps, ref) => {
  const { onClick, children, type, style } = props
  return (
    <StyledLinkButton ref={ref} type={type} onClick={onClick} style={style}>
      {children}
    </StyledLinkButton>
  )
})

LinkButton.defaultProps = {
  type: 'button',
}
