import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import omitDeep from 'omit-deep-lodash'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { useEffect, useState } from 'react'
import { sha256 } from 'crypto-hash'
import { getAuthToken } from 'sdk/useAuth'
import { browserLogs, getSessionId } from 'sdk/browserLogs'
import { onError } from '@apollo/client/link/error'
// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(
      ({ message, locations, path, extensions, stack, originalError, name }) =>
        browserLogs.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          { stack, extensions, originalError, name }
        )
    )
  if (networkError) {
    browserLogs.error(`[Network error]: ${networkError}`)
  }
})

const { createUploadLink } = require('apollo-upload-client')
const undefinedToNullReplacer = (key, value) =>
  value === undefined ? null : value
function replaceNull(obj) {
  //^ because you seem to want to replace (strings) "null" or "undefined" too
  return JSON.parse(JSON.stringify(obj, undefinedToNullReplacer))
}
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename'] // more keys like timestamps could be included here
  const def = getMainDefinition(operation.query) as any

  //check if mutation operation and it is not a file upload as variables must not be changed for uploads
  //upload is detected if there is a variable property named "file"
  if (
    def &&
    def.operation === 'mutation' &&
    def.operation &&
    !operation.variables?.file
  ) {
    const removeTypeNameVars = omitDeep(operation.variables, keysToOmit)
    const nullNormalized = replaceNull(removeTypeNameVars)
    operation.variables = nullNormalized
  }
  return forward ? forward(operation) : null
})
const rootRouteParts = ['home', 'myLocations', 'myAppointments', 'account']
/**
 * provides Apollo link with custom http headers
 * see: https://www.apollographql.com/docs/react/networking/authentication/
 */
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken()
  const sessionorgId = sessionStorage.getItem('orgId')
  const sessionlocationId = sessionStorage.getItem('locationId')
  const language = localStorage.getItem('i18nextLng')
  const pathParts = window?.location?.pathname
    .split('/')
    .filter(part => part.length > 0)

  //instagram browser on IOS has problems with accessing sessionStorage. try to extract header params from the route

  const urlSearchParams = new URLSearchParams(window.location.search)
  const orgIdFromSearchParams = urlSearchParams.get('orgId')
  const locationIdFromSearchParams = urlSearchParams.get('locationId')

  const orgId = orgIdFromSearchParams
    ? orgIdFromSearchParams
    : pathParts && pathParts.length
    ? pathParts[0]
    : undefined
  const locationId = locationIdFromSearchParams
    ? locationIdFromSearchParams
    : pathParts && pathParts.length > 1
    ? pathParts[1]
    : undefined

  const isOrgIdRoute = orgId && !rootRouteParts.includes(orgId)

  return {
    headers: Object.assign(
      {
        ...headers,
        authorization: token,

        'x-session-id': getSessionId(),
        'accept-culture': language,
        'x-integration-source': 'web-b2c-legacy',
      },
      isOrgIdRoute
        ? {
            'x-orgId': orgId,
            'x-locationId': locationId,
          }
        : {
            'x-orgId': sessionorgId,
            'x-locationId': sessionlocationId,
          }
    ),
  }
})
const uploadLink =
  process.env.REACT_APP_USE_PERSISTED_QUERY === 'false'
    ? createUploadLink({
        uri: process.env.REACT_APP_API_URL,
        credentials: 'include',
        fetchOptions: {
          credentials: 'include',
        },
      })
    : createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
        createUploadLink({
          uri: process.env.REACT_APP_API_URL,
          credentials: 'include',
          fetchOptions: {
            credentials: 'include',
          },
        })
      )

// const uploadLink = createUploadLink({
//   uri: process.env.REACT_APP_API_URL,
// }
// )
let wsLink: WebSocketLink
let subscriptionClient: SubscriptionClient

export const createWsLink = () => {
  if (subscriptionClient) {
    subscriptionClient.unsubscribeAll()
    subscriptionClient.close()
  }
  let loc = window.location,
    new_uri
  if (loc.protocol === 'https:') {
    new_uri = 'wss:'
  } else {
    new_uri = 'ws:'
  }
  new_uri += '//' + loc.host

  new_uri += '/subscriptions'
  subscriptionClient = new SubscriptionClient(
    new_uri,

    {
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        const token = getAuthToken()
        return {
          token,
        }
      },
    }
  )
  subscriptionClient.onDisconnected(() => {
    console.log(
      '%csocket connection with zoyya graph has disconnected.',
      'background:red; color:white'
    )
  })
  subscriptionClient.onConnected(() => {
    console.log(
      '%cestablished socket connection with zoyya graph.',
      'background:green; color:white'
    )
  })
  subscriptionClient.onReconnected(async () => {
    try {
      console.log(
        '%cReestablished socket connection with zoyya graph.',
        'background:green; color:white'
      )
      await apolloClient.cache.reset()
    } catch (error) {
      console.error({ error })
    }
  })

  wsLink = new WebSocketLink(subscriptionClient)
  return wsLink
}
wsLink = createWsLink()
export const useWebSocketId = () => {
  const [connectionId, setConnectionId] = useState(0)
  useEffect(() => {
    const removeSubs = subscriptionClient.onReconnected(() => {
      setConnectionId(id => id + 1)
    })
    return () => {
      removeSubs()
    }
  }, [])
  return connectionId
}

const operationLink = errorLink.concat(
  cleanTypenameLink.concat(authLink.concat(uploadLink) as any)
)

const splitterLink = split(
  // split based on operation type
  ({ query, variables, operationName }) => {
    browserLogs.info(`GraphQL ${operationName || ''}`, {
      operationName,
      variables,
    })
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  operationLink
) as any

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          market: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
        },
      },
      Mutation: {
        fields: {
          market: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
        },
      },
    },
  }),
  link: splitterLink,
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
    watchQuery: { errorPolicy: 'all' },
  },
})
