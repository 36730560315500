import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

export const PhoneEmail = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M12.4 22.3c-2.7-2.7-1.7-4.8-.3-6.2l.9-.9c.9-.9 2.4-.9 3.4 0l4 4c.5.5.5 1.4 0 2-.1.1-.3.2-.5.3-7.1 2.9-15.2-.5-18.1-7.6C.4 10.5.4 6.8 1.8 3.4c.4-.7 1.2-1.1 1.9-.8.2.1.3.2.5.3l4 4c.9.9.9 2.4 0 3.4l-.9.9c-1.4 1.4-3.5 2.4-6.2-.3"
        fill="none"
      ></path>
      <path
        d="M11.9 11.5c-.6 0-1-.4-1-1v-6c0-.6.5-1 1-1h9.9c.6 0 1 .4 1 1v6c0 .6-.5 1-1 1h-9.9z"
        fill="none"
      ></path>
      <path d="M22.5 3.8l-5.7 4.5-5.7-4.5" fill="none"></path>
    </StyledIcon>
  )
}
export default PhoneEmail
