import React, { useState, useRef, useContext } from 'react'
import styled from 'styled-components'
import { device } from '../../constants'
import Button from 'components/Button/Button'
import { useSdk } from 'sdk'
import { MobileContext, InfoContext } from 'App'
import { Overlay } from 'components/Overlay/Overlay'
import { Icons } from 'components/Icon'
import { Form, FormField } from 'components/Form/Form'
import { FieldRow, FieldColumn } from 'views/Settings/styles'
import * as Yup from 'yup'
import { useUpdateUserEmailMutation } from 'state/graphql'
import { Loader } from 'components'

export const InfoPopup = ({ user, setIsInfoPopupHiden, refetchUser }) => {
  const { t, appServices } = useSdk()

  const [incompleteSteps] = useState(1)
  const [isBusy, setIsBusy] = useState(false)
  const { isInfoPopupOpen, setIsInfoPopupOpen } = useContext(InfoContext)
  const [updateEmailMutation] = useUpdateUserEmailMutation()

  const containerRef = useRef()
  const isMobile = useContext(MobileContext)

  const EmailSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .required()
        .email(t('translation.ClientForm.email-valid'))
        .trim(),
    })

  const handleSubmit = async values => {
    setIsBusy(true)
    try {
      const result = await updateEmailMutation({
        variables: { input: { userId: user?.id, email: values.email?.trim() } },
      })
      appServices
        .handleMutationResult(
          result,
          t('translation.MyAccountContact.label-email-changed-link-sent')
        )
        .onSuccess(() => {
          setIsInfoPopupOpen(false)
          refetchUser()
        })
    } catch (err) {
      appServices.toast.danger('Something went wrong')
    }
    setIsBusy(false)
  }

  return (
    <>
      {isBusy && <Loader />}
      {incompleteSteps !== 0 ? (
        <>
          {isMobile && isInfoPopupOpen ? (
            <Overlay setIsInfoPopupOpen={() => setIsInfoPopupOpen(false)} />
          ) : null}
          <Container ref={containerRef}>
            {isInfoPopupOpen ? (
              <Window>
                <Header>
                  <Greeting>
                    {t('translation.InfoPopup.hi-greeting')} {user?.firstName}
                  </Greeting>
                  <RotatedHand />
                  <CloseButton onClick={() => setIsInfoPopupOpen(false)}>
                    <Icons.Cross
                      size="extraSmall"
                      stroke={3}
                      style={{ strokeWidth: 4 }}
                    />
                  </CloseButton>
                </Header>
                <Main>
                  <p>{t('translation.InfoPopup.email-popup-hint')}</p>
                  <Form
                    onSubmit={handleSubmit}
                    initialValues={{ email: '' }}
                    validationSchema={EmailSchema()}
                    isInitialValid={false}
                    promptIfDirty={false}
                  >
                    {form => {
                      return (
                        <>
                          <FieldRow>
                            <FieldColumn>
                              <FormField.Text
                                // label={t('translation.ClientForm.email')}
                                name="email"
                                placeholder={t('translation.ClientForm.email')}
                              />
                            </FieldColumn>
                          </FieldRow>

                          <div style={{ display: 'flex' }}>
                            <Button
                              buttonType={'primary'}
                              label={'Spremi'}
                              size={'medium'}
                              type={'submit'}
                              name={'profileButton'}
                            />
                            <Button
                              buttonType={'text'}
                              label={t(
                                'translation.InfoPopup.dont-show-anymore'
                              )}
                              type={'button'}
                              size={'medium'}
                              onClick={() => {
                                setIsInfoPopupHiden(true)
                                localStorage.setItem('hideInfoPopup', 'true')
                              }}
                              name={'profileButtonHide'}
                            />
                          </div>
                        </>
                      )
                    }}
                  </Form>
                </Main>
              </Window>
            ) : null}
          </Container>
          <AvatarContainer>
            <Avatar
              src={process.env.PUBLIC_URL + '/assets/ivana-yellow.png'}
              onClick={() => setIsInfoPopupOpen(!isInfoPopupOpen)}
            />
            <StepsLeft>{incompleteSteps}</StepsLeft>
          </AvatarContainer>
        </>
      ) : null}
    </>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 105px;
  left: 45px;
  right: auto;
  z-index: 99999999;
  @media ${device.tablet} {
    bottom: 145px;
    right: 15px;
    left: auto;
  }
  @media ${device.mobileS} {
    right: 5px;
    left: auto;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media all and (display-mode: standalone) {
      /* CSS specific to standalone applications */
      bottom: 155px;
    }
  }
`
const Window = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  height: auto;
  background-color: ${props => props.theme.colors.light};
  border-radius: 10px;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 20px 15px;
  animation: window 0.5s ease-out;
  @keyframes window {
    0% {
      transform: scale(0.1);
      transform-origin: bottom left;
    }
    100% {
      transform: scale(1);
    }
  }

  @media ${device.tablet} {
    @keyframes window {
      0% {
        transform: scale(0.1);
        transform-origin: bottom right;
      }
      100% {
        transform: scale(1);
      }
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
const Greeting = styled.h1`
  font-weight: 500;
  font-size: 20px;
  margin-right: 5px;
`
const RotatedHand = styled(Icons.Hand)`
  transform: rotate(-30deg);
  position: relative;
  bottom: 3px;
  stroke: #af8e34;
  fill: #ffcf4b;
  stroke-width: 0.5;
`
const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 7px;
  padding: 10px;
`

const Main = styled.div`
  p {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 300;
  }
`

const StepsLeft = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: ${props => props.theme.colors.primary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 32px;
  position: absolute;
  bottom: 40px;
  animation: dot 0.5s ease-out, bounce 2s ease 2s;
  @keyframes dot {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-8px);
    }
    50% {
      transform: scale(1, 1) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
`

const AvatarContainer = styled.button`
  height: 50px;
  text-align: right;
  position: fixed;
  bottom: 35px;
  left: 45px;
  right: auto;
  outline: none;
  border: none;
  z-index: 999999;
  background-color: transparent;
  border-radius: 50px;
  box-shadow: 0px 1px 8px 0px #00000040;
  transition: 0.3s;
  animation: crescendo 0.5s ease-out;
  @media ${device.tablet} {
    bottom: 75px;
    right: 15px;
    left: auto;
  }
  @media ${device.mobileS} {
    right: 5px;
    left: auto;
  }

  @keyframes crescendo {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }
  :hover {
    transform: translateY(-2px);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media all and (display-mode: standalone) {
      /* CSS specific to standalone applications */
      bottom: 85px;
    }
  }
`

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  cursor: pointer;
`

export const BannerCloseButton = styled.div`
  position: absolute;
  right: 8px;
  @media ${device.tablet} {
    top: 3px;
    right: 0px;
    button {
      background-color: transparent;
      border: none;
      color: white;
      span {
        font-size: 30px !important;
      }
    }
  }
`
