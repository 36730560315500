import styled from 'styled-components'
import { device } from '../../../constants'

export const FooterContainer = styled.footer`
  background-image: linear-gradient(135deg, #6a4ee1, #30364d);
  padding: 0 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px none #e3e3e3;
  text-align: center;
  display: block;
  margin-top: auto;
`

export const FooterWrapper = styled.div`
  z-index: 999;
  display: block;
  max-width: 1220px;
  margin-top: 0;
  margin-left: auto;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  position: relative;
  width: 100%;
  margin-right: auto;
`

export const FooterFlex = styled.div`
  display: flex;
  height: auto;
  margin-bottom: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #666;
  text-align: left;
`

export const FooterSide = styled.div`
  width: 50%;
  padding-top: 60px;
  padding-right: 60px;
  padding-bottom: 60px;

  @media ${device.tablet} {
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export const FooterLink = styled.a`
  max-height: 60px;
  min-width: 70px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #333;
  -o-object-fit: fill;
  object-fit: fill;
  text-decoration: none;
  max-width: 100%;
  display: inline-block;
`

export const FooterLogo = styled.img`
  max-width: 100%;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  width: 120px;
`

export const FooterText = styled.p`
  font-family: 'DM Sans', sans-serif;
  color: #d2d2f5;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 34px;
  padding-top: 10px;

  @media ${device.tablet} {
    line-height: 24px;
    font-size: 12px;
  }
`

export const FooterSocialWrapper = styled.div`
  margin-top: 40px;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
`

export const FooterSocialLink = styled.a`
  position: relative;
  display: inline-block;
  margin-right: 0;
  margin-left: 0;
`

export const FooterSocialFacebook = styled.div<{ isDark?: boolean }>`
  display: flex;
  margin-right: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0;
  background-image: url(https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5f104abc6d13576a4b28ca15_facebook-logo%20Black.svg);
  background-size: 16px;
  opacity: 0.6;
  filter: ${props => (props.isDark ? 'none' : ' invert(100%)')};
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  :hover {
    opacity: 1;
  }
`

export const FooterSocialInsta = styled.div<{ isDark?: boolean }>`
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0;
  background-size: 16px;

  opacity: 0.6;
  filter: ${props => (props.isDark ? 'none' : ' invert(100%)')};
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  margin-right: 3px;
  background-image: url('https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5f104abc6d1357059228ca6e_instagram%20(1).png');
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  :hover {
    opacity: 1;
  }
`

export const FooterSocialLinkedIn = styled.div`
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  border-radius: 0;
  background-size: 16px;
  opacity: 0.6;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  margin-right: 3px;
  background-image: url(https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5f423fbf78d43f33a5de8873_linkedIn.svg);
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  :hover {
    opacity: 1;
  }
`

export const FooterSocialYouTube = styled.div`
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0;
  background-size: 16px;
  opacity: 0.6;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  margin-right: 3px;
  background-image: url(https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fd0719af788d64f615ba879_social-video-youtube-clip.svg);
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  :hover {
    opacity: 1;
  }
`

export const FooterSideRight = styled.div`
  display: -webkit-flex;
  display: flex;
  padding-top: 98px;
  padding-right: 0;
  padding-left: 60px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-left: 1px solid rgba(48, 54, 77, 0.18);
  border-left-color: rgba(210, 210, 245, 0.2);
  width: 50%;
  padding-bottom: 60px;
  position: relative;

  @media ${device.tablet} {
    padding-left: 20px;
    flex-direction: column;
    padding-top: 58px;
    padding-bottom: 20px;
  }
`

export const FooterColumn = styled.div`
  width: 50%;
  margin-bottom: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: left;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const FooterList = styled.ul`
  padding-left: 0;
  list-style: none;
`

export const FooterListItem = styled.li`
  font-size: 16px;
  color: #30364d;
  line-height: 30px;
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: none;

  a {
    color: #d2d2f5;
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 2px solid transparent;
    -webkit-transition: border 0.3s ease, color 0.3s ease;
    transition: border 0.3s ease, color 0.3s ease;
    font-size: 14px;
    line-height: 30px;
    font-family: 'DM Sans', sans-serif;
    text-decoration: none;

    :hover {
      border-bottom-color: #b87df9;
      color: #b87df9;
      border-bottom: 2px solid #6a4ee1;
    }
  }

  @media ${device.tablet} {
    a {
      font-size: 12px;
    }
  }
`

export const FooterCopyright = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  color: #666;
  border-top-color: rgba(210, 210, 245, 0.2);

  @media ${device.tablet} {
    font-size: 10px;
    line-height: 16px;
  }
`

export const FooterCopyrightLeft = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #fff;

  a {
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 2px solid transparent;
    -webkit-transition: border 0.3s ease, color 0.3s ease;
    transition: border 0.3s ease, color 0.3s ease;
    color: #30364d;
    font-size: 14px;
    color: #d2d2f5;
    line-height: 30px;
    text-decoration: none;
    font-family: 'DM Sans', sans-serif;
  }

  @media ${device.tablet} {
    font-size: 10px;
    line-height: 16px;
  }
`

export const FooterCopyrightRight = styled.div`
  color: #fff;
  display: flex;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;

  a {
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 2px solid transparent;
    -webkit-transition: border 0.3s ease, color 0.3s ease;
    transition: border 0.3s ease, color 0.3s ease;
    color: #30364d;
    font-size: 14px;
    color: #e3e3e3;
    margin-right: 4px;
    margin-left: 4px;
  }

  @media ${device.tablet} {
    a {
      font-size: 10px;
      line-height: 16px;
    }
  }
`
export const VersionNumber = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
`

export const FooterSocialLinkStyled = styled.a`
  border: 1px solid white;
  color: white;
  opacity: 0.7;
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  &:hover {
    opacity: 1;
  }
`
