import React, {
  useRef,
  useState,
  useMemo,
  Suspense,
  lazy,
  useEffect,
} from 'react'
import { ClientAppBody, ClientAppWrapper, WizardBodyWrapper } from 'styles'
import { Route, Switch, useLocation, useParams } from 'react-router'
import { useViewportDimensions } from 'helpers'
import { PrivateRoute } from 'sdk/PrivateRoute'
import { HeaderContext, ClientMobileHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import { Redirect } from 'react-router-dom'
import { RedirectRoute } from 'Redirect'
import { getCurrentLanguage } from 'localization'
import { Icons, Loader, NotFound } from 'components'
import Helmet from 'react-helmet'
import {
  NavigationContainer,
  NavigationLeftColumn,
  NavigationLogo,
  NavigationLogoImage,
  NavigationNav,
  NavigationNavLink,
  NavigationWrapper,
  SelectWrapper,
} from 'views/Booking/Navigation/styles'
import {
  NavBarName,
  NavBarStyled,
  NavBarUser,
} from 'components/NavBarMenu/style'
import { useAuth } from 'sdk/useAuth'
import { CodeVerification } from 'views/Signup/CodeVerification'
import {
  BackgroundImage,
  Container,
  HeaderImage,
  HeaderText,
  LoacationSection,
  LocationImageSection,
  LocationsGridTitle,
  StyledHomeInputContainer,
} from 'views/Booking/Home/styles'
import { CategoriesContainer } from 'views/Booking/Home/styles'
import Input from 'components/Input'
import Search from 'components/Icon/Icons/Search'
import { DesktopLocationsGrid } from 'views/Booking/Home/styles'
import AppointmentReschedule from 'views/Booking/Appointment/AppointmentReschedule'
import { FacebookRedirect } from 'views/FacebookRedirect/FacebookRedirect'
import { useMarketGetLocationQuery } from './state/graphql'
import {
  setLogContextProperty,
  removeLogContextProperty,
} from 'sdk/browserLogs'

const SelectedCategory = lazy(() =>
  import('views/Booking/SelectedCategory/SelectedCategory')
)
const AppointmentMobile = lazy(() => import('mobile/Appointment/Appointment'))
const Appointment = lazy(() => import('views/Booking/Appointment/Appointment'))
const Select = lazy(() => import('components/Select/Select'))
const Login = lazy(() => import('views/Login/Login'))
const ThankYouPage = lazy(() => import('components/ThankYouPage/ThankYouPage'))
const ForgotPassword = lazy(() => import('views/PasswordReset/ForgotPassword'))
const NotificationPreferences = lazy(() =>
  import('views/Settings/NotificationPreferences/NotificationPreferences')
)
const Signup = lazy(() => import('views/Signup/Signup'))
const EmailConfirmation = lazy(() =>
  import('views/EmailConfirmation/EmailConfirmation')
)
const MyLocations = lazy(() => import('views/Booking/MyLocations/MyLocations'))
const FooterNavigation = lazy(() => import('mobile/FooterNavigation'))

const ReviewPage = lazy(() => import('views/ReviewPage/ReviewPage'))
const SelectedDesktopLocation = lazy(() =>
  import('views/Booking/SelectedLocation/SelectedLocation')
)
const NewEventWizard = lazy(() =>
  import('views/Booking/BookingSteps/BookingSteps')
)
const NewEventWizardMobile = lazy(() =>
  import('mobile/Booking/BookingSteps/BookingSteps')
)
const SelectedLocation = lazy(() =>
  import('mobile/Booking/SelectedLocation/SelectedLocation')
)
const NavigationHeader = lazy(() =>
  import('views/Booking/Navigation/Navigation')
)
const Footer = lazy(() => import('views/Booking/Footer/Footer'))
const OrgLocations = lazy(() => import('views/OrgLocations/OrgLocations'))
const MyAccount = lazy(() => import('views/Settings/MyAccount/MyAccount'))
const Locations = lazy(() => import('views/Booking/Home/Home'))
const BookingRezervationsDesktop = lazy(() =>
  import('views/Booking/MyAppointments/MyAppointments')
)
const MyAccountSecurity = lazy(() =>
  import('views/Settings/MyAccount/MyAccountSecurity')
)
const MyAccountContact = lazy(() =>
  import('views/Settings/MyAccount/MyAccountContact')
)
const SettingsMobile = lazy(() => import('mobile/Settings/SettingsMobile'))

export const ClientRoutes = ({ isMobile }) => {
  const { t } = useSdk()
  const { user } = useAuth()
  const clientRef = useRef<any>()
  const { pathname } = useLocation()

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0)
    }
  }, [pathname, clientRef])

  function shouldShowNavigation() {
    return !pathname.endsWith('login') && !pathname.endsWith('signup')
  }

  const SuspenseWrapper = () => (
    <ClientAppWrapper ref={clientRef}>
      <Loader isComponent />
      <ClientAppBody>
        {isMobile ? null : shouldShowNavigation() ? (
          <NavigationContainer>
            <NavigationWrapper>
              <NavigationLeftColumn>
                <NavigationLogo>
                  <NavigationLogoImage
                    onClick={() => window.open('https://zoyya.com', '_self')}
                    src={process.env.PUBLIC_URL + '/assets/zoyya-black.svg'}
                  />
                </NavigationLogo>
                {!!user?.id ? (
                  <NavigationNav>
                    <NavigationNavLink isActive={pathname.startsWith('/home')}>
                      {t('translation.clientRoutes.homepage')}
                    </NavigationNavLink>
                    <NavigationNavLink isActive={pathname.startsWith('/home')}>
                      {t('translation.Navigation.link-myPlaces')}
                    </NavigationNavLink>
                    <NavigationNavLink
                      isActive={pathname.startsWith('/myAppointments')}
                    >
                      {t('translation.Navigation.link-myBookings')}
                    </NavigationNavLink>
                    <SelectWrapper style={{ marginLeft: 20 }}>
                      <Select
                        style={{ width: 'auto' }}
                        isFullHeight
                        hideSearch
                        items={[]}
                        yPosition={'bottom'}
                        capitalizeItemLabel={false}
                        xPosition="left"
                        fullWidth
                        customButton={handleClick => (
                          <NavBarStyled
                            onClick={handleClick}
                            data-intercom-target="User-Menu"
                          >
                            <div
                              style={{
                                border: '1px solid #333',
                                borderRadius: '100%',
                                padding: 5,
                                marginRight: '10px',
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Icons.UserBold color={'#333'} size={'small'} />
                            </div>

                            <NavBarUser>
                              <NavBarName
                                style={{
                                  fontSize: '16px',
                                  color: '#333',
                                }}
                                data-cy="sidebar_myAccount"
                              >
                                {user?.firstName + ' ' + user?.lastName}
                              </NavBarName>
                            </NavBarUser>
                            <Icons.ChevDown
                              stroke={5}
                              color={'#333'}
                              style={{
                                width: 10,
                                height: 10,
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            />
                          </NavBarStyled>
                        )}
                      />
                    </SelectWrapper>
                    <NavigationNavLink>
                      <img
                        alt="croatia-flag-icon"
                        src={
                          'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5ff9c1987fde0323be6bc36f_croatia%20(2).svg'
                        }
                        style={{ width: 25, height: 32 }}
                      />

                      <Icons.ChevDown
                        stroke={5}
                        style={{ width: 10, height: 10, marginLeft: 10 }}
                      />
                    </NavigationNavLink>
                  </NavigationNav>
                ) : (
                  <>
                    <NavigationNav
                      style={{ marginLeft: 'auto', marginRight: 20 }}
                    >
                      <NavigationNavLink>
                        {t('translation.FooterNavigation.title-dashboard')}
                      </NavigationNavLink>
                      <NavigationNavLink
                        onClick={() =>
                          window.open('https://zoyya.com', '_self')
                        }
                      >
                        {t('translation.Navigation.za-partnere')}
                      </NavigationNavLink>
                    </NavigationNav>
                    <NavigationNav style={{ marginLeft: 0 }}>
                      <NavigationNavLink
                        style={{ marginLeft: 'auto' }}
                        onClick={() =>
                          window.open(
                            process.env.PUBLIC_URL + '/login',
                            '_self'
                          )
                        }
                      >
                        {t('translation.Footer.label-login')}
                      </NavigationNavLink>
                      <NavigationNavLink
                        darkButton={true}
                        onClick={() =>
                          window.open(
                            process.env.PUBLIC_URL + '/signup',
                            '_self'
                          )
                        }
                      >
                        {t('translation.clientRoutes.register')}
                      </NavigationNavLink>
                      <NavigationNavLink>
                        <img
                          alt="language-icon"
                          src={
                            getCurrentLanguage() === 'en'
                              ? 'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5ff9c2013c5ff1b451cbe795_united-kingdom%20(1).svg'
                              : getCurrentLanguage() === 'hr'
                              ? 'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5ff9c1987fde0323be6bc36f_croatia%20(2).svg'
                              : getCurrentLanguage() === 'es'
                              ? 'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/611c0bbf9dedbe99f512d0b6_spain.svg'
                              : 'https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/61348e4de32da814c06ffd1c_germany.svg'
                          }
                          style={{ width: 25, height: 32 }}
                        />

                        <Icons.ChevDown
                          stroke={5}
                          style={{ width: 10, height: 10, marginLeft: 10 }}
                        />
                      </NavigationNavLink>
                    </NavigationNav>
                  </>
                )}
              </NavigationLeftColumn>
            </NavigationWrapper>
          </NavigationContainer>
        ) : null}
        {pathname.endsWith('home') ? (
          <>
            <BackgroundImage>
              <LoacationSection>
                <LocationImageSection>
                  <LocationsGridTitle>
                    <span>{t('translation.Home.homeTitle')}</span>
                    <HeaderText>{t('translation.Home.homeText')}</HeaderText>
                    <StyledHomeInputContainer>
                      <Input
                        id="searchButton"
                        placeholder={t(
                          'translation.Home.search-locations-cities'
                        )}
                        name="searchButton"
                        autoComplete={'off'}
                        icon={<Search size="medium" />}
                        prefix={{ text: '', paddingLeft: '55px' }}
                        onClick={() => {}}
                        readOnly
                      />
                    </StyledHomeInputContainer>
                  </LocationsGridTitle>
                  <HeaderImage />
                </LocationImageSection>
                <div className="big-squares">
                  <div className="mid-circle" />
                  <div className="mid-circle-second"></div>
                  <div className="big-square"></div>
                </div>
                <CategoriesContainer></CategoriesContainer>
              </LoacationSection>
            </BackgroundImage>
            <Container>
              <DesktopLocationsGrid></DesktopLocationsGrid>
            </Container>
          </>
        ) : !isMobile ? (
          <div style={{ minHeight: '100vh' }} />
        ) : null}
        {isMobile && pathname.endsWith('/home') && shouldShowNavigation() && (
          <FooterNavigation />
        )}
      </ClientAppBody>
    </ClientAppWrapper>
  )

  return (
    <Suspense fallback={<SuspenseWrapper />}>
      <Switch>
        <ClientRoute
          exact
          path={'/login'}
          component={<Login />}
          showMobileFooter
        />
        <ClientRoute exact path={'/review/:token'} component={<ReviewPage />} />
        <ClientRoute exact path={'/thankYou'} component={<ThankYouPage />} />
        <ClientRoute
          path={`/settings`}
          component={<SettingsMobile />}
          showMobileFooter
        />
        <ClientRoute
          exact
          path={'/passwordReset/:token'}
          component={<ForgotPassword />}
          showMobileFooter
        />
        <ClientRoute
          exact
          path={'/passwordReset'}
          component={<ForgotPassword />}
          showMobileFooter
        />
        <ClientRoute
          exact
          path={'/notificationPreferences/:token'}
          component={<NotificationPreferences />}
          showMobileFooter
        />

        <ClientRoute path={'/signup/:type'} component={<Signup isRegister />} />
        <ClientRoute
          path={'/signup'}
          component={<Signup isRegister />}
          showMobileFooter
        />
        <ClientRoute
          exact
          path={'/emailConfirmationSuccess'}
          component={<EmailConfirmation />}
          shouldShowNavigation
        />
        <ClientRoute exact path={'/invite'} component={<Signup />} />
        <ClientRoute
          exact
          path={'/register/:inviteId'}
          component={<CodeVerification />}
        />

        <ClientRoute
          exact
          path={'/myAppointments'}
          isPrivate
          component={<BookingRezervationsDesktop />}
          showMobileFooter
          shouldShowNavigation
        />

        <ClientRoute
          exact
          path={'/home/:categoryId'}
          component={<SelectedCategory />}
          showMobileFooter
          shouldShowNavigation
        />
        <ClientRoute
          exact
          path={'/home'}
          component={<Locations />}
          showMobileFooter
          shouldShowNavigation
        />
        <ClientRoute
          exact
          path={'/facebookRedirect'}
          component={<FacebookRedirect />}
          showMobileFooter
          shouldShowNavigation
        />
        <ClientRoute
          exact
          path={'/myLocations'}
          isPrivate
          component={<MyLocations />}
          showMobileFooter
          shouldShowNavigation
        />
        <ClientRoute
          isPrivate
          path="/account/security"
          component={<MyAccountSecurity />}
          showMobileFooter
        />
        <ClientRoute
          isPrivate
          path="/account/contact"
          component={<MyAccountContact />}
          showMobileFooter
        />
        <ClientRoute
          isPrivate
          path="/account"
          component={<MyAccount />}
          showMobileFooter
        />

        <ClientRoute
          path={'/review/:token'}
          isPrivate
          component={<ReviewPage />}
        />
        {/**Beware the route order is important!*/}
        <ClientRoute
          path={'/myAppointments/:bookingId/reschedule'}
          isPrivate
          component={<AppointmentReschedule />}
          showMobileFooter
        />
        <ClientRoute
          path={'/myAppointments/:bookingId'}
          isPrivate
          component={isMobile ? <AppointmentMobile /> : <Appointment />}
          showMobileFooter
        />

        <ClientRoute path={`/redirect/:orgId`} component={<RedirectRoute />} />

        <ClientRoute
          path={`/:orgId/:locationId`}
          component={<LocationRoutes isMobile={isMobile} />}
          noTopMargin
          shouldShowNavigation
        />

        <ClientRoute
          path={`/:orgId`}
          component={<OrgLocations />}
          shouldShowNavigation
          showMobileFooter
        />

        <Redirect from="/:orgId" to="/redirect/:orgId" />
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}
// new routes
const LocationRoutes = props => {
  const { orgId, locationId } = useParams<any>()
  const { isMobile } = props

  const locationData = useMarketGetLocationQuery({
    variables: { locationId },
    skip: !locationId, // to prevent spamming errors on home route
  })
  const location = locationData?.data?.market?.getLocation

  useEffect(() => {
    orgId && sessionStorage.setItem('orgId', orgId)
    locationId && sessionStorage.setItem('locationId', locationId)
    if (orgId) {
      setLogContextProperty('orgId', orgId)
    } else {
      removeLogContextProperty('orgId')
    }
    if (locationId) {
      setLogContextProperty('locationId', locationId)
    } else {
      removeLogContextProperty('locationId')
    }
  }, [orgId, locationId])

  const fbPixelCode = location?.fbPixelId
    ? `{!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${location?.fbPixelId}');
          fbq('track', 'PageView');}`
    : ''

  const gtagCode =
    location?.googleAnalyticsId &&
    location?.googleAnalyticsId?.startsWith('GTM')
      ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${location?.googleAnalyticsId}');`
      : ''

  return (
    <>
      {location?.fbPixelId && (
        <Helmet>
          <script>{fbPixelCode}</script>
          <noscript>
            {`
      <img height="1" width="1" style="display:none"
               src="https://www.facebook.com/tr?id=${location?.fbPixelId}&ev=PageView&noscript=1"/>
  `}
          </noscript>
        </Helmet>
      )}
      {gtagCode && (
        <>
          <Helmet>
            <script>{gtagCode}</script>
          </Helmet>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${location?.googleAnalyticsId}`}
            height="0"
            width="0"
            title="gtag-iframe"
            style={{
              display: 'none',
              visibility: 'hidden',
              height: 0,
              width: 0,
            }}
          ></iframe>
        </>
      )}

      <Switch>
        {/**Beware the route order is important!*/}
        <ClientRoute
          path={`/:orgId/:locationId/day/:dayId/:calendarView/:stepId/booking/:bookingId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/day/:dayId/:calendarView/:stepId/:employeeId/:timeId/:inviteId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/day/:dayId/:calendarView/:stepId/:employeeId/:timeId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />

        <ClientRoute
          path={`/:orgId/:locationId/day/:dayId/:calendarView/:stepId/:employeeId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/day/:dayId/:calendarView/:stepId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/day/:dayId/:calendarView`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        {/* Group appointments wizard */}
        <ClientRoute
          path={`/:orgId/:locationId/groupWizard/:stepId/:appointmentId/:inviteId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/groupWizard/:stepId/:appointmentId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/groupWizard/:stepId/`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/groupWizard/:stepId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        {/* Hotel appointments wizard */}
        <ClientRoute
          path={`/:orgId/:locationId/hotelWizard/:stepId/booking/:bookingId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/hotelWizard/:stepId/:inviteId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/hotelWizard/:stepId/`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/hotelWizard/:stepId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />

        <ClientRoute
          path={`/:orgId/:locationId`}
          component={
            isMobile ? <SelectedLocation /> : <SelectedDesktopLocation />
          }
        />
      </Switch>
    </>
  )
}

const ClientRoute = props => {
  const {
    isPrivate,
    path,
    exact,
    component,
    showMobileFooter,
    shouldShowNavigation,
    noTopMargin = false,
  } = props
  return isPrivate ? (
    <PrivateRoute path={path} exact={exact}>
      <ClientRouteContainer
        component={component}
        showMobileFooter={showMobileFooter}
        shouldShowNavigation={true}
      />
    </PrivateRoute>
  ) : (
    <Route path={path} exact={exact}>
      <ClientRouteContainer
        component={component}
        showMobileFooter={showMobileFooter}
        shouldShowNavigation={shouldShowNavigation}
        noTopMargin={noTopMargin}
      />
    </Route>
  )
}

const ClientRouteContainer = ({
  component,
  showMobileFooter,
  shouldShowNavigation,
  noTopMargin = false,
}) => {
  const containerRef = useRef<any>()
  const [headerOptions, setHeaderOptions] = useState()
  const { isMobile } = useViewportDimensions()

  const headerProvider = useMemo(
    () => ({
      setOptions: options => setHeaderOptions(options),
      getOptions: () => headerOptions,
    }),
    [headerOptions]
  )
  const { pathname } = useLocation()
  return (
    <HeaderContext.Provider value={headerProvider}>
      <ClientAppWrapper ref={containerRef}>
        <ClientAppBody>
          {isMobile ? (
            <ClientMobileHeader />
          ) : shouldShowNavigation ? (
            <NavigationHeader />
          ) : null}
          <WizardBodyWrapper
            isHome={
              pathname.endsWith('/home') ||
              pathname.includes('invite') ||
              pathname.endsWith('login') ||
              pathname.includes('signup') ||
              pathname.endsWith('/thankYou')
            }
            noTopMargin={noTopMargin}
            isFullHeight={!shouldShowNavigation}
            isFullWidth={
              !pathname.includes('/wizard') &&
              !pathname.includes('/group') &&
              !pathname.includes('/hotel')
            }
          >
            {component}
          </WizardBodyWrapper>
          {!isMobile && shouldShowNavigation && <Footer />}
          {isMobile && showMobileFooter && <FooterNavigation />}
        </ClientAppBody>
      </ClientAppWrapper>
    </HeaderContext.Provider>
  )
}
