import React from 'react'
import styled from 'styled-components'

export const Nav = styled.div`
  margin-bottom: 15px;
  text-align: center;
`

export const Dot = styled.span`
  color: ${props =>
    props.isActive
      ? props.theme.colors.primary
      : props.theme.colors.secondaryDark};
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: ${props => (props.isActive ? 1 : 0.4)};
  text-shadow: ${props => (props.isActive ? '0 0px 8px' : 'none')};
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
`

const WizardNavigation = props => {
  const dots = []
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i
    dots.push(
      <Dot key={`step-${i}`} data-cy={`step-${i}`} isActive={isActive}>
        &bull;
      </Dot>
    )
  }

  return <Nav>{dots}</Nav>
}

export default WizardNavigation
