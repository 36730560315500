import React from 'react'
import { Loader } from 'components'
export const QueryRelay = props => {
  const { query, children } = props
  const { data, loading, error } = query

  if (loading) return <Loader isComponent />
  if (error) return null

  if (typeof children === 'function') return children(data)
  return children
}
