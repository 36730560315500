import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Web = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M23.5 12v-.003c0 6.348-5.146 11.493-11.493 11.493S.514 18.345.514 11.997C.514 5.65 5.66.504 12.007.504c4.207 0 8.078 2.3 10.092 5.994h0A11.455 11.455 0 0123.5 12z"></path>
        <path d="M17.017 6.5h0A11.693 11.693 0 0012 .5h0a11.693 11.693 0 00-5.017 6M6.982 17.5h0a11.7 11.7 0 005.018 6h0a10.4 10.4 0 005.1-6M3.28 4.5h17.44M3.28 19.5h17.44M9.5 10v1.345h0c0 .763.146 1.52.43 2.228L10.5 15l1-3.5 1 3.5.571-1.427h0a6.01 6.01 0 00.429-2.228V10M15.5 10v1.345h0c0 .763.146 1.52.43 2.228L16.5 15l1-3.5 1 3.5.571-1.427h0a6.01 6.01 0 00.429-2.228V10M3.5 10v1.345h0c0 .763.146 1.52.43 2.228L4.5 15l1-3.5 1 3.5.571-1.427h0a6.01 6.01 0 00.429-2.228V10"></path>
      </g>
    </StyledIcon>
  )
}
export default Web
