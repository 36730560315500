/** WARNING
 * This file is automatically generated by the `npm run gen:icons` command.
 * ALL changes will be overwritten.
 */
import React from 'react'
import createIconComponent from '../utils/createIconComponent'

const ChevronDownIcon = createIconComponent({
  content: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.146 6.854A.5.5 0 0 0 10.793 6H5.207a.5.5 0 0 0-.353.854l2.792 2.792a.5.5 0 0 0 .708 0l2.792-2.792z"
      />
    </g>
  ),
})
ChevronDownIcon.displayName = 'ChevronDownIcon'

export default ChevronDownIcon
