import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Icons } from 'components'
import {
  SelectItemStyled,
  SelectItemLabel,
  SelectItemIcon,
  SelectItemTitle,
  CheckIconWrapper,
  HotKeyPrompt,
  Title,
} from './style'

const shouldItemMoveRight = (item, hasSelectedItems, hideSearch) =>
  // if the item isn't selected, we need to check if other items are selected in the dropdown or if it has a search bar
  !item.selected && (hasSelectedItems || !hideSearch)
//&&
// if it's not selected and it has a custom component, we shouldn't move the item
// !(item.component && !hasSelectedItems);

class SelectItem extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.item.selected !== this.props.item.selected ||
      nextProps.item.isInactive !== this.props.item.isInactive
    )
  }

  render() {
    const {
      item,
      onClick,
      hovered,
      keyMap,
      hasSelectedItems,
      getItemId,
      hideSearch,
      capitalizeItemLabel,
      isColor,
    } = this.props
    return (
      <SelectItemStyled
        onClick={item.onItemClick || onClick}
        hovered={hovered}
        id={getItemId(item)}
        name={item.key}
        data-cy={'menuItem_' + item.id}
        disabled={item.disabled}
      >
        <SelectItemLabel
          capitalizeItemLabel={capitalizeItemLabel}
          hideSearch={hideSearch}
          hasSelectedItems={hasSelectedItems}
          hasComponent={item.component}
          color={isColor ? item.id : undefined}
        >
          {(item.selected ||
            (item.customComponent && item.selected !== undefined)) && (
            <CheckIconWrapper>
              {item.selected && <Icons.Checkmark color="grayDarker" />}
            </CheckIconWrapper>
          )}
          {item.customComponent
            ? item.customComponent()
            : [
                item.icon && (
                  <SelectItemIcon key={'icon'} hovered={hovered}>
                    {item.icon}
                  </SelectItemIcon>
                ),

                <SelectItemTitle
                  key={'title'}
                  moveRight={shouldItemMoveRight(
                    item,
                    hasSelectedItems,
                    hideSearch
                  )}
                  title={item[keyMap ? keyMap.title : 'title']}
                >
                  {item.component && item.component(item)}
                  <Title>{item[keyMap ? keyMap.title : 'title']}</Title>
                </SelectItemTitle>,
                item.hotKeyPrompt && (
                  <HotKeyPrompt key={'hotkey'} hovered={hovered}>
                    {item.hotKeyPrompt}
                  </HotKeyPrompt>
                ),
              ]}
        </SelectItemLabel>
      </SelectItemStyled>
    )
  }
}

SelectItem.propTypes = {
  /** Item to render */
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    onItemClick: PropTypes.func,
    selected: PropTypes.bool,
    icon: PropTypes.node,
    component: PropTypes.func,
  }).isRequired,

  customComponent: PropTypes.func,

  /** On click function */
  onClick: PropTypes.func.isRequired,

  /** Get the id of the item */
  getItemId: PropTypes.func.isRequired,

  /** Is the item selected */
  hovered: PropTypes.bool,

  /** Should capitalize Item Label */
  capitalizeItemLabel: PropTypes.bool,

  /** Custom keys to used in the Items array */
  keyMap: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),

  /** Does the Select have selected items to adjust the margin */
  hasSelectedItems: PropTypes.bool,

  /** Does the Select have a search bar incorporated */
  hideSearch: PropTypes.bool,

  /** Is it a multi select */
  multiSelect: PropTypes.bool,
}

SelectItem.defaultProps = {
  hovered: undefined,
  keyMap: undefined,
  hasSelectedItems: undefined,
  hideSearch: undefined,
  multiSelect: undefined,
  capitalizeItemLabel: true,
}

export default SelectItem
