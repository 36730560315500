import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};
`

const User = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g>
        <g
          fill="none"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M15.89 2.86a5.5 5.5 0 11-7.78 7.78 5.5 5.5 0 017.78-7.78M3 22.75h0a9 9 0 0118 0s0 0 0 0z"></path>
        </g>
      </g>
    </StyledIcon>
  )
}

export default User
