import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const CreditCard = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.504 20a2 2 0 01-2-2V6a2 2 0 012-2h19a2 2 0 012 2v12a2 2 0 01-2 2zM.5 8h23M20.5 12h-3M11.5 12h-8M6.5 15h-3"></path>
      </g>
    </StyledIcon>
  )
}
export default CreditCard
