import { toast, ToastOptions } from 'react-toastify'

export class Toast {
  show = (message: any, options?: ToastOptions) => {
    toast(message)
  }
  danger = (message: any, options?: ToastOptions) => {
    toast.error(message, {
      hideProgressBar: true,

      ...options,
    })
  }
  success = (message: any, options?: ToastOptions) => {
    toast.success(message, {
      hideProgressBar: true,
      ...options,
    })
  }
}
