import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Alarm = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M23.5 10.5v0a1 1 0 01-1 1h-21 0a1 1 0 01-1-1s0 0 0 0v-2h0a1 1 0 011-1h21a1 1 0 011 1s0 0 0 0zM21.5 22.5v0a1 1 0 01-1 1h-17 0a1 1 0 01-1-1s0 0 0 0v-11h19zM8.5 7.5v16M15.5 23.5v-16M17.427 3.84C16.663 4.605 13 5.5 13 5.5s.9-3.663 1.66-4.427h0a1.957 1.957 0 112.767 2.767zM6.573 3.84C7.337 4.605 11 5.5 11 5.5s-.9-3.663-1.66-4.427h0A1.957 1.957 0 006.573 3.84z"></path>
      </g>
    </StyledIcon>
  )
}
export default Alarm
