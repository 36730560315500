import { NavLink } from 'react-router-dom'
import { fontFamily } from 'components/style/fonts'
import { AvatarWrapper } from 'components/Avatar/Avatar'
import { device } from './constants'
import { transparentize } from 'polished'
import { TooltipWrapper } from 'components/Tooltip/style'
import { ButtonLabel } from 'components/Button/style'
import styled from 'styled-components'
import { red } from 'components/style/colors'

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
export let vh = window.innerHeight * 0.01
export let vw = window.innerWidth * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)
document.documentElement.style.setProperty('--vw', `${vw}px`)

export const eventRowHeight = 20
export const workScheduleRowHeight = 4
export const employeeScheduleRowHeight = 8

export const AppContainer = styled.div<{ isMobile?: boolean }>`
  background-color: ${props =>
    props.isMobile ? props.theme.colors.gradientBackground : 'transparent'};
  color: ${props => props.theme.colors.text};
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ClientAppWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;
`

export const ClientBodyWrapper = styled.div<{ showMobileFooter?: boolean }>`
  margin: 0 auto;
  width: 100%;
  position: relative;
  max-width: 1220px;
  margin-top: 100px;
  @media ${device.tablet} {
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0px 20px;

    overflow: ${props => (props.showMobileFooter ? 'auto' : 'initial')};
  }
`

export const WizardBodyWrapper = styled.div<any>`
  margin: 0 auto;
  width: 100%;
  position: relative;
  margin-top: ${props => (props.isHome || props.noTopMargin ? '0' : '87px')};
  padding-top: 20px;
  max-width: ${props => (props.isFullWidth ? 'initial' : '1220px')};
  height: ${props => (props.isFullHeight ? '100%' : 'initial')};
  @media ${device.tablet} {
    margin-top: 0px;
    overflow: auto;
    height: 100%;
    padding-top: 0px;

    > div {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

export const ClientAppBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  @media ${device.tablet} {
    height: 100%;
    flex-direction: column;
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const Container = styled.div<{ isInitial?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: ${props => (props.isInitial ? 'initial' : 'relative')};
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 0px 15px;
  }
`

export const FormsContainer = styled.div`
  @media ${device.tablet} {
    padding: 10px 0px;
  }
`

export const SettingsHeader = styled.div`
  display: flex;
  padding: 5px 0px 0px 30px;
  width: 100%;
  border-bottom: 1px solid #edeef4;
  font-size: 20px;
  font-weight: 600;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  max-height: 57px;
  min-height: 57px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 9999;
`

export const Row = styled.div<{ isRelative?: boolean; isNarrow?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Footer = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.light};
  //padding-bottom: 10px;
  @media ${device.tablet} {
    box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 999;
    max-height: 80px;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      padding-bottom: 20px;
    }
  }
`

export const NavigationButtons = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${props => (props.isMobile ? '0' : '1.429rem')};
  height: 60px;
  @media ${device.tablet} {
    height: 65px;
    max-height: 65px;
    overflow: hidden;
  }
`

export const NavigationItemWrapper = styled.div``

export const NavTitle = styled.span<{ isSelected: boolean }>`
  color: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.text};
  font-size: 8px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0.2px;
`

export const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
type TNavigationItem = {
  isSelected?: boolean
  activeClassName: 'active'
}
export const NavigationItem = styled(NavLink)
  .attrs(props => ({
    activeClassName: 'active',
  }))
  .withConfig<TNavigationItem>({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['isSelected', 'isSecondChild'].includes(prop.toString()),
  })`  
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  min-height:50px;
  color: ${props => props.theme.colors.text};
  width: 100%;
  margin-bottom: 5px;
  svg {
    color: ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.secondaryDark};
  }
  svg.chavron {
    margin-right: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    background: rgb(220 221 229);
    .nav-title{
      font-weight:bold
    }
  }
  @media ${device.tablet} {
    margin-left: 0px;
    margin: 0px 1rem;
    padding: 15px 0px;
    min-height: 55px;
        //border-bottom: 1px solid #dbdfe2;
    width: auto;
    &.active {
      background: initial;      
    }
    svg {
      color: ${props =>
        props.isSelected
          ? props.theme.colors.primary
          : props.theme.colors.text};
    }
  }
`
export const NavigationTitle = styled.span<{
  isSelected?: boolean
  isSubItem?: boolean
  isMenu?: boolean
  isButton?: boolean
  isPrimaryButton?: boolean
}>`
  font-weight: ${props => (props.isMenu ? '600' : '400')};
  font-size: 13px;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  margin-right: auto;
  max-width: ${props => (props.isSubItem ? '100px' : '100%')};
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
  color: ${props =>
    props.isPrimaryButton ? props.theme.colors.light : props.theme.colors.text};
  min-height: ${props =>
    props.isButton || props.isPrimaryButton ? '40px' : '50px'};

  @media ${device.tablet} {
    font-weight: ${props => (props.isMenu ? '500' : '400')};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: ${props =>
      props.isButton || props.isPrimaryButton ? 'center' : 'flex-start'};
    text-align: ${props =>
      props.isButton || props.isPrimaryButton ? 'center' : 'left'};

    margin: ${props =>
      props.isButton || props.isPrimaryButton ? 'auto' : 'initial'};
    padding: 0px 2rem;
  }
`
export const FooterWrapper = styled.div`
  background: ${props => props.theme.colors.light};
  height: 100%;

  ${NavigationItem} {
    //margin-top: 7px;
  }
`

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.429rem;
  a:first-child {
    margin-top: 0px !important;
  }
`

type TTableItem = {
  isHomeTable?: boolean
  hasAvatar?: boolean
}
export const TableItem = styled.div.withConfig<TTableItem>({
  shouldForwardProp: prop =>
    !['isWide', 'isHomeTable', 'hasAvatar', 'isDisabled', 'isAvatar'].includes(
      prop
    ),
})`
  cursor: pointer;
  font-style: normal;
  font-weight: ${props => (props.isHomeTable ? '400' : '500')};
  font-size: ${props => (props.isHomeTable ? '13px' : '1rem !important')};
  line-height: 1.429rem;
  color: ${props => props.theme.colors.text} !important;
  font-family: ${fontFamily};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 35px;

  ${AvatarWrapper} {
    float: ${props => (props.hasAvatar ? 'left' : 'none')};
  }

  span {
    float: ${props => (props.hasAvatar ? 'left' : 'none')};
    margin-left: ${props => (props.hasAvatar ? '10px' : 'inherit')};
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const HelpHint = styled.div`
  color: ${props => props.theme.colors.secondaryDark};
  font-size: 0.857rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-style: italic;
  padding-top: 20px;

  span {
    margin-right: 0.286rem;
  }

  a {
    margin-left: 0.286rem;
    color: ${props => props.theme.colors.primary};
  }
`

export const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  @media ${device.tablet} {
    flex-direction: column;
    margin-bottom: 0px;
  }
`

export const FormPageContainer = styled.div`
  max-height: 100%;
  overflow: auto;

  @media ${device.tablet} {
    padding: 0px 15px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`
type TFormContainer = {
  isMobile?: boolean
  isFullHeight?: Boolean
}
export const FormContainer = styled.div.withConfig<TFormContainer>({
  shouldForwardProp: prop => !['isMobile', 'isFullHeight'].includes(prop),
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => (props.isMobile ? '15px' : '20px')};
  height: ${props => (props.isFullHeight ? '100%' : 'auto')};

  @media ${device.tablet} {
    display: initial;
    height: 100%;
    padding: 20px 20px 20px 10px;
  }
`

export const ListBodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${props => props.theme.colors.light};
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media ${device.tablet} {
    &::-webkit-scrollbar {
      display: none;
    }
    min-height: 0;
    flex: 1;
    display: block;
    overflow: initial;
  }
`
export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  background: ${props => props.theme.colors.light};
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 10px 0px !important;
    overflow: hidden;
  }
`
export const UserAvatarWrapper = styled.div<{ hasBottomBorder?: boolean }>`
  flex-direction: row;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${props => (props.hasBottomBorder ? '15px' : '0')};
  border-bottom: 1px solid
    ${props =>
      props.hasBottomBorder ? props.theme.colors.outline : 'transparent'};
`

export const UserAvatar = styled.div`
  width: 60px;
  height: 60px;
  border: 5px solid ${props => props.theme.colors.primary};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 20px;
  flex-shrink: 0;
  img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
    position: absolute;
    padding: 4px;
  }
`
export const UserName = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  line-height: 35px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`

export const UserWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media ${device.mobileL} {
    overflow-x: auto;
  }
`

export const UserDesc = styled.span`
  font-size: 16px;
  color: ${props => props.theme.colors.text};
  @media ${device.mobileL} {
    font-size: 12px;
  }
`
export const NavigationButton = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: ${props => props.theme.colors.text};
  width: calc(100% - 40px);
  margin-left: 0px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 20px 0;
  min-height: 55px;

  @media ${device.tablet} {
    margin-left: 0px;
    //margin: 0px 1rem;
    padding: ${props =>
      props.isMenu
        ? '0px'
        : props.isButton || props.isPrimaryButton
        ? '5px'
        : '15px'};
    min-height: ${props =>
      props.isButton || props.isPrimaryButton ? '50px' : '55px'};
    border-bottom: none;
    background: ${props =>
      props.isMenu || props.isButton
        ? 'transparent'
        : props.isPrimaryButton
        ? 'linear-gradient(90deg,#b87df9,#6a4ee1)'
        : props.theme.colors.lightBackground};
    width: auto;
    margin-bottom: ${props =>
      props.isMenu || props.isPrimaryButton ? '0' : '10px'};
    border-radius: 5px;
    margin-top: ${props =>
      props.isButton || props.isPrimaryButton ? '10px' : '0'};
    border: ${props =>
      props.isButton ? `1px solid ${props.theme.colors.outline}` : 'none'};
  }
`

type TDatePickerCalendarContainerProps = {
  isHeaderPicker?: boolean
  isAbsolute?: boolean
}
export const DatePickerCalendarContainer = styled.div<
  TDatePickerCalendarContainerProps
>`
  margin: 10px 0px;
  overflow: hidden;
  display: flex;
  top: ${props => (props.isHeaderPicker ? '40px' : 'initial')};
  position: ${props => (props.isAbsolute ? 'absolute' : 'initial')};
  background: ${props => (props.isAbsolute ? 'white' : 'transparent')};
  box-shadow: ${props =>
    props.isAbsolute ? '0px 0px 10px 0px #8080803b' : 'none'};
  border-radius: ${props => (props.isAbsolute ? '4px' : '0')};
  z-index: 99999;

  @media ${device.tablet} {
    overflow: auto;
    max-height: calc((var(--vh, 1vh) * 100 - 100px));
    margin-top: 0px;
  }
`

export const DateButton = styled.button`
  padding: 8px 8px;
  white-space: nowrap;

  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  font-size: 14px;
  height: 32px;
  font-family: ${fontFamily};

  &:focus,
  :active {
    border: none;
    outline: none;
  }
`
export const DatePickerArrow = styled(DateButton)`
  box-shadow: none;
  transform: none;
  color: #101928;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 40px;
  background: ${props => props.theme.colors.light};
  border: none;
  border-radius: 4px;

  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  &:last-child {
    border-left: 1px solid
      ${props => transparentize(0.4, props.theme.colors.outline)};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`

export const EventStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 200px;

  @media ${device.tablet} {
    margin-right: 0;
  }
`
export const EventTimeRow = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  align-items: center;
  width: ${props => (props.isFullWidth ? '100%' : '340px')};

  ${TooltipWrapper} {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  @media ${device.tablet} {
    padding-right: 10px;
    ${ButtonLabel} {
      display: none !important;
    }
  }
`
export const EventTimeReservationWrapper = styled.div<{ color?: string }>`
  color: ${props => props.color};

  @media ${device.tablet} {
    display: none;
  }
`
export const EventTimeIcon = styled.div<{ color?: string }>`
  font-size: 1.714rem;
  border-radius: 9.5px;
  color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  min-width: 38px;
`

type TEventColumn = {
  marginTop?: string
  isRight?: boolean
}
export const EventColumn = styled.div<TEventColumn>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? '1.714rem' : '0')};
  margin-left: ${props => (props.isRight ? 'auto' : 'initial')};
`

export const SidebarRow = styled.div`
  display: flex;
  flex-direction: column;
`
export const SidebarMenuItem = styled.div<any>`
  display: flex;
  align-items: center;
  background: ${props =>
    props.isOpen ? props.theme.colors.lightBackground : 'transparent'};
  border-radius: 10px;
  padding: 0 1rem;

  :focus,
  :active {
    background: ${props => props.theme.colors.lighBackground};
  }
`

export const OrganizationSwitchIcon = styled.div<{
  background: string
  size?: 'small' | 'large' | 'medium' | 'extraLarge'
}>`
  height: ${props =>
    props.size === 'medium'
      ? '34px'
      : props.size === 'small'
      ? '22px'
      : props.size === 'large'
      ? '40px'
      : props.size === 'extraLarge'
      ? '64px'
      : '20px'};
  width: ${props =>
    props.size === 'medium'
      ? '34px'
      : props.size === 'small'
      ? '22px'
      : props.size === 'large'
      ? '40'
      : props.size === 'extraLarge'
      ? '64px'
      : '20px'};
  font-style: normal;
  font-weight: 500;
  font-size: 0.786rem;
  line-height: 1.143rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.light};
  background: ${props => props.background};
  border-radius: 100px;
  min-width: 20px;
  text-transform: uppercase;
  min-height: 20px;
  margin-left: auto;
`

export const SelectRow = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 40px);

  ${AvatarWrapper} {
    margin-right: 8px;
  }

  ${OrganizationSwitchIcon} {
    margin-left: 0px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`

export const ErrorMessage = styled.small`
  display: block;
  padding: 0 0 15px 10px;
  color: ${red};
  margin-top: -5px;
`
